import styled from "styled-components";
import { useEffect, useState } from "react";
import { capitalize, minimalize } from "./helpers";
import { usePopper } from "react-popper";
import { useListenForOutsideClicks } from "../../hooks/useListenForOutsideClicks";
import { theme } from "../../style/theme";

interface Props {
  addTag: (tag: string) => void;
}
export function AddTag({ addTag }: Props) {
  const [search, setSearch] = useState("");
  const [isInputFocused, setInputFocused] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [allTags, setAllTags] = useState<string[]>([]);

  const options = {
    placement: "bottom",
    strategy: "fixed",
  };

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    // @ts-ignore
    options
  );
  const pages = [
    {
      metadata: {
        tags: ["tag1", "tag2"],
      },
    },
  ];

  useEffect(() => {
    try {
      // The alltags are computed only once for each render of the component
      setAllTags(
        Array.from(
          new Set(
            Array.from(pages)
              .map((page) => {
                return [];
              })
              .flat()
          )
        )
      );
    } catch (e) {
      console.error(e);
      console.error("Mapping other pages for metadata failed");
      setAllTags([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredResults = allTags.filter((tag: string) => {
    const miniSearch = minimalize(search);
    const miniTag = minimalize(tag);
    return miniTag.includes(miniSearch);
  });

  // close menu when user clicks outside of menu and reference element
  useListenForOutsideClicks(
    [referenceElement, popperElement],
    () => setInputFocused(false),
    isInputFocused
  );

  return (
    <Container ref={setReferenceElement}>
      <AddContainer>
        <Input
          onFocus={() => setInputFocused(true)}
          type="text"
          placeholder={"Write a tag..."}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addTag(search);
              setSearch("");
            }
          }}
          spellCheck={false}
        />
        <AddButton
          style={{
            backgroundColor: search.length > 0 ? theme.secondary : "white",
            color: search.length > 0 ? "white" : theme.lightText,
          }}
        >
          ↵
        </AddButton>
      </AddContainer>
      {isInputFocused && (
        <AllTagsContainer
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {filteredResults.map((tag) => (
            <ElementTag
              key={tag}
              onClick={() => {
                addTag(capitalize(tag));
                setInputFocused(false);
                setSearch("");
              }}
            >
              {capitalize(tag)}
            </ElementTag>
          ))}
        </AllTagsContainer>
      )}
    </Container>
  );
}

const AddContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

const AddButton = styled.button`
  padding: 8px;
  height: 32px;
  width: 33px;
  border-radius: 4px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.lightText};
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.secondary};
    color: white;
  }
`;

const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;

  align-items: center;
`;
const Input = styled.input`
  border-bottom: 1px solid ${({ theme }) => theme.lightText};
  margin-bottom: 8px;
  outline: none;
  padding: 8px 8px 8px 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.lightText};
  font-size: 13px;
  width: 100%;

  background-color: rgba(255, 255, 255, 0.55);
  color: ${({ theme }) => theme.text};
`;

const AllTagsContainer = styled.div`
  background-color: white;
  padding: 0px;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  max-height: 240px;
  overflow: auto;
  font-size: 15px;
  width: 180px;
`;

const ElementTag = styled.div`
  padding: 3px;
  padding: 4px 6px 4px 6px;

  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.primary};
    color: white;
  }
`;
