import { langs } from "@uiw/codemirror-extensions-langs";
import { tokyoNightStorm } from "@uiw/codemirror-theme-tokyo-night-storm";
import CodeMirror from "@uiw/react-codemirror";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { NoticeToolTip } from "../../Components/ToolTip/ToolTip";
import useDebounce from "../../hooks/useDebounce";
import { ExpandArrowIcon } from "../../icons/ExpandArrowIcon";
import { CodeEditorModal } from "./CodeEditorModal";

interface Props {
  onSave: (value: string) => void;
  initialValue?: string;
}

export const CodeEditor = ({ onSave, initialValue }: Props) => {
  const [val, setVal] = useState(initialValue ?? "");
  const [deBouncedValue] = useDebounce(val, 800);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setVal(initialValue ?? "");
  }, [initialValue]);

  useEffect(() => {
    if (deBouncedValue === initialValue) return;
    onSave(deBouncedValue);
  }, [deBouncedValue]);

  const placeholder = `<title>CodeMirror: HTML Mode</title>
<link rel="stylesheet" href="lib/codemirror.css">
<script src="lib/codemirror.js"></script>`;
  return (
    <FlexColumn>
      <CodeMirror
        theme={tokyoNightStorm}
        placeholder={placeholder}
        height='100px'
        width='100%'
        extensions={[langs.html()]}
        basicSetup={{
          autocompletion: true,
          bracketMatching: true,
        }}
        onChange={(v) => setVal(v)}
        value={val}
      />
      <NoticeToolTip anchorSelect='.expand-button'> Expand </NoticeToolTip>
      <FullScreenButton
        onClick={() => setIsExpanded(true)}
        className='expand-button'
      >
        <ExpandArrowIcon color='black' size={12} />
      </FullScreenButton>
      <CodeEditorModal
        open={isExpanded}
        setOpen={setIsExpanded}
        placeholder={placeholder}
        val={val}
        setVal={setVal}
      />
    </FlexColumn>
  );
};

const FlexColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  margin: 4px 0 12px;
`;

const FullScreenButton = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: ${({ theme }) => theme.greyLight};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.greyDark};
  }
`;
