import styled from "styled-components";
import { templateColors } from "../../hooks/useProjectType";

interface PillProps {
  text?: string;
  className?: string;
  onClick?: any;
}

export function Pill({ text = "Blog", className, onClick }: PillProps) {
  return (
    <PillWrapper className={className} onClick={onClick}>
      {text}
    </PillWrapper>
  );
}

const PillWrapper = styled.div`
  padding: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.lightText};
`;

export const WrapperProjectType = styled(Pill)<{
  text: string;
  type: string;
  selected?: boolean;
}>`
  cursor: pointer;
  color: white;
  opacity: ${({ selected }) => (selected !== false ? 1 : 0.8)};

  background-color: ${({ type }) => {
    return templateColors[type];
  }};

  border: ${({ selected }) =>
    selected ? "2px solid white" : "2px solid transparent"};

  &:hover {
    opacity: 1;
  }
`;
