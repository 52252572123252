import styled from "styled-components";
import { GoogleIcon } from "../../icons/google.icon";
import { SignInCode, SignInInput } from "./SignInInput";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PAGES } from "../../Router/constants";
import { ENDPOINTS } from "../../tools";
import { Button } from "../../Components/Button";

export function ConnectPage() {
  const [email, setEmail] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { next = `/${PAGES.WORKSPACE}` } = useParams();
  const codeSent = searchParams.get("codeSent") === "true";
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  const signInWithCode = async () => {
    try {
      await axios.post(
        `${ENDPOINTS.API}/auth/email/code`,
        {
          email: email.trim(),
          code: code.trim(),
        },
        {
          withCredentials: true,
          params: {
            source: window.location.href,
            next: next ?? undefined,
          },
        }
      );
      navigate(next, { replace: true });
    } catch (ex) {
      console.error(ex);
      toast.error("Wrong temporary code");
    }
  };

  const continueWith = async (provider: "email" | "google" | "github") => {
    if (provider === "email") {
      setInProgress(true);

      try {
        await axios.post(`${ENDPOINTS.API}/auth/email`, {
          email: email.trim(),
        });
        setSearchParams({ codeSent: "true" });
      } catch (e) {
        if (e?.response?.data?.errors) {
          toast.error(
            `${e?.response?.data?.errors[0].msg} for ${e?.response?.data?.errors[0].path}`
          );
        } else {
          toast.error(e?.response?.data);
        }
      }

      setInProgress(false);
    } else {
      const url =
        `${ENDPOINTS.API}/auth/${provider}` +
        `?source=${encodeURIComponent(window.location.href)}` +
        `${next != null ? `&next=${encodeURIComponent(next)}` : ""}`;

      window.location.href = url;
    }
  };

  return (
    <>
      <Container>
        <FormWrapper>
          <Form>
            <FormTitle>
              <span>Welcome to Notice</span>
            </FormTitle>
            <FormDescription>
              {codeSent
                ? "Enter the 6 digits code you received by email."
                : "Connect quickly using Google, or use your email."}
            </FormDescription>
            <ButtonsWrapper hide={codeSent}>
              <StyledButton onClick={() => continueWith("google")}>
                <GoogleIcon size={18} />
                Continue with Google
              </StyledButton>
              <OrEmailDivider>
                <Line />

                <span style={{ fontWeight: 500 }}>OR</span>
                <Line />
              </OrEmailDivider>
            </ButtonsWrapper>
            {!codeSent && (
              <SignInInput
                type="email"
                placeholder={"Enter work email"}
                value={email}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    continueWith("email");
                  }
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            )}
            {codeSent && (
              <SignInCode
                value={code}
                onChange={(e) => setCode(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    signInWithCode();
                  }
                }}
                autoFocus
              />
            )}
            {!codeSent && (
              <Button
                style={{
                  marginTop: "16px",
                  width: "100%",
                  height: 40,
                  fontWeight: 700,
                }}
                onClick={() => continueWith("email")}
                width="100%"
                disabled={!email.includes("@")}
                loading={inProgress}
              >
                Continue with email
              </Button>
            )}
            {codeSent && (
              <Button
                style={{
                  marginTop: "16px",
                  width: "100%",
                  height: 40,
                  fontWeight: 700,
                }}
                onClick={signInWithCode}
                width="100%"
                disabled={code.length !== 6}
                loading={inProgress}
              >
                Continue
              </Button>
            )}
          </Form>
        </FormWrapper>
      </Container>
      <LookingForV1 href="https://app.notice.studio" target="_blank">
        Looking for Notice v1? Click here.{" "}
      </LookingForV1>
    </>
  );
}

const LookingForV1 = styled.a`
  margin: auto;
  text-align: center;
  font-size: 14px;
  margin-top: 16px;
  color: ${({ theme }) => theme.lightText};
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  width: 100%;
`;

const OrEmailDivider = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  gap: 8px;
  font-size: 12px;
  margin-bottom: 16px;
`;

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.primary};

  color: white;
  padding: 8px 16px;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: none;
  cursor: pointer;
  transition: 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-weight: 700;
  padding: 16px;
  &:hover {
    opacity: 0.8;
  }
`;

const ButtonsWrapper = styled.div<{ hide: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0 0px 0;
  height: ${({ hide }) => (hide ? "0px" : "130px")};
  opacity: ${({ hide }) => (hide ? "0" : "1")};

  transition: height 0.5s ease, opacity 0.2s ease;

  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  background-color: rgb(0, 0, 0, 0.05);
  backdrop-filter: blur(16px) brightness(1.05);

  width: 500px;
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-top: 7vh;
  margin-left: auto;
  margin-right: auto;
`;

const FormWrapper = styled.div`
  position: relative;

  flex: 1;

  display: flex;
  justify-content: center;

  height: 100%;
  width: 50%;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1 1 0%;

  padding: 40px 80px;
  max-width: 550px;
`;

const FormTitle = styled.h1`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
`;

const FormDescription = styled.p``;
