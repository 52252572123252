import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../../Components/Dialog/Dialog";
import { DoneActions, UserPrompt } from "./UserPrompt";
import styled from "styled-components";
import { MarkdownORHTMLResponse } from "./MarkdownORHTMLResponse";
import { generalPrompt } from "../../../hooks/ai/chatMessages";
import { useAIContent } from "../../../hooks/ai/useAiGeneratePage";

export function AIPrompt({ children, insertMarkdown }) {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent noStyle={true}>
        <Content insertMarkdown={insertMarkdown} setOpen={setOpen} />
      </DialogContent>
      <DialogTrigger
        className="change-url-tooltip"
        asChild={true}
        style={{ height: "100%", width: "100%" }}
      >
        <FlexyChildren
          onClick={() => setOpen(true)}
          style={{ height: "100%", width: "100%" }}
        >
          {children}
        </FlexyChildren>
      </DialogTrigger>
    </Dialog>
  );
}

const FlexyChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-radius: 5px;
  width: 600px;
  max-height: 80vh;
  overflow: auto;
  z-index: 999999;

  max-width: fill-available;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
`;
const PromptWrapper = styled.div`
  width: 100%;
  max-width: fill-available;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
  padding: 10px 10px 10px 10px;
  margin-left: 0px;
`;

const Content = ({ insertMarkdown, setOpen }) => {
  const [status, setStatus] = useState<"idle" | "loading" | "done">("idle");

  const [inputValue, setInputValue] = useState("");
  const [markdownResponse, setMdResponse] = useState("");
  const [generateContent] = useAIContent();

  const destroyElement = () => {
    setOpen(false);
  };

  const addResponse = (response: string) => {
    setMdResponse((r) => r + response);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    generatePrompt();
  };

  const generatePrompt = async () => {
    setInputValue("");
    setStatus("loading");
    try {
      await generateContent({
        chatMessages: generalPrompt(inputValue),
        setResponse: addResponse,
        onStreamComplete: () => {
          setStatus("done");
        },
      });

      // TMP event to check if many users use the AI
    } catch (e) {
      setStatus("done");
    }
  };

  const keepResponse = () => {
    setOpen(false);

    insertMarkdown(markdownResponse, { silent: true });

    retryInput();
  };

  const retryInput = () => {
    setInputValue("");
    setMdResponse("");
    setStatus("idle");
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      handleSubmit();
    }
  };

  return (
    <Wrapper onKeyDown={onKeyDown}>
      <PromptWrapper>
        <UserPrompt
          inputValue={inputValue}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          status={status}
          destroyElement={() => setOpen(false)}
          keepResponse={keepResponse}
          retryInput={retryInput}
        />
        {status !== "idle" && (
          <MarkdownORHTMLResponse html={markdownResponse} />
        )}
        {/* If the response is long, we display a second actions bar */}
        {status === "done" && markdownResponse.length > 400 && (
          <DoneActions
            destroyElement={destroyElement}
            keepResponse={keepResponse}
            retryInput={retryInput}
          />
        )}
      </PromptWrapper>
      {/* TODO: re-add when available */}
      {/* <ActionBox>
        <LinkButton onClick={() => console.log("nav to settings")}>
          Change AI Settings
        </LinkButton>
      </ActionBox> */}
    </Wrapper>
  );
};
