import styled from "styled-components";
import { marked } from "marked";

interface Props {
  markdown?: string;
  html?: string;
}
export const MarkdownORHTMLResponse = ({ markdown, html }: Props) => {
  return (
    <Wrapper
      dangerouslySetInnerHTML={{
        // @ts-ignore
        __html: markdown
          ? marked(markdown, {
              silent: true,
              breaks: true,
            })
          : html ?? "",
      }}
    ></Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 12px 24px 10px 10px;
  font-family: system-ui;
  font-size: 15px;
  line-height: 1.4;
  ol {
    list-style: decimal;
    padding-left: 16px;
    padding-top: var(--ntc-user-block-padding);
    padding-bottom: var(--ntc-user-block-padding);

    margin-left: 12px;
    color: var(--ntc-user-font-color);
  }
  ul {
    padding-left: 16px;
    padding-top: var(--ntc-user-block-padding);
    padding-bottom: var(--ntc-user-block-padding);

    margin-left: 12px;
    color: var(--ntc-user-font-color);
  }
  li {
    padding-left: 0px;
    color: var(--ntc-user-font-color);
  }
  h1 {
    padding: 0;
    span {
      font-size: var(--ntc-user-h1-size);
      font-weight: var(--ntc-user-h1-weight);
    }
  }
  h2 {
    padding: 0;
    span {
      font-size: var(--ntc-user-h2-size);
      font-weight: var(--ntc-user-h2-weight);
    }
  }
  h3 {
    padding: 0;
    span {
      font-size: var(--ntc-user-h3-size);
      padding: var(--ntc-user-headings-padding);
      font-weight: var(--ntc-user-h3-weight);
    }
  }
`;
