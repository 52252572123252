import { ChangeEvent, DragEvent, useRef } from "react";
import { toast } from "react-hot-toast";
import styled, { useTheme } from "styled-components";
import { useUploadFile, useUploadPP } from "../../queries/file/useFiles";
import { SmallLoader } from "../../Components/SmallLoader";

export type FileType = "image" | "video" | "audio" | "application";
type Props = {
  type: "image" | "video" | "audio" | "application";
  acceptedTypes: Array<string>;
  children?: React.ReactNode;
  mutation: ReturnType<typeof useUploadFile | typeof useUploadPP>["mutation"];
};

export const DropZoneArea = ({
  type,
  acceptedTypes,
  children,
  mutation,
}: Props) => {
  const { mutate, isPending } = mutation;
  const theme = useTheme();

  const inputFile = useRef<HTMLInputElement | null>(null);
  const acceptAllTypes = acceptedTypes[0] === "*";

  const OpenFileSelector = () => {
    inputFile.current?.click();
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (
        !acceptedTypes.includes(e.dataTransfer.files[0].type) &&
        !acceptAllTypes
      ) {
        toast.error("Sorry file type not accepted");
      } else if (type === "image" && e.dataTransfer.files[0].size > 6291456) {
        toast.error("File size too big, Must be under 6MB.");
      } else if (
        (type === "video" || type === "audio" || type === "application") &&
        e.dataTransfer.files[0].size > 25165824
      ) {
        toast.error("File size too big, Must be under 24MB.");
      } else {
        await mutate({
          file: e.dataTransfer.files[0],
          type,
        });
      }
    }
  };

  const handleImageSelection = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.files && e.target.files[0]) {
      if (type === "image" && e.target.files[0].size > 6291456) {
        toast.error("File size too big, Must be under 6MB.");
      } else if (type === "video" && e.target.files[0].size > 25165824) {
        toast.error("File size too big, Must be under 24MB.");
      } else {
        await mutate({
          file: e.target.files[0],
          type,
        });
      }
    }
  };

  return (
    <DropZone
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      onClick={OpenFileSelector}
      style={{ pointerEvents: isPending ? "none" : "auto" }}
    >
      <input
        onChange={handleImageSelection}
        accept={acceptedTypes.join(",")}
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
      />
      {children ?? (
        <>
          <AcceptedTypes>
            {acceptAllTypes
              ? "Any type of document is accepted"
              : `Accepted types: ${acceptedTypes.join(", ")}`}
          </AcceptedTypes>
          <StyledButton
            color={theme.primary}
            onClick={(e) => {
              e.stopPropagation();
              OpenFileSelector();
            }}
          >
            Browse
            {isPending ? (
              <SmallLoader size={"16px"} color={theme.white} />
            ) : (
              // <UploadIcon color={theme.white} size={16} />
              <div>uploadicon</div>
            )}
          </StyledButton>
        </>
      )}
    </DropZone>
  );
};

const DropZone = styled.div`
  box-sizing: border-box;
  position: relative;

  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 0px;
  color: ${({ theme }) => theme.primary};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledButton = styled.button`
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;

  background-color: ${({ theme }) => theme.primary};
  color: white;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  cursor: pointer;
`;

const AcceptedTypes = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.primary};
`;
