import { IconProps } from ".";

export const DividerIcon = ({ size = 30 }: IconProps) => {
  return (
    <svg
      style={{ width: size, height: size }}
      width="264"
      height="220"
      viewBox="0 0 264 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.5 82.5H170.5C171.959 82.5 173.358 81.9205 174.389 80.8891C175.421 79.8576 176 78.4587 176 77C176 75.5413 175.421 74.1424 174.389 73.1109C173.358 72.0795 171.959 71.5 170.5 71.5H93.5C92.0413 71.5 90.6424 72.0795 89.6109 73.1109C88.5795 74.1424 88 75.5413 88 77C88 78.4587 88.5795 79.8576 89.6109 80.8891C90.6424 81.9205 92.0413 82.5 93.5 82.5Z"
        fill="#69B3FC"
      />
      <path
        d="M192.5 115.5H71.5C68.475 115.5 66 113.025 66 110C66 106.975 68.475 104.5 71.5 104.5H192.5C195.525 104.5 198 106.975 198 110C198 113.025 195.525 115.5 192.5 115.5Z"
        fill="#3A85D0"
      />
      <path
        d="M93.5 148.5H170.5C171.959 148.5 173.358 147.921 174.389 146.889C175.421 145.858 176 144.459 176 143C176 141.541 175.421 140.142 174.389 139.111C173.358 138.079 171.959 137.5 170.5 137.5H93.5C92.0413 137.5 90.6424 138.079 89.6109 139.111C88.5795 140.142 88 141.541 88 143C88 144.459 88.5795 145.858 89.6109 146.889C90.6424 147.921 92.0413 148.5 93.5 148.5Z"
        fill="#69B3FC"
      />
    </svg>
  );
};
