import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import { theme } from "./style/theme";
import { Toaster } from "react-hot-toast";
import { PostHogNotice } from "./PostHog/PostHogNotice";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: 1,
    },
  },
});

const customShouldForwardProp = (propName) => propName !== "hide";

root.render(
  <React.StrictMode>
    <PostHogNotice>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <StyleSheetManager shouldForwardProp={customShouldForwardProp}>
            <App />
          </StyleSheetManager>
        </ThemeProvider>
      </QueryClientProvider>
    </PostHogNotice>

    <Toaster
      position="bottom-left"
      toastOptions={{
        style: {
          borderRadius: 4,
          boxShadow: theme.shadow,
          fontSize: 15,
        },
      }}
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
