import { Navigate, useParams } from "react-router-dom";
import { PAGES } from "../../Router/constants";
import { usePage } from "../../queries/page/usePages";
import { Tiptap } from "../TipTap/TipTap";
import styled from "styled-components";
import { CogIcon } from "../../icons/CogIcon";
import { NoticeToolTip } from "../../Components/ToolTip/ToolTip";
import { useEffect, useState } from "react";
import { PageSettings } from "./PageSettings";
import { TranslateTab } from "./Translation/TranslateTab";
import { Translate01 } from "../../icons/Translate01";
import { useQueryParam } from "../../hooks/useQueryParam";

const Tabs = {
  settings: {
    name: "settings",
    width: "388px",
    label: "Settings",
  },
};

export function EditorPage() {
  const { pageId } = useParams();
  const { isLoading } = usePage().query;
  const [start, setStart] = useState(1);
  const [getQueryParam, setQueryParam] = useQueryParam();
  const currentTab = getQueryParam("tab") ?? "";

  useEffect(() => {
    setTimeout(() => {
      setStart(0);
    }, 300);
  }, []);

  if (!pageId && !pageId) {
    return <Navigate to={"/" + PAGES.WORKSPACE} replace />;
  }

  if (isLoading) return null;

  return (
    <Wrapper>
      <NoticeToolTip
        anchorSelect=".page-settings-tooltip"
        delayShow={300}
        place="right"
      >
        {Tabs.settings.label}
      </NoticeToolTip>
      {/* <NoticeToolTip
        anchorSelect=".page-translate-tooltip"
        delayShow={300}
        place="right"
      >
        {Tabs.translate.label}
      </NoticeToolTip> */}

      <TiptapContainer>
        <Tiptap />
        <TabContainer>
          <Tab
            opened={currentTab !== "" ? 1 : 0}
            style={{ width: currentTab !== "" ? Tabs[currentTab].width : 0 }}
          >
            {currentTab === Tabs.settings.name && <PageSettings />}
            {/* {currentTab === Tabs.translate.name && <TranslateTab />} */}
          </Tab>
          <PageSettingsContainer start={start}>
            <IconContainer
              className="page-settings-tooltip"
              onClick={() => {
                setQueryParam(
                  "tab",
                  currentTab === Tabs.settings.name ? "" : Tabs.settings.name
                );
              }}
            >
              <CogIcon />
            </IconContainer>
            {/* <IconContainer
              className="page-translate-tooltip"
              onClick={() => {
                setQueryParam(
                  "tab",
                  currentTab === Tabs.translate.name ? "" : Tabs.translate.name
                );
              }}
            >
              <Translate01 />
            </IconContainer> */}
          </PageSettingsContainer>
        </TabContainer>
      </TiptapContainer>
    </Wrapper>
  );
}

const Tab = styled.div<{ opened: number }>`
  opacity: ${({ opened }) => (opened ? "1" : "0")};
  min-height: 300px;
  backdrop-filter: blur(16px);

  transition: padding 0.5s ease-in-out, width 0.5s ease-in-out,
    opacity 0.8s ease-in-out;
  background-color: rgb(0 0 0 / 2.5%);
  padding: ${({ opened }) => (opened ? "16px" : "16px 0px")};
  box-sizing: border-box;
  border-radius: 0px 0px 8px 0px;
  height: fit-content;
`;

const TabContainer = styled.div`
  display: flex;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  svg {
    transition: all 0.5s ease-out;
    transform: rotate(0deg);
  }

  &:hover {
    svg {
      transition: all 0.5s ease-out;
      transform: rotate(90deg);
    }
  }
  padding: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageSettingsContainer = styled.div<{ start: number }>`
  height: ${({ start }) => (start ? "0px" : "40px")};
  width: ${({ start }) => (start ? "0px" : "40px")};
  backdrop-filter: blur(16px);

  background-color: rgb(0 0 0 / 2.5%);

  height: fit-content;
  border-radius: 0px 8px 8px 0px;
  transition: width 0.5s ease-out;
`;

const TiptapContainer = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  /* Compensate for the width of the PageSettingsContainer */
  left: 40px;
`;
