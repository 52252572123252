import { useQuery } from "@tanstack/react-query";
import { Price, Product, getPrice, getProduct } from "./payment.calls";

export const usePrice = (priceId: string) => {
  // Queries
  const query = useQuery<Price>({
    queryKey: ["price", priceId],
    queryFn: () => getPrice(priceId),
  });

  // Mutations

  return {
    query,
  };
};

export const useProduct = (productId: string) => {
  // Queries
  const query = useQuery<Product>({
    queryKey: ["product", productId],
    queryFn: () => getProduct(productId),
  });

  // Mutations

  return {
    query,
  };
};
