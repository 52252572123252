import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useOutlet } from "react-router";
import styled, { createGlobalStyle } from "styled-components";
import { useLostSavingConfirmation } from "../../hooks/useLostSavingConfirmation";
import { BreadcrumbNav } from "../BreadcrumbNav/BreadcrumbNav";
import { FeedbackButton } from "../FeedbackButton/FeedbackButton";

export function RootPage() {
  const location = useLocation();
  useLostSavingConfirmation();
  const element = useOutlet(); // provided by react-router-dom

  const GlobalStyle = createGlobalStyle`
`;

  useEffect(() => {
    if (window?.innerWidth < 768) {
      localStorage.getItem("shownAlert") !== "yes" &&
        alert(
          "Notice won't properly work on small screens. Please use a bigger screen for the best experience."
        );
      localStorage.setItem("shownAlert", "yes");
    }
  }, []);

  if (location.pathname === "/") {
    // forward the search params to the workspace location to handle payment success
    return <Navigate to={`/workspace`} replace />;
  }

  return (
    <AppContainer>
      {!location.pathname.includes("/connect") && (
        <>
          <FeedbackButton />

          <BreadcrumbNav />
        </>
      )}

      <GlobalStyle />
      <CoolBg>{/* <Container></Container> */}</CoolBg>

      <MainContainer>
        <Outlet />
      </MainContainer>
    </AppContainer>
  );
}

const AppContainer = styled.div`
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: baseline;
  align-items: center;
  margin: 0;
  color: ${(props) => props.theme.text};
  backdrop-filter: blur(5px);
  background-color: rgb(0 0 0 / 0.5%);

  h1 {
    margin-top: 0px;
    margin-bottom: 16px;
    font-weight: 300;
  }

  h2 {
    margin-top: 0px;
    margin-bottom: 16px;
    font-weight: 200;
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 16px;
    font-weight: 200;
  }

  h1:focus {
    border-color: #000;
    outline: none !important;
  }

  [contenteditable="true"]::selection {
    background-color: ${(props) => props.theme.pastelBlue};
    color: inherit; /* Keeps the text color the same as the element's text color */
    outline: none;
  }
`;

const MainContainer = styled.div`
  gap: 32px;
  width: 100%;
  min-width: 70%;
  min-height: 90vh;
  width: calc(100% - 20vh);
  margin: 0 auto;
  padding: 5vh 64px 64px 64px;
  margin: 5vh 10vh 0 10vh;
`;

const CoolBg = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: -1;
  pointer-events: none;
  &::after {
    content: "";
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0.3;
    filter: invert(1);
    background-image: url(https://assets-notice.b-cdn.net/v2-editor/textured-noise.png);
  }
`;

// const Container = styled.div`
//   position: absolute;
//   content: "";
//   width: 100vw;
//   min-height: 100vh;
//   height: 100%;

//   /* ${(props) => {
//     const { highlight = "blue" } = props.theme;
//     return css`
//       background-image: radial-gradient(at 27% 37%, #3a85d0 0, transparent 0),
//         radial-gradient(at 97% 21%, #3a85d0 0, transparent 25%),
//         radial-gradient(at 52% 99%, #3a85d0 0, transparent 25%),
//         radial-gradient(at 10% 29%, #3a85d0 0, transparent 25%),
//         radial-gradient(at 97% 96%, ${highlight} 0, transparent 25%),
//         radial-gradient(at 33% 50%, ${highlight} 0, transparent 25%),
//         radial-gradient(at 79% 53%, red 0, transparent 25%);
//     `;
//   }} */
// `;
