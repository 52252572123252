import { AxiosAPI } from "../api";

export interface BillingPortal {
  url: string;
}

export type Product = {
  name: string;
  marketing_features: { name: string }[];
};
export async function getProduct(productId: string): Promise<Product> {
  return (await AxiosAPI.get(`/payment/product/${productId}`)).data;
}

export type Price = {
  // in cents
  amount: number;
  currency: string;
  interval: string;
};
export async function getPrice(priceId: string): Promise<Price> {
  return (await AxiosAPI.get(`/payment/price/${priceId}`)).data;
}

// Calls
export async function createBillingPortal(
  _workspaceId: string
): Promise<BillingPortal> {
  return (
    await AxiosAPI.post(
      `/payment/create_billing_portal`,
      {},
      { params: { workspaceId: _workspaceId } }
    )
  ).data;
}
