import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  useEffect(() => {
    setTimeout(() => {
      // Go to previous page
      window.history.back();
    }, 3000);
  }, []);

  return (
    <div id="error-page">
      <p>
        Sorry, an unexpected error has occurred. You will be redirected to the
        previous page in a few seconds.
      </p>
      <p>
        <i>Error: {error.statusText || error.message}</i>
      </p>
    </div>
  );
}
