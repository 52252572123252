import { FloatingMenu } from "@tiptap/react";
import { useRef } from "react";
import styled from "styled-components";
import { BulletedListIcon } from "../../icons/BulletedList.icon";
import { CodeIcon } from "../../icons/Code.icon";
import { DividerIcon } from "../../icons/Divider.icon";
import { Heading1Icon } from "../../icons/Heading1.icon";
import { Heading2Icon } from "../../icons/Heading2.icon";
import { Heading3Icon } from "../../icons/Heading3.icon";
import { HintIcon } from "../../icons/Hint.icon";
import { ImageBlockIcon } from "../../icons/ImageBlock.icon";
import { NumberedListIcon } from "../../icons/NumberedList.icon";
import { QuoteIcon } from "../../icons/Quote.icon";
import { TextIcon } from "../../icons/Text.icon";
import { useUploadFile } from "../../queries/file/useFiles";

export function BlockMenu({ editor }: any) {
  const imageInput = useRef<HTMLInputElement | null>(null);
  const { mutateAsync: uploadFile } = useUploadFile().mutation;

  const OpenFileSelector = () => {
    imageInput.current?.click();
  };

  const handleImageSelection = async (files: FileList) => {
    if (files && files.length > 0) {
      for (const file of Array.from(files)) {
        const [mime] = file.type.split("/");

        if (mime === "image") {
          const url = URL.createObjectURL(file);
          editor
            ?.chain()
            .focus()
            .setImage({
              src: url,
            })
            .run();

          const fileResData = await uploadFile({
            file: files[0],
            type: mime,
          });
          editor?.chain().focus().setImage({ src: fileResData.url }).run();
        }
      }
    }
  };

  return (
    <CoolBg>
      <FloatingMenu
        className='floating-menu'
        editor={editor}
        tippyOptions={{ duration: 100, placement: "left" }}
        shouldShow={({ editor, view, state, oldState }) => {
          if (!editor || editor.isDestroyed) {
            return false;
          }
          const { from } = editor.state.selection;
          const parent = editor.state.doc.resolve(from).parent;
          const textContent = parent.textContent;
          const isSlashFirstCharacter =
            textContent.startsWith("/") && textContent.length === 1;

          if (isSlashFirstCharacter) {
            const { from, to } = editor.state.selection;

            if (from === to && from > 1) {
              editor
                .chain()
                .focus()
                .deleteRange({ from: from - 1, to })
                .run();
            }
          }
          return isSlashFirstCharacter;
        }}
      >
        <Button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive("paragraph") ? "is-active" : ""}
        >
          <IconContainer>
            <TextIcon size={30} />
          </IconContainer>

          <Text>Paragraph</Text>
        </Button>
        {/* <Button
          onClick={() => editor?.chain().focus().setFile({ name: "yo" }).run()}
        >
          <IconContainer>
            <TextIcon size={30} />
          </IconContainer>

          <Text>File</Text>
        </Button> */}
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={
            editor.isActive("heading", { level: 1 }) ? "is-active" : ""
          }
        >
          <IconContainer>
            <Heading1Icon size={32} />
          </IconContainer>
          <Text>Header 1</Text>
        </Button>
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={
            editor.isActive("heading", { level: 2 }) ? "is-active" : ""
          }
        >
          <IconContainer>
            <Heading2Icon size={32} />
          </IconContainer>

          <Text>Header 2</Text>
        </Button>
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={
            editor.isActive("heading", { level: 3 }) ? "is-active" : ""
          }
        >
          <IconContainer>
            <Heading3Icon size={32} />
          </IconContainer>

          <Text>Header 3</Text>
        </Button>
        <Button onClick={() => editor.chain().focus().setHint().run()}>
          <IconContainer>
            <HintIcon size={30} />
          </IconContainer>

          <Text>Hint</Text>
        </Button>

        <Button
          onClick={() => {
            OpenFileSelector();

            // if (url) {
            //   editor.chain().focus().setImage({ src: url }).run();
            // }
          }}
        >
          <input
            onChange={(e) => {
              console.log(e);
              handleImageSelection(e.target.files);
            }}
            type='file'
            style={{ display: "none" }}
            ref={imageInput}
            accept='image/*'
          />

          <IconContainer>
            <ImageBlockIcon size={32} />
          </IconContainer>

          <Text>Image</Text>
        </Button>

        <Button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          <IconContainer>
            <BulletedListIcon size={30} />
          </IconContainer>

          <Text>Bullet List</Text>
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          <IconContainer>
            <NumberedListIcon size={30} />
          </IconContainer>

          <Text>Ordered List</Text>
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive("codeBlock") ? "is-active" : ""}
        >
          <IconContainer>
            <CodeIcon size={30} />
          </IconContainer>
          <Text>Code Block</Text>
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive("blockquote") ? "is-active" : ""}
        >
          <IconContainer>
            <QuoteIcon size={30} />
          </IconContainer>
          <Text>Quote</Text>
        </Button>
        <Button onClick={() => editor.chain().focus().setHTMLCode().run()}>
          <IconContainer>
            <DividerIcon size={30} />
          </IconContainer>
          <Text>HTML</Text>
        </Button>
        <Button
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
        >
          <IconContainer>
            <DividerIcon size={30} />
          </IconContainer>
          <Text>Divider</Text>
        </Button>
      </FloatingMenu>
    </CoolBg>
  );
}

const CoolBg = styled.div``;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  height: 100%;
  width: 32px;
`;

const Text = styled.div`
  padding: 16px 16px 16px 0px;
`;

const Button = styled.button`
  * {
    color: ${(props) => props.theme.text};
  }
  display: flex;
  font-size: inherit;
  font-family: inherit;
  color: #000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid transparent;
  width: 200px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(15px);

  &:hover {
    background-color: rgba(227, 227, 227, 0.5);
  }
`;
