import { useMutation } from "@tanstack/react-query";
import { AxiosAPI } from "../../../../queries/api";

export const useDomainSetup = () => {
  const mutation = useMutation({
    mutationFn: async ({ project, ...body }: any) => {
      const { data } = await AxiosAPI.post(
        `/domain/${project._id}/setup`,
        body
      );
      return data;
    },
    retry: false,
  });

  return { mutation };
};
