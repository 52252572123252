import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { User, getUser, updateUser } from "./user.calls";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";

export const useUser = () => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ["user"],
    queryFn: getUser,
  });
  const { projectId = "", workspaceId = "" } = useParams();

  // Mutations
  const mutation = useMutation({
    mutationFn: (user: Partial<User>) => updateUser({ ...user }),
    onMutate: async (user) => {
      queryClient.cancelQueries();

      const previousUser = queryClient.getQueryData(["user"]);

      queryClient.setQueryData(["user"], (old: any) => {
        return { ...old, ...user };
      });

      return { previousUser };
    },
    onSuccess: ({ data }: any) => {
      queryClient.setQueryData(["user"], (old: any) => {
        return data;
      });

      if (projectId)
        queryClient.invalidateQueries({ queryKey: ["pages", projectId] });
      if (workspaceId)
        queryClient.invalidateQueries({
          queryKey: ["collaborators", workspaceId],
        });
    },
    onError: (err: any, project, context) => {
      queryClient.setQueryData(["user"], (old: any) => {
        return context.previousUser;
      });

      console.log(err);

      toast.error(err.response.data.errors[0].msg);
    },
  });

  return {
    query,
    mutation,
  };
};
