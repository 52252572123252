const endpoints = {
  prod: {
    API: "https://api-v2.notice.studio",
  },
  dev: {
    API: "http://localhost:3014",
  },
};

export const ENDPOINTS =
  endpoints[process.env.NODE_ENV === "production" ? "prod" : "dev"];
