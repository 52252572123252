import styled from "styled-components";
import { usePage } from "../../queries/page/usePages";
import { useProject } from "../../queries/project/useProject";
import { TextInput } from "../../Components/Input/InputText";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../Components/Popover/Popover";
import { NoticeToolTip } from "../../Components/ToolTip/ToolTip";
import { useEffect, useState } from "react";
import { CopyIcon } from "../../icons/CopyIcon";
import { toast } from "react-hot-toast";
import { ProfileModal, ProfilePlaceholder } from "../Profile/ProfileModal";
import { TextArea } from "../../Components/Textarea/TextArea.component";
import { theme } from "../../style/theme";
import { usePublishPage } from "../../queries/publish/usePublish";
import { Link } from "react-router-dom";
import { LinkExternalIcon } from "../../icons/link-external.icon";

export function DatePublished() {
  const {
    query: { data: page },
    mutation: updatePage,
  } = usePage();

  const { datePublished = new Date() } = page;

  return (
    <DateInput
      onChange={(e) => updatePage.mutate({ datePublished: e.target.value })}
      type="date"
      value={datePublished}
    />
  );
}

const DateInput = styled.input`
  border: 1px solid ${({ theme }) => theme.lightText};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 12px;
  font-family: system-ui;
  background-color: rgba(255, 255, 255, 0.55);
  cursor: pointer;
  font-size: 13px;
  color: ${({ theme }) => theme.text};

  &:hover {
    background-color: ${({ theme }) => theme.pastelYellow};
  }
  transition: background-color 0.2s ease-in-out;
`;

export function PageSlug() {
  const {
    query: { data: page },
    mutation: updatePage,
  } = usePage();

  const { data: project } = useProject().query ?? {};
  const { slug: projectSlug } = project ?? {};
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(page?.slug ?? "");
  const { mutation: publishMutation, query: publishQuery } = usePublishPage(
    page,
    project
  );
  const { data: publishStatus } = publishQuery;

  const isPlublishing = publishStatus === "publishing";
  const { lastPublishedDate } = page;

  useEffect(() => {
    if (open === false && url !== page.slug) {
      updatePage.mutate({ slug: url });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setURL but only allow lowercase letters, numbers, and hyphens

    // setURL but only allow letters, numbers, and hyphens
    setUrl(e.target.value.replace(/[^a-zA-Z0-9-]/g, "").toLowerCase());
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverContent className="Popover">
        <InputContainer
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setOpen(false);
            }
          }}
          spellCheck={false}
        >
          <HardCodedUrl>{projectSlug}.notice.site/</HardCodedUrl>
          <TextInput value={url} onChange={onChange} placeholder="my-site" />
        </InputContainer>
      </PopoverContent>
      <LinkToProject>
        <NoticeToolTip anchorSelect=".change-url-tooltip">
          Change page URL
        </NoticeToolTip>

        <PopoverTrigger className="change-url-tooltip">
          <WebsiteName onClick={() => setOpen((v) => !v)}>
            {projectSlug}.notice.site/{page?.slug}
          </WebsiteName>
        </PopoverTrigger>
        {lastPublishedDate && (
          <Link
            to={`https://${project?.slug}.notice.site/${page?.slug}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ padding: 0, margin: 0, height: "100%" }}
          >
            <IconContainer className="visit-website-tooltip">
              <LinkExternalIcon size={14} />
            </IconContainer>
          </Link>
        )}
      </LinkToProject>

      {!lastPublishedDate && (
        <p style={{ color: theme.error }}>
          You need to{" "}
          <StyledButton
            onClick={() => {
              publishMutation.mutate();
            }}
          >
            {isPlublishing ? "publishing..." : "publish"}
          </StyledButton>{" "}
          this page once before accessing it.
        </p>
      )}
    </Popover>
  );
}

const WebsiteName = styled.div`
  border-radius: 4px 0px 0px 4px;
  height: 32px;

  display: flex;
  align-items: center;
  cursor: text;
  width: 100%;

  transition: background-color 0.2s ease-in-out;
`;
const IconContainer = styled.div`
  height: 100%;
  padding: 3px 3px 3px 3px;
  padding-left: 8px;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  height: 32px;

  border-left: 1px solid ${(props) => props.theme.lightText};
`;

const StyledButton = styled.button`
  border: 1px solid ${({ theme }) => theme.error};
  border-radius: 2px;
  color: ${({ theme }) => theme.error};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.error};
    color: white;
  }
`;

const HardCodedUrl = styled.span`
  display: flex;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  height: 40px;
  gap: 3px;
`;

const LinkToProject = styled.div`
  background-color: rgba(255, 255, 255, 0.55);
  background-image: url(https://assets-notice.b-cdn.net/v2-editor/textured-noise.png);
  margin-left: 0px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.text};
  border: 1px solid ${(props) => props.theme.lightText};

  padding: 0px 8px;

  border-radius: 4px;
  justify-content: space-between;

  transition: background-color 0.2s ease-in-out;
`;

export function PageID() {
  const {
    query: { data: page },
  } = usePage();
  return (
    <PageIDWrapper>
      <PageIDContainer>{page?._id}</PageIDContainer>

      <CopyIconContainer
        onClick={() => {
          toast.dismiss();
          // copy pageID in the clipboard
          navigator.clipboard.writeText(page._id);

          // show a toast message
          toast.success("Page ID copied to clipboard");
        }}
      >
        <CopyIcon />
      </CopyIconContainer>
    </PageIDWrapper>
  );
}

const PageIDContainer = styled.div`
  padding: 8px 12px;
`;

const CopyIconContainer = styled.span`
  margin-left: 8px;
  cursor: pointer;
  padding: 8px 12px;
  border-left: 1px solid ${(props) => props.theme.lightText};
  border-radius: 0px 4px 4px 0px;
  &:hover {
    background-color: ${(props) => props.theme.pastelYellow};
  }
  transition: background-color 0.2s ease-in-out;
`;

const PageIDWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.lightText};
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: space-between;
  text-align: left;

  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.55);
`;

export function PageDescription() {
  const {
    query: {
      data: { description = "" },
    },
    mutation: updatePage,
  } = usePage();

  return (
    <TextArea
      placeholder="Write a summary of the page here (Max. 300 characters)."
      maxLength={300}
      updateAction={(e) => {
        updatePage.mutate({ description: e.target.value });
      }}
      defaultValue={description}
    />
  );
}

export function PageAuthor() {
  const {
    query: {
      data: { author = null },
    },
  } = usePage();
  const [profileOpen, setProfileOpen] = useState(false);

  if (!author) return <>-</>;
  const { picture } = author;

  return (
    <AuthorWrapper>
      {picture ? <Img src={picture} alt="user" /> : <ProfilePlaceholder />}
      <ProfileModal profileOpen={profileOpen} setProfileOpen={setProfileOpen} />
      <UserName>
        <div style={{ textAlign: "center" }}> {author.name}</div>
        <EditProfileButton onClick={() => setProfileOpen(true)}>
          Edit profile
        </EditProfileButton>
      </UserName>
    </AuthorWrapper>
  );
}

const EditProfileButton = styled.div`
  font-size: 11px;
  margin-top: 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.lightText};
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const UserName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 32px;
  justify-content: space-around;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.lightText};
  border-radius: 4px;
  gap: 8px;
  background-color: rgba(255, 255, 255, 0.55);
`;

const Img = styled.img`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
`;
