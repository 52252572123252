import { Navigate, useParams } from "react-router-dom";
import { PagesList } from "./PagesList";
import { useProject } from "../../queries/project/useProject";
import { Button } from "../../Components/Button";
import styled from "styled-components";
import { usePublish } from "../../queries/publish/usePublish";
import { PageConfiguration } from "./ProjectConfiguration";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../Components/Dialog/Dialog";
import { NoticeToolTip } from "../../Components/ToolTip/ToolTip";
import { DomainConfiguration } from "./DomainConfiguration";
import { BuildingIcon } from "../../icons/building.icon";
import { useEffect } from "react";
import { useQueryParam } from "../../hooks/useQueryParam";
import { ChevronIcon } from "../../icons/ChevronIcon";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { PreferencesTab } from "./PreferencesTab";
import { useProjectType } from "../../hooks/useProjectType";
import { usePages } from "../../queries/page/usePages";
import { TemplateTab } from "./TemplateTab";

export function ProjectPage() {
  const { projectId } = useParams();
  const [getQueryParam, setQueryParam] = useQueryParam();
  const currentTab = getQueryParam("tab");
  const { pageName } = useProjectType();
  const { data: pages } = usePages().query;

  const { query, mutation } = useProject();
  const [wrapperRef] = useAutoAnimate(/* optional config */);

  const { data, isLoading } = query;
  const { mutation: publishMutation, query: publishQuery } = usePublish(data);
  const { data: publishStatus } = publishQuery;

  const { mutate } = mutation;

  useEffect(() => {
    if (!currentTab || currentTab === "") {
      setQueryParam("tab", "pages");
    }
  }, []);

  const publish = async () => {
    await publishMutation.mutate();
  };

  if (!projectId) {
    return <Navigate to="/workspace" replace />;
  }

  if (isLoading) return <></>;
  if (!data) return null;

  const { name } = data;

  return (
    <>
      <NoticeToolTip
        anchorSelect=".project-settings-tooltip"
        delayShow={300}
        place="top"
      >
        Project settings
      </NoticeToolTip>
      <NoticeToolTip
        anchorSelect=".project-domain-tooltip"
        delayShow={300}
        place="top"
      >
        Domain configuration
      </NoticeToolTip>

      <Container>
        <h1
          contentEditable
          style={{
            marginBottom: 16,
          }}
          onBlur={(e) => {
            mutate({ name: e.currentTarget.textContent });
          }}
          suppressContentEditableWarning
        >
          {name}
        </h1>

        <RightActionsContainer>
          <Dialog>
            <DialogContent style={{ width: "100%" }}>
              <DomainConfiguration />
            </DialogContent>

            <DialogTrigger asChild={true}>
              <BuildingIconContainer className="project-domain-tooltip">
                <BuildingIcon />
              </BuildingIconContainer>
            </DialogTrigger>
          </Dialog>

          {pages && pages.length ? (
            <StyledButton
              onClick={() => publish()}
              color="white"
              loading={publishStatus === "publishing"}
              loaderPosition="relative"
              style={{ marginLeft: 16 }}
            >
              Publish
            </StyledButton>
          ) : (
            <></>
          )}
        </RightActionsContainer>
      </Container>
      <h2 style={{ marginTop: 22 }}>General</h2>
      <PageConfiguration />
      <div ref={wrapperRef}>
        <SectionTitle
          onClick={() =>
            setQueryParam("tab", currentTab === "pages" ? "" : "pages")
          }
        >
          <ChevronIcon
            style={{
              transform:
                currentTab === "pages" ? "rotate(90deg)" : "rotate(0deg)",
              transition: "transform 0.2s ease-in-out",
            }}
          />

          <H2>
            {pageName}
            {pages && pages.length > 1 ? "s" : ""}
          </H2>
        </SectionTitle>

        {currentTab === "pages" && <PagesList />}
        <SectionTitle
          onClick={() =>
            setQueryParam(
              "tab",
              currentTab === "preferences" ? "" : "preferences"
            )
          }
        >
          <ChevronIcon
            style={{
              transform:
                currentTab === "preferences" ? "rotate(90deg)" : "rotate(0deg)",
              transition: "transform 0.2s ease-in-out",
            }}
          />
          <H2>Customization</H2>
        </SectionTitle>
        {currentTab === "preferences" && <PreferencesTab />}
        <SectionTitle
          onClick={() =>
            setQueryParam("tab", currentTab === "template" ? "" : "template")
          }
        >
          <ChevronIcon
            style={{
              transform:
                currentTab === "template" ? "rotate(90deg)" : "rotate(0deg)",
              transition: "transform 0.2s ease-in-out",
            }}
          />
          <H2>Template</H2>
        </SectionTitle>
        {currentTab === "template" && <TemplateTab />}
      </div>
    </>
  );
}

const H2 = styled.h2`
  margin-bottom: 0px !important;
`;

const SectionTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }

  /* User can't select the text */
  user-select: none;
`;

const RightActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BuildingIconContainer = styled.div`
  cursor: pointer;
  transition: all 0.5s ease-out;

  display: flex;
  align-items: center;
  justify-content: center;
  path {
    transition: all 0.5s ease-out;
  }

  &:hover {
    path {
      stroke: ${({ theme }) => theme.primary};
    }
  }
`;

const StyledButton = styled(Button)`
  /* margin-bottom: 16px; */
  font-weight: bold;
  height: 30px;
  font-size: 13px;
  letter-spacing: 1px;
  &:hover {
    background-color: black;

    color: white;
  }
`;

const Container = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
