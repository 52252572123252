import styled from "styled-components";
import { FeatherIcon } from "../../icons/Feather.icon";
import { Portal } from "../../Components/Portal";
import { theme } from "../../style/theme";
import { Dialog, DialogContent } from "../../Components/Dialog/Dialog";
import { useState } from "react";
import { TextArea } from "../../Components/Textarea/TextArea.component";
import { Button } from "../../Components/Button";
import { SlackIcon } from "../../icons/SlackIcon";
import { sendHelpMessage } from "../../queries/helpmessage/helpMessage.calls";
import { useUser } from "../../queries/user/useUser";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Lottie from "lottie-react";
import animationData from "../../assets/check.lottie.json";

export function FeedbackButton() {
  const [open, setOpen] = useState(false);
  return (
    <Portal>
      <FeedbackDialog open={open} setOpen={setOpen} />
      <Wrapper>
        <StyledFeedbackButton onClick={() => setOpen(true)}>
          <FeatherIcon size={14} color={theme.lightText} />
          Feedback / Help
        </StyledFeedbackButton>
      </Wrapper>
    </Portal>
  );
}

const Wrapper = styled.div`
  // fixed at the bottom right of the screen
  position: fixed;
  bottom: 20px;
  right: 20px;
`;

const StyledFeedbackButton = styled.button`
  // some styles
  background-color: white;
  color: ${(props) => props.theme.lightText};
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  font-weight: 500;
  box-shadow: inset 0 2px 1px #00000026, 0 0 0 2px #ffffff1a;
  // no outlines
  outline: none;

  /* Inset shadow that goes well with the bg color */

  &:hover {
    font-size: 13px;
  }
`;
interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}

function FeedbackDialog({ open, setOpen }: Props) {
  const [wrapperRef] = useAutoAnimate(/* optional config */);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState<
    "idle" | "sending" | "success" | "error"
  >("idle");
  const { data: user } = useUser().query;
  const sendEmail = async () => {
    // send email
    try {
      setStatus("sending");
      await sendHelpMessage({
        message: message.replaceAll("\n", "<br>"),
        senderEmail: user.email,
      });
      setStatus("success");
    } catch (e) {
      console.error(e);
      setStatus("error");
    }
    // AxiosAPI.post("/feedback", { message });
  };

  //   TODO: Only rerender when status changes
  const MessageComp = () => {
    if (status === "idle")
      return (
        <>
          <StyledTextArea
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            placeholder={"Type your message here"}
            defaultValue={""}
            style={{ width: "100%" }}
            minHeight="72px"
            maxLength={1000}
          />
          <div style={{ alignSelf: "end" }}>
            <Button onClick={() => sendEmail()}>Send Message</Button>
          </div>
        </>
      );
    if (status === "sending") return <div>Sending...</div>;
    if (status === "success")
      return (
        <SuccessWrapper>
          <Lottie
            animationData={animationData}
            loop={false}
            style={{ width: 80, height: 80 }}
          />
          <i>Message sent successfully, we'll come back to you soon.</i>
          <Button onClick={() => setOpen(false)}>Close Window</Button>
        </SuccessWrapper>
      );
    if (status === "error") return <div>Failed to send message</div>;
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <Container ref={wrapperRef}>
          <h2>Want to give us feedback or need help?</h2>
          <YouCanJoin>
            <JoinSlack>
              You can join the community on
              <div
                onClick={() =>
                  window.open(
                    "https://join.slack.com/t/noticegroupe/shared_invite/zt-21nkruxea-gTZ5jfvevH49JvzQrHdfhQ",
                    "_blank"
                  )
                }
                style={{
                  display: "flex",
                  gap: 8,
                  cursor: "pointer",
                  alignItems: "center",
                  border: `1px solid ${theme.borderColor}`,
                  padding: "6px",
                  borderRadius: "5px",
                  backgroundColor: theme.lightBg,
                }}
              >
                <span>Slack</span>
                <SlackIcon size={24} />
              </div>
              or directly write to us below.
            </JoinSlack>
          </YouCanJoin>
          {MessageComp()}
        </Container>
      </DialogContent>
    </Dialog>
  );
}

const JoinSlack = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const YouCanJoin = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.border};
  padding-bottom: 16px;
  width: 100%;
`;

const StyledTextArea = styled(TextArea)`
  margin-top: 32px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
