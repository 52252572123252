import { langs } from "@uiw/codemirror-extensions-langs";
import { tokyoNightStorm } from "@uiw/codemirror-theme-tokyo-night-storm";
import CodeMirror from "@uiw/react-codemirror";
import { useRef } from "react";
import styled, { useTheme } from "styled-components";
import { Portal } from "../../Components/Portal";
import { useOnKeyPressed } from "../../hooks/useKeyPressed";
import Cross from "../../icons/Cross";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  fullHeight?: boolean;
  placeholder: string;
  val: string;
  setVal: (value: string) => void;
}

export const CodeEditorModal = ({
  open,
  setOpen,
  fullHeight = false,
  placeholder,
  val,
  setVal,
}: Props) => {
  const theme = useTheme();
  const pageRef = useRef<HTMLDivElement | null>(null);

  const close = () => {
    setOpen(false);
  };

  useOnKeyPressed("escape", () => close());

  if (!open) return null;

  return (
    <Portal>
      <Background
        onClick={(e) => {
          if (
            pageRef.current != null &&
            pageRef.current.contains(e.target as Node)
          )
            return;
          close();
        }}
      >
        <Page ref={pageRef} show={open} fullHeight={fullHeight}>
          <Wrapper fullHeight={fullHeight}>
            <ExitButton onClick={close}>
              <Cross color={theme.white} size={16} />
            </ExitButton>
            <Content noPadding={!fullHeight}>
              <CodeMirror
                theme={tokyoNightStorm}
                placeholder={placeholder}
                height='80vh'
                width='100%'
                extensions={[langs.html()]}
                basicSetup={{
                  autocompletion: true,
                  bracketMatching: true,
                }}
                onChange={(v) => setVal(v)}
                value={val}
              />
            </Content>
          </Wrapper>
        </Page>
      </Background>
    </Portal>
  );
};

const Background = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.6);

  overflow: hidden;
  z-index: 9000;

  transition: opacity 0.15s ease-in-out;
`;

const Page = styled.div<{ show: boolean; fullHeight: boolean }>`
  margin: 0px;
  max-width: 1280px;
  width: 100%;
  height: 100%;

  overflow-y: hidden;
  overflow-x: hidden;
  transform: ${({ show }) => (show ? "scale(1)" : "scale(0.9)")};

  border-radius: 8px;

  transition: transform 0.15s ease-in-out;
`;

const Wrapper = styled.div<{ fullHeight: boolean }>`
  height: 100%;
  overflow-y: ${({ fullHeight }) => (fullHeight ? "auto" : undefined)};
`;

const Content = styled.div<{ noPadding: boolean }>`
  padding-right: ${({ noPadding }) => (noPadding ? undefined : "52px")};
`;

const ExitButton = styled.div`
  position: sticky;
  float: right;
  top: 8px;
  right: 8px;

  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:hover line {
    stroke: ${({ theme }) => theme.primary};
    fill: ${({ theme }) => theme.primary};
    transition: opacity 0.5s ease-in-out;
  }
`;
