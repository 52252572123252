import { useLocation, useNavigate, useParams } from "react-router";
import { PAGES } from "../Router/constants";

export const useQueryParam = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const setQueryParam = (param, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(param, value);
    navigate({ pathname: location.pathname, search: searchParams.toString() });
  };

  const getQueryParam = (param: string, value?: any): any => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };
  const deleteQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(param);
    navigate({ pathname: location.pathname, search: searchParams.toString() });
  };

  return [getQueryParam, setQueryParam, deleteQueryParam];
};

export const useNavigateToProject = () => {
  const navigate = useNavigate();
  const { workspaceId } = useParams();

  return (id: string) => {
    return navigate(
      `/${PAGES.WORKSPACE}/${workspaceId}/${PAGES.PROJECT}/${id}`
    );
  };
};

export const useNavigateToPage = () => {
  const navigate = useNavigate();
  const { workspaceId, projectId } = useParams();

  return (id: string) => {
    return navigate(
      `/${PAGES.WORKSPACE}/${workspaceId}/${PAGES.PROJECT}/${projectId}/${PAGES.EDITOR}/${id}`
    );
  };
};

export const useNavigateToWorkspace = () => {
  const navigate = useNavigate();

  return (id: string) => {
    // Save workspaceId in local storage
    localStorage.setItem("workspaceId", id);
    return navigate(`/${PAGES.WORKSPACE}/${id}`);
  };
};
