import { AxiosAPI } from "../api";
import { Project, ProjectTypes } from "../project/project.calls";

// export type ProjectSummary = Pick<Project, "name" | "_id">;

// Calls
export function createProject({
  name,
  _id,
  workspaceId,
  type = "blog",
  template = "blog-01",
}: {
  name: string;
  _id: string;
  workspaceId: string;
  type: ProjectTypes;
  template: string;
}): Promise<Project> {
  return AxiosAPI.post(`/projects?workspaceId=${workspaceId}`, {
    name,
    _id,
    type,
    template,
  });
}

export async function getProjects(
  workspaceId: string
): Promise<Array<Project>> {
  const { data } = await AxiosAPI.get(`/projects?workspaceId=${workspaceId}`);
  return data;
}
