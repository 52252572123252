import { AbsoluteCenter, Heading, VStack } from "@chakra-ui/react";
import Lottie from "lottie-react";
import { useNavigate } from "react-router";
import { PAGES } from "../../Router/constants";
import animationData from "../../assets/check.lottie.json";
import { useTimeout } from "../../hooks/useTimeout";

export function SubscriptionCreatedRedirect() {
  const navigate = useNavigate();

  useTimeout(() => {
    navigate(`/${PAGES.WORKSPACE}`);
  }, 3000);

  return (
    <AbsoluteCenter>
      <VStack>
        <Lottie
          animationData={animationData}
          loop={false}
          style={{ width: 300, height: 300 }}
        />
        <Heading>Thank you for your subscription!</Heading>
      </VStack>
    </AbsoluteCenter>
  );
}
