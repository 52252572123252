import axios from "axios";
import { ENDPOINTS } from "../tools";

export const AxiosAPI = axios.create({
  baseURL: ENDPOINTS.API,
  timeout: 60_000,
  withCredentials: true,
});

AxiosAPI.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

AxiosAPI.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (
      ["user_not_found", "token_invalid_or_expired"].includes(
        error.response.data
      )
    )
      window.location.href = "/connect";

    return Promise.reject(error);
  }
);
