import { IconProps } from ".";

export const CodeIcon = ({ size = 30 }: IconProps) => {
  return (
    <svg
      style={{ width: size, height: size }}
      width="264"
      height="220"
      viewBox="0 0 264 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M115.55 165.001C114.18 165.001 112.811 164.49 111.744 163.472L72.5789 125.919C63.9165 117.262 63.9165 103.303 72.4964 94.7232L111.717 56.5591C113.894 54.4416 117.376 54.4856 119.493 56.6691C121.611 58.8471 121.561 62.3285 119.383 64.446L80.2183 102.555C75.9834 106.79 75.9834 113.775 80.2733 118.059L119.356 155.531C121.55 157.632 121.622 161.118 119.521 163.307C118.443 164.429 116.996 165.001 115.55 165.001ZM152.389 163.439L191.504 125.27C200.079 116.69 200.079 102.731 191.427 94.0797L152.361 56.5371C150.172 54.4361 146.691 54.5076 144.584 56.6911C142.483 58.8801 142.549 62.3615 144.738 64.468L183.727 101.928C188.012 106.218 188.012 113.197 183.777 117.438L144.705 155.564C142.527 157.687 142.489 161.168 144.606 163.34C145.684 164.446 147.114 165.001 148.544 165.001C149.93 165.001 151.316 164.479 152.383 163.439H152.389Z"
        fill="#3A85D0"
      />
    </svg>
  );
};
