import styled from "styled-components";
import { theme } from "../../style/theme";

function generateRandomNumberFromString(str) {
  // Ensure the input is a lowercase alphanumeric string
  const alphanumericLowercase = str.toLowerCase().replace(/[^a-z1-9]/g, "");

  let hash = 0;

  // Basic hash function: sum the character codes of the string
  for (let i = 0; i < alphanumericLowercase.length; i++) {
    hash += alphanumericLowercase.charCodeAt(i);
  }

  // Use modulo to scale the hash to the range 0-5
  const randomNumber = hash % 6;

  return randomNumber;
}

const bgColors = [
  theme.pastelBlue,
  theme.pastelGreen,
  theme.pastelOrange,
  theme.pastelPink,
  theme.pastelPurple,
  theme.pastelYellow,
];

export function CoverImage({ image, _id }) {
  const randomNumber = generateRandomNumberFromString(_id);
  const color = bgColors[randomNumber];
  return (
    <ImageContainer>
      {!image ? (
        <FallbackContainer
          style={{ backgroundColor: color }}
        ></FallbackContainer>
      ) : (
        <StyledImg
          src={image}
          alt="cover"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "8px 8px 0px 0px",
          }}
        />
      )}
    </ImageContainer>
  );
}

const StyledImg = styled.img``;

const FallbackContainer = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: 500;
  padding: 8px;
  border-radius: 8px 8px 0px 0px;
  background-color: ${({ theme }) => theme.pastelPink};

  :hover {
    background-color: rgb(0 0 0 / 3%);
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  padding: 0px;
  border-bottom: 1px solid #e0e0e0;

  height: 62%;
`;
