import React from "react";
import styled from "styled-components";

export const TextInput = React.forwardRef(function TextInput(
  props: any,
  ref: any
) {
  return <StyledInput {...props} type="text" ref={ref} />;
});

const StyledInput = styled.input`
  padding: 8px;
  border: 1px solid ${(props) => props.theme.lightText};
  border-radius: 4px;
  font-size: 16px;
  color: #000;
  background-color: rgba(255, 255, 255, 1);
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: #000;
    outline: none !important;
  }

  /* Make the placeholder italic */
  &::placeholder {
    font-style: italic;
  }
`;
