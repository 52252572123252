// import { ChakraProvider } from "@chakra-ui/react";
import { NoticeRouter } from "./Router/Router";

function App() {
  return (
    // <ChakraProvider>
    <NoticeRouter />
    // </ChakraProvider>
  );
}

export default App;
