import styled from "styled-components";
import { ChangeEventHandler, HTMLInputTypeAttribute } from "react";

interface Props extends React.ComponentPropsWithoutRef<"input"> {
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  value?: string | number | readonly string[];
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const SignInCode = ({
  type,
  placeholder,
  value,
  onChange,
  ...props
}: Props) => {
  return (
    <InputWrapper>
      <Input
        value={value}
        onChange={onChange}
        placeholder={"Enter temporary code"}
        {...props}
        autoComplete="off"
      />
    </InputWrapper>
  );
};

export const SignInInput = ({
  type,
  placeholder,
  value,
  onChange,
  ...props
}: Props) => {
  return (
    <InputWrapper>
      <Input
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        {...props}
        autoComplete="email"
      />
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 0 16px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: ${({ theme }) => theme.borderRadius};

  &:focus-within {
    border-color: ${({ theme }) => theme.primary};
  }

  /* Override Chrome's autocomplete styles for input fields */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${({ theme }) => theme.text} !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  }

  /* For other browsers like Firefox */
  input:-moz-ui-invalid {
    box-shadow: none;
  }
`;

const Input = styled.input`
  flex: 1 1 0%;
  padding: 8px 0;

  &::placeholder {
    color: ${({ theme }) => theme.lightText};
    padding-left: 2px;
  }

  /* Reset */
  outline-style: none;
  overflow: hidden;
  appearance: none;
  background-color: transparent;
  border-radius: 0px;
  line-height: 22px;
  border: none;
  box-sizing: border-box;
  margin: 0;
  resize: none;
`;
