import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createProject, getProjects } from "./projects.calls";
import { toast } from "react-hot-toast";
import { ProjectTypes } from "../project/project.calls";

export const useProjects = (workspaceId: string) => {
  const queryClient = useQueryClient();

  // Queries
  const query = useQuery({
    queryKey: ["projects", workspaceId],
    queryFn: () => getProjects(workspaceId),
  });

  // Mutations
  const mutation = useMutation({
    mutationFn: ({
      name,
      _id,
      type,
      template,
    }: {
      name: string;
      _id: string;
      type: ProjectTypes;
      template: string;
    }) => createProject({ name, _id, workspaceId, type, template }),
    onMutate: async ({ name, _id, type, template }) => {
      queryClient.cancelQueries();
      queryClient.setQueryData(["projects", workspaceId], (old: any) => {
        return [...old, { name, _id, type, template }];
      });
    },

    onSuccess: ({ data }: any) => {
      queryClient.setQueryData(["projects", workspaceId], (old: any) => {
        return old.map((p: any) => {
          if (p._id === data._id) {
            return data;
          }
          return p;
        });
      });
    },
    onError: (err: any, { _id }) => {
      queryClient.setQueryData(["projects", workspaceId], (old: any) => {
        const rollBack = old.filter((w: any) => w._id !== _id);
        return rollBack;
      });

      toast.error(err.response.data.errors[0].msg);
    },
  });

  return {
    query,
    mutation,
  };
};
