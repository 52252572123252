import { IconProps } from ".";

export const LinkedInIcon = ({ size = 16, color = "#007BB5" }: IconProps) => {
  return (
    <>
      <svg
        enableBackground="new 0 0 32 32"
        width={size}
        height={size}
        id="Layer_1"
        version="1.0"
        viewBox="0 0 32 32"
      >
        <g>
          <rect fill={color} height="23" width="7" y="9" />
          <path
            d="M24.003,9C20,9,18.89,10.312,18,12V9h-7v23h7V19c0-2,0-4,3.5-4s3.5,2,3.5,4v13h7V19C32,13,31,9,24.003,9z"
            fill={color}
          />
          <circle cx="3.5" cy="3.5" fill={color} r="3.5" />
        </g>
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
      </svg>{" "}
    </>
  );
};
