import styled from "styled-components";
import { Button } from "../../Components/Button";
import { PROJECT_TYPES_MAP } from "../../hooks/useProjectType";
import { LinkExternalIcon } from "../../icons/link-external.icon";

interface Props {
  type: string;
  setTemplate: (template: string) => void;
  selectedTemplate?: string;
}

export function TemplateSelector({
  type,
  setTemplate,
  selectedTemplate,
}: Props) {
  const templates = PROJECT_TYPES_MAP[type]?.templates ?? {};
  return (
    <TemplateWrapper>
      {Object.entries(templates).map(([key, value]) => {
        const selected = selectedTemplate === key;
        return (
          <Template key={key} $selected={selected}>
            <TitleAndDescription>
              {/* @ts-ignore */}
              <Title>{value?.name || key}</Title>
              {/* @ts-ignore */}
              <Description>{value?.description}</Description>
            </TitleAndDescription>
            {!selected && (
              <div>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => {
                    setTemplate(key);
                  }}
                >
                  Select this template
                </Button>
                <Example template={value} />
              </div>
            )}
          </Template>
        );
      })}{" "}
    </TemplateWrapper>
  );
}

const TitleAndDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-size: 21px;
`;

const Description = styled.span`
  font-size: 14px;
  padding-top: 8px;
`;

const TemplateWrapper = styled.div`
  display: flex;
  direction: row;
  flex-wrap: wrap;
  max-height: 410px;
  overflow: auto;
  gap: 8px;
`;

const Template = styled.div<{ $selected?: boolean }>`
  padding: 8px;
  border-radius: 4px;
  width: 190px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border: 1px solid ${(props) => props.theme.lightText};
  transition: 0.2s ease-in-out;
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.pastelYellow : "transparent"};
`;

const Example = ({ template }) => {
  const exampleUrl = template?.["app-url"];
  return (
    <Wrapper
      onClick={() => {
        window.open(exampleUrl);
      }}
      style={{ pointerEvents: exampleUrl ? "auto" : "none" }}
    >
      {exampleUrl ? (
        <>
          Visit demo <LinkExternalIcon />
        </>
      ) : (
        "No demo available"
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 8px;
  margin-top: 16px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.lightText};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.secondary};
    path {
      stroke: ${({ theme }) => theme.secondary};
    }
  }
`;
