import { useEffect } from "react";
import styled from "styled-components";
import { Button } from "../../Components/Button";
import { Dialog, DialogContent } from "../../Components/Dialog/Dialog";
import { createBillingPortal } from "../../queries/payment/payment.calls";
import { usePrice, useProduct } from "../../queries/payment/usePayment";
import { useWorkspace } from "../../queries/workspaces/useWorkspaces";
import { handleAPIErrors } from "../../tools/handleAPIErrors";
import { Loader } from "../ProjectPage/CustomDomainFlow/Loader";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}
export function SubscriptionModal({ open, setOpen }: Props) {
  const { query } = useWorkspace();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent noStyle>
        <Wrapper>
          {query.isLoading ? (
            <Loader />
          ) : query.data.subscriptionId != null ? (
            <MyPlan data={query.data} />
          ) : (
            <PricingTable data={query.data} />
          )}
        </Wrapper>
      </DialogContent>
    </Dialog>
  );
}

function MyPlan({ data }) {
  const { query: queryPrice } = usePrice(data.priceId);
  const { query: queryProduct } = useProduct(data.productId);

  return (
    <>
      {queryProduct.isLoading ? (
        <Loader />
      ) : (
        <ul>
          <li>{queryProduct.data?.name}</li>
          <li>
            {data.cancelAt ? (
              <p style={{ color: "red" }}>
                will be cancelled on{" "}
                {new Date(data.cancelAt * 1000).toLocaleDateString()}
              </p>
            ) : (
              <></>
            )}
          </li>
          <li>
            features:{" "}
            <ul>
              {queryProduct.data?.marketing_features?.map((m) => (
                <li key={m.name}>{m.name}</li>
              ))}
            </ul>
          </li>
        </ul>
      )}
      {queryPrice.isLoading ? (
        <Loader />
      ) : (
        <ul>
          <li>
            {queryPrice.data?.amount / 100} in &nbsp;
            {queryPrice.data?.currency} per &nbsp;
            {queryPrice.data?.interval}
          </li>
        </ul>
      )}
      <Button
        onClick={async () => {
          try {
            window.open((await createBillingPortal(data._id)).url);
          } catch (err) {
            console.error(err);
            handleAPIErrors(err);
          }
        }}
      >
        Manage
      </Button>
    </>
  );
}

function PricingTable({ data }) {
  useStripePricingTable();

  if (data.productId === "vip") {
    return <div>VIP Plan</div>;
  }

  return (
    <stripe-pricing-table
      pricing-table-id={
        process.env.NODE_ENV === "development"
          ? "prctbl_1PDTpOLoiTnsogUDERldCM69"
          : "prctbl_1PFhgCLoiTnsogUD4ytWkQdA"
      }
      publishable-key={
        process.env.NODE_ENV === "development"
          ? "pk_test_51PCGtdLoiTnsogUD5wasKiM16TJiwJKieBluQmlfxZHGDDtthLEYXaWwznPu3rVzsSHY2wLurEWfUbOT5VM55u6s0065qVU8R0"
          : "pk_live_51PCGtdLoiTnsogUDdG2W9dVBCJib0vMcvSljZ1BwScKD8UuM0heWShT0ZYd2uPOxL2LxuFerXw1pDMXRBYKAhQyc00ztbcgeg5"
      }
      client-reference-id={data?._id}
      //      customer-email={data?.contactEmail} add it to automatically fill the email field
      //      customer-name={"test+location_US@example.com"} add it to test price in $
    ></stripe-pricing-table>
  );
}

const useStripePricingTable = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

const Wrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 32px;
  height: 80vh;
  width: 80vw;
`;
