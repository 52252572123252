import styled, { useTheme } from "styled-components";
import { AIIcon } from "../../../icons/AIIcon";
import { Loader } from "../../ProjectPage/CustomDomainFlow/Loader";
import { ArrowRight } from "../../../icons/ArrowRight copy";

interface Props {
  inputValue: string;
  handleSubmit: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  status: "idle" | "loading" | "done";
  destroyElement: any;
  keepResponse: () => void;
  retryInput: () => void;
}

export const UserPrompt = ({
  inputValue,
  handleSubmit,
  handleInputChange,
  status,
  destroyElement,
  keepResponse,
  retryInput,
}: Props) => {
  const theme = useTheme();

  // This function is called when the user types in the textarea
  // It will resize the textarea to fit the text
  const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
    // @ts-ignore
    event.target.style.height = "21px"; // Reset the height at the start of the input event
    // @ts-ignore
    event.target.style.height = `${event.target.scrollHeight}px`; // Set the height to scroll height
  };

  return (
    <Wrapper>
      <div>
        <AIIcon size={16} color={theme.yellorange} />
      </div>
      <TextWrapper>
        {status === "loading" && (
          <ActionsWrapper style={{ justifyContent: "space-between" }}>
            <AIIsWriting>AI is writing...</AIIsWriting>
          </ActionsWrapper>
        )}
        {status === "idle" && (
          <TextArea
            autoFocus
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Describe what you want to create to our AI"
            onInput={handleInput}
          />
        )}
        {status === "done" && (
          <DoneActions
            destroyElement={destroyElement}
            keepResponse={keepResponse}
            retryInput={retryInput}
          />
        )}
      </TextWrapper>
      <StyledRow>
        {status === "loading" && <Loader size={17} color={theme.yellorange} />}
        {status === "idle" && (
          <WrapperArrowRight
            disabled={!inputValue}
            onClick={() => inputValue && handleSubmit()}
          >
            <ArrowRight size={20} color={"white"} />
          </WrapperArrowRight>
        )}
      </StyledRow>
    </Wrapper>
  );
};

interface DoneActionsProps {
  destroyElement: any;
  keepResponse: () => void;
  retryInput?: () => void;
}

export const DoneActions = ({
  destroyElement,
  keepResponse,
  retryInput,
}: DoneActionsProps) => {
  return (
    <ActionsWrapper>
      <MainCTA onClick={keepResponse}>Keep</MainCTA>
      {retryInput && <ActionWrapper onClick={retryInput}>Retry</ActionWrapper>}
      <ActionWrapper onClick={destroyElement}>
        Cancel <Kdb>ESC</Kdb>
      </ActionWrapper>
    </ActionsWrapper>
  );
};

const MainCTA = styled.span`
  transition: opacity 0.1s ease-in-out;
  font-size: 15px;
  padding: 4px;
  border-radius: 4px;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.mariner};
  color: white;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

const Kdb = styled.kbd`
  background-color: ${({ theme }) => theme.lightGrey};
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 20px;
  padding: 1px 3px;
`;

const ActionWrapper = styled.div`
  transition: background-color 0.1s ease-in-out;

  margin-right: 8px;
  justify-content: flex-end;
  font-size: 15px;
  color: ${({ theme }) => theme.grey};
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.lightGrey};
  }
  padding: 4px;
  border-radius: 4px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const AIIsWriting = styled.span`
  font-weight: 500;
  font-size: 14.5px;

  color: ${(props) => props.theme.yellorange};
`;

const TextWrapper = styled.div`
  width: 100%;
  padding-left: 12px;
`;

const WrapperArrowRight = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  border-radius: 100%;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.grey : theme.yellorange};
  width: 20px;
  height: 20px;
  align-self: center;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  :hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 0.8)};
  }
`;

const TextArea = styled.textarea`
  font-family: inherit;
  outline: none;
  overflow: hidden;
  height: 21px;
  font-size: inherit;
  line-height: inherit;
  border: none;
  background: none;
  width: 100%;
  display: block;
  resize: none;
  padding: 0px;
  font-size: 14px;
`;

const Wrapper = styled.div`
  background-color: white;
  display: flex;
  width: 100%;
  max-width: fill-available;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
`;
const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 8px;
`;
