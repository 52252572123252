import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../../Components/Dialog/Dialog";
import styled from "styled-components";
import { generateImagePrompt } from "../../../hooks/ai/chatMessages";
import { useNoStreamGenerateContent } from "../../../hooks/ai/useAiGeneratePage";
import { UserPrompt } from "./UserPrompt";
import { MagicWandIcon } from "../../../icons/MagicWand.icon";
import { Loader } from "../../ProjectPage/CustomDomainFlow/Loader";
import { theme } from "../../../style/theme";
import { Editor } from "@tiptap/react";
import { useAIGenerateImage } from "../../../hooks/ai/useAIGenerateImage";

interface Props {
  editor?: Editor;
  children: React.ReactNode;
  insertImage: (url: string) => void;
}

export function ImageAIPrompt({ children, insertImage, editor }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent noStyle={true}>
        <Content insertImage={insertImage} setOpen={setOpen} editor={editor} />
      </DialogContent>
      <DialogTrigger
        className="change-url-tooltip"
        asChild={true}
        style={{ height: "100%", width: "100%" }}
      >
        <FlexyChildren
          onClick={() => setOpen(true)}
          style={{ height: "100%", width: "100%" }}
        >
          {children}
        </FlexyChildren>
      </DialogTrigger>
    </Dialog>
  );
}

const FlexyChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ContentProps {
  editor?: Editor;
  setOpen: (val: boolean) => void;
  insertImage: (url: string) => void;
}

const Content = ({ insertImage, setOpen, editor }: ContentProps) => {
  const [status, setStatus] = useState<"idle" | "loading" | "done">("idle");
  const [generateNoStream] = useNoStreamGenerateContent();

  const [inputValue, setInputValue] = useState("");
  const [markdownResponse, setMdResponse] = useState("");
  const { mutateAsync: generateImageAsync } = useAIGenerateImage();
  const [suggesting, setSuggesting] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    generateImage();
  };

  const generateImage = async () => {
    setInputValue("");
    if (status === "loading") return;
    setStatus("loading");
    try {
      const data = await generateImageAsync({
        prompt: inputValue,
      });
      insertImage(data.url);
      setOpen(false);
      setStatus("done");

      // TMP event to check if many users use the AI
    } catch (e) {
      setStatus("done");
    }
  };

  const keepResponse = () => {
    setOpen(false);

    insertImage(markdownResponse);

    retryInput();
  };

  const retryInput = () => {
    setInputValue("");
    setMdResponse("");
    setStatus("idle");
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      handleSubmit();
    }
  };

  const suggestImage = async () => {
    setSuggesting(true);

    // Get text content from editor
    const html = editor?.getHTML() || "";

    await generateNoStream({
      chatMessages: generateImagePrompt(html),
      setResponse: (val) => {
        setInputValue(val);
      },
      onStreamComplete: () => {},
      temperature: 0.5,
      model: "gpt-4",
    });
    setSuggesting(false);
  };

  return (
    <Wrapper onKeyDown={onKeyDown}>
      <PromptWrapper>
        {status === "idle" && (
          <SuggestAction>
            {!suggesting ? (
              <div
                onClick={() => {
                  suggestImage();
                }}
                style={{ display: "flex", alignItems: "center", gap: 8 }}
              >
                <MagicWandIcon />
                <span>Suggest an image prompt</span>
              </div>
            ) : (
              <>
                <Loader size={17} color={theme.lightText} />
                Thinking...
              </>
            )}
          </SuggestAction>
        )}

        <UserPrompt
          inputValue={inputValue}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          status={status}
          destroyElement={() => setOpen(false)}
          keepResponse={keepResponse}
          retryInput={retryInput}
        />
        {/* If the response is long, we display a second actions bar */}
        {/* {status === "done" && markdownResponse.length > 400 && (
          <DoneActions
            destroyElement={destroyElement}
            keepResponse={keepResponse}
            retryInput={retryInput}
          />
        )} */}
      </PromptWrapper>
      {/* TODO: re-add when available */}
      {/* <ActionBox>
        <LinkButton onClick={() => console.log("nav to settings")}>
          Change AI Settings
        </LinkButton>
      </ActionBox> */}
    </Wrapper>
  );
};

const SuggestAction = styled.div`
  font-size: 14px;
  position: absolute;
  top: -48px;
  color: ${(props) => props.theme.lightText};
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  left: 0px;
  box-shadow: ${(props) => props.theme.shadow};
  width: fit-content;
  margin-bottom: 32px;
  background-color: white;
  gap: 8px;
  transition: 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

const Wrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-radius: 5px;
  width: 600px;
  max-height: 80vh;
  /* overflow: auto; */
  z-index: 999999;
  position: relative;

  max-width: fill-available;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
`;
const PromptWrapper = styled.div`
  width: 100%;
  max-width: fill-available;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
  padding: 10px 10px 10px 10px;
  margin-left: 0px;
`;
