import { Node } from "@tiptap/core";
import {
  mergeAttributes,
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react";
import styled from "styled-components";

export interface HTMLCodeOptions {
  HTMLAttributes: Record<string, any>;
  placeholder: string;
}

export const HTMLCode = ({ node, placeHolder }) => {
  const isEmpty = node.textContent.trim() === "";

  const localPlaceHolder =
    placeHolder && placeHolder.length > 0
      ? placeHolder
      : "Enter HTML code that will executed be in the renderer...";

  return (
    <NodeViewWrapper
      as={StyledPre}
      data-placeholder={localPlaceHolder}
      className={isEmpty ? "is-empty" : ""}
      data-type='html-code-block'
    >
      <NodeViewContent as={"code"} className='content' />
    </NodeViewWrapper>
  );
};

const StyledPre = styled.pre`
  background: #002451 !important;

  &.is-empty::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
  }
`;

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    html: {
      /**
       * Toggle a hint
       */
      setHTMLCode: () => ReturnType;
    };
  }
}

export const HTMLCodeExtension = Node.create<HTMLCodeOptions>({
  name: "html",

  group: "block",

  content: "text*",

  marks: "",

  code: true,

  selectable: false,

  whitespace: "pre",

  placeholder: "hello how are you.....?",

  //   @ts-ignore
  addNodeView() {
    return ReactNodeViewRenderer(HTMLCode);
  },

  parseHTML() {
    return [
      {
        tag: 'pre[data-type="html-code-block"]',
        preserveWhitespace: "full",
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const mergedAttributes = mergeAttributes(
      this.options.HTMLAttributes,
      HTMLAttributes
    );

    if (node.textContent.trim() === "") {
      return [
        "pre",
        {
          ...mergedAttributes,
          "data-placeholder": this.options.placeholder || "enter html block...",
        },
      ];
    } else {
      return ["pre", mergedAttributes, ["code", {}, node.textContent]];
    }
  },

  addCommands() {
    return {
      setHTMLCode:
        () =>
        ({ commands }) => {
          return commands.setNode(this.name);
        },
    };
  },
});
