import styled from "styled-components";

export function ToggleButton({ onClick, checked }) {
  return (
    <Switch className="switch" onClick={onClick}>
      <input type="checkbox" checked={checked} />
      <span className="slider round"></span>
    </Switch>
  );
}

const Switch = styled.label`
  position: relative;
  display: inline-block;

  width: 30px;
  height: 17px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 17px;

    &:before {
      position: absolute;
      content: "";
      height: 13px;
      width: 13px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: ${({ theme }) => theme.secondary};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${({ theme }) => theme.secondary};
  }

  input:checked + .slider:before {
    transform: translateX(13px);
  }
`;
