import styled from "styled-components";
import { PROJECT_TYPES_MAP } from "../../hooks/useProjectType";
import { templateColors } from "../../hooks/useProjectType";
import { darken } from "polished";

export function ProjectTypeSelector({ projectType = "", setProjectType }) {
  return (
    <ProjectTypesContainer>
      {Object.entries(PROJECT_TYPES_MAP).map(
        ([type, { text, description }]) => {
          return (
            <WrapperProjectType
              text={text}
              type={type}
              onClick={() => setProjectType(type)}
              key={type}
              selected={projectType === type}
              description={description}
            />
          );
        }
      )}
    </ProjectTypesContainer>
  );
}

const ProjectTypesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
`;

interface PillProps {
  text?: string;
  className?: string;
  onClick?: any;
  description?: string;
}

export function ProjectCard({
  text = "Blog",
  className,
  onClick,
  description,
}: PillProps) {
  return (
    <PillWrapper className={className} onClick={onClick}>
      <Title>{text}</Title>
      <Description>{description}</Description>
    </PillWrapper>
  );
}

const Description = styled.span`
  font-size: 14px;
  border-top: 1px solid white;
  padding-top: 8px;
`;

const Title = styled.span`
  color: white;
  font-size: 21px;
  font-weight: 700;
  padding-bottom: 8px;
`;

const PillWrapper = styled.div`
  padding: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.lightText};
  display: flex;
  flex-direction: column;
`;

export const WrapperProjectType = styled(ProjectCard)<{
  text: string;
  type: string;
  selected?: boolean;
}>`
  height: 200px;
  width: 190px;
  color: white;

  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ type }) => {
    // Add a gradient to the background color based on the type
    const baseColor = templateColors[type] ?? templateColors["blog"];
    return `linear-gradient(135deg, ${baseColor} 30%, ${darken(
      0.2,
      baseColor
    )} 100%)`;
  }};

  transition: 0.1s ease-in-out;

  border: 2px solid white;
  /* border: ${({ selected }) =>
    selected ? "2px solid white" : "2px solid transparent"}; */

  &:hover {
    opacity: 0.8;
  }
`;
