import styled from "styled-components";
import { Dialog, DialogContent } from "../../Components/Dialog/Dialog";
import { TextInput } from "../../Components/Input/InputText";
import {
  useUploadLogo,
  useWorkspace,
} from "../../queries/workspaces/useWorkspaces";
import { useCallback, useState } from "react";
import { DropZoneArea } from "../DropZoneArea/DropZoneArea";
import { Sketch } from "@uiw/react-color";
import { Button } from "../../Components/Button";
import { debounce } from "lodash";
import { useUser } from "../../queries/user/useUser";
import {
  Explanation,
  SectionTab,
} from "../../Components/SectionTab/SectionTab";

interface Props {
  settingsOpen: boolean;
  setSettingsOpen: (value: boolean) => void;
}
export function WorkspaceSettingsModal({
  settingsOpen,
  setSettingsOpen,
}: Props) {
  return (
    <Dialog open={settingsOpen} onOpenChange={setSettingsOpen}>
      <DialogContent style={{ textAlign: "center" }}>
        <WorkspaceSettings />
      </DialogContent>
    </Dialog>
  );
}

export function WorkspaceSettings() {
  const { query: { data: { email } } = { email: "" } } = useUser();
  const {
    query: { data: workspace, isLoading },
    mutation: updateWorkspace,
  } = useWorkspace();

  const handleNameChange = useCallback(
    debounce((e) => {
      updateWorkspace.mutate({ name: e.target.value });
    }, 400),
    []
  );

  const handleEmailChange = useCallback(
    debounce((e) => {
      updateWorkspace.mutate({ contactEmail: e.target.value });
    }, 400),
    []
  );

  if (isLoading) return;

  return (
    <Wrapper>
      <SectionTab title="General">
        <H3>Workspace name</H3>
        <TextInput
          placeholder="Name"
          defaultValue={workspace?.name}
          onChange={handleNameChange}
        />
      </SectionTab>
      <SectionTab title="Default values">
        <Explanation>
          These values are applied when you create a new project. You can
          override them in each project's <b>Customization</b> tab.
        </Explanation>
        <H3>Logo</H3>
        <p style={{ fontSize: 13, marginBottom: 16 }}>
          Must be square (ex: 36x36)
        </p>
        <WorkspaceLogo />
        <H3>Accent color</H3>
        <ColorPicker
          updateColor={(color) =>
            updateWorkspace.mutate({ accentColor: color })
          }
          initialValue={workspace?.accentColor}
        />

        <H3>Contact email</H3>
        <TextInput
          placeholder="Name"
          defaultValue={email}
          onChange={handleEmailChange}
        />
      </SectionTab>
    </Wrapper>
  );
}

const H3 = styled.h3`
  margin: 0;
  margin-top: 16px !important;
  padding: 0;
  border-bottom: 1px solid ${({ theme }) => theme.lightText};
`;

const Wrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: 600px;
  border: 1px solid ${({ theme }) => theme.lightText};
  padding: 8px;
  border-radius: 8px;
`;

function WorkspaceLogo() {
  const {
    query: { data: workspace },
  } = useWorkspace();
  const updateLogo = useUploadLogo().mutation;

  return (
    <DropZoneArea
      type={"image"}
      acceptedTypes={["image/png", "image/jpeg"]}
      mutation={updateLogo}
    >
      <WorkspaceLogoContainer>
        {workspace?.logoUrl && (
          <img src={workspace?.logoUrl} alt="workspace logo" />
        )}
        {!workspace?.logoUrl && <DropPlaceHolder>Upload file</DropPlaceHolder>}
      </WorkspaceLogoContainer>
    </DropZoneArea>
  );
}

const DropPlaceHolder = styled.div`
  border: 1px dashed ${({ theme }) => theme.lightText};
  padding: 8px;
  border-radius: 8px;
`;

const WorkspaceLogoContainer = styled.div`
  img {
    width: 50px;
    transition: border 0.3s;
    height: 50px;
  }
  cursor: pointer;

  display: flex;
  justify-content: center;
  &:hover {
    opacity: 0.8;
  }
  transition: opacity 0.3s ease-in-out;
`;

export const ColorPicker = ({ updateColor, initialValue = "#3182ce" }) => {
  const [open, setOpen] = useState(false);
  const [hex, setHex] = useState(initialValue);

  const handleColor = (color: string) => {
    updateColor(color);
    setOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <ClickerWrapper
        onClick={(e) => {
          setOpen(true);
        }}
      >
        <ColorSquare color={hex}></ColorSquare>
        {hex}
      </ClickerWrapper>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent style={{ textAlign: "center" }} noStyle={true}>
          <PickerWrapper>
            <Sketch
              color={hex}
              onChange={(color) => {
                setHex(color.hex);
              }}
              style={{
                boxShadow: "none",
              }}
            />
            <Buttons>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handleColor(hex);
                }}
                style={{ width: 100 }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setHex(initialValue || "#3182ce");
                  setOpen(false);
                }}
                style={{ width: 100 }}
              >
                Cancel
              </Button>
            </Buttons>
          </PickerWrapper>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const PickerWrapper = styled.div`
  box-shadow: ${(props) => props.theme.shadow};
  background-color: white;
  padding: 8px;
  border-radius: 8px;
`;

const Buttons = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 8px;
  background-color: white;
  justify-content: space-around;
`;

const ClickerWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  gap: 8px;
  width: fit-content;
  padding: 8px;
  border-radius: 4px;
  transition: transform 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const ColorSquare = styled.div<{ color: string }>`
  height: 30px;
  width: 30px;
  background-color: ${({ color }) => color};
  border-radius: 5px;
  cursor: pointer;
  border: 3px solid white;
`;
