import { Extension } from "@tiptap/core";
import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    noticeFileExtesion: {
      /**
       * Add a file
       */
      setFile: (options: { name: string }) => ReturnType;
    };
  }
}

export const NoticeFileExtension = Extension.create({
  name: "noticeFileExtension",
  draggable: true,
  addNodeView: () => ReactNodeViewRenderer(NoticeFileNode),
  addAttributes: () => ({
    file: {
      default: null,
    },
  }),
  addCommands: () => ({
    setFile:
      (attrs) =>
      ({ commands }) => {
        return commands.setNode("noticeFile");
      },
  }),
});

export const NoticeFileNode = Node.create({
  name: "noticeFile",
  group: "block",
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      file: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "div[data-type='noticeFile']",
        getAttrs: (dom) => {
          if (dom instanceof HTMLElement) {
            return {
              file: dom.getAttribute("data-file"),
            };
          }
          return;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    // return ["div", { "data-type": "noticeFile", ...HTMLAttributes }];
    return ["pre", ["code", HTMLAttributes, 0]];
  },

  addNodeView() {
    return ({ node }) => {
      const handleFileChange = async (event) => {
        const file = event.target.files[0];
        console.log("file change");
        // Perform file upload logic here (e.g., send file to server)
        // Update the node attributes with the uploaded file data
        // updateAttributes({ file: file.name });
      };

      return (
        <div>
          <input type="file" onChange={handleFileChange} />
          {node.attrs.file && <p>Uploaded file: {node.attrs.file}</p>}
        </div>
      );
    };
  },
});
