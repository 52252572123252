import { useCallback, useState } from "react";
import { debounce } from "lodash";
import useAutoSizeTextArea from "./useAutoSizeTextArea";
import styled from "styled-components";

interface Props {
  updateAction?: (e: any) => void;
  defaultValue: string;
  placeholder: string;
  minHeight?: string;
  onChange?: (e: any) => void;
  [key: string]: any;
}

export function TextArea({
  updateAction = () => {},
  defaultValue,
  placeholder,
  minHeight = "72px",
  onChange,
  ...props
}: Props) {
  const [val, setVal] = useState(defaultValue);

  const [textAreaRef, setTextAreaRef] = useState<HTMLTextAreaElement | null>(
    null
  );

  useAutoSizeTextArea(textAreaRef, val);

  const handleNameChange = useCallback(
    debounce((e) => {
      updateAction(e);
    }, 400),
    []
  );

  return (
    <StyledTextArea
      {...props}
      onChange={(e) => {
        handleNameChange(e);
        onChange && onChange(e);
        setVal(e.target.value);
      }}
      placeholder={placeholder}
      ref={setTextAreaRef}
      value={val}
      $minHeight={minHeight}
    ></StyledTextArea>
  );
}

const StyledTextArea = styled.textarea<{ $minHeight: string }>`
  min-height: ${({ $minHeight }) => $minHeight};
  box-sizing: border-box;
  font-family: inherit;
  outline: none;
  overflow: hidden;
  line-height: inherit;
  border: 1px solid ${({ theme }) => theme.lightText};
  border-radius: 4px;
  width: 100%;
  display: block;
  resize: none;
  padding: 8px;
  font-size: 13px;
  color: ${({ theme }) => theme.text};
  max-height: 200px;

  /* Make the placeholder italic */
  &::placeholder {
    font-style: italic;
  }

  background-color: rgba(255, 255, 255, 0.55);
`;
