import styled from "styled-components";

export function SmallLoader({ size = "22px", color = "white" }) {
  return <Loader color={color} size={size} />;
}

const Loader = styled.span<{ color: string; size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border: 3px solid ${({ color }) => color};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
