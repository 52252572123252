import { AxiosAPI } from "../api";

type LanguagesContent = {
  [key: string]: any;
};

export interface Page {
  content: any;
  _id: string;
  coverImage?: string;
  datePublished?: any;
  slug?: string;
  title?: string;
  description?: string;
  category?: string;
  tags?: Array<string>;
  author?: any;
  hidden?: boolean;
  html?: string;
  languagesContent?: LanguagesContent;
  lastPublishedDate?: any;
}

export function createPage({
  name,
  _id,
  projectId,
  noticeType,
}: {
  name: string;
  _id: string;
  projectId: string;
  noticeType: string;
}): Promise<Page> {
  return AxiosAPI.post(`/pages?projectId=${projectId}`, {
    name,
    _id,
    noticeType,
  });
}

export async function getPages({
  projectId,
}: {
  projectId: string;
}): Promise<Page[]> {
  const { data } = await AxiosAPI.get(`/pages?projectId=${projectId}`);
  return data ?? [];
}

export async function getPage({ pageId }): Promise<Page> {
  if (!pageId) return null;

  const { data } = await AxiosAPI.get(`/pages/${pageId}`);
  return data;
}

export async function updatePage(page: Partial<Page>): Promise<Page> {
  const { data } = await AxiosAPI.put(`/pages/${page._id}`, {
    page,
  });
  return data;
}

export async function updatePageContent(page: Partial<Page>): Promise<Page> {
  const { data } = await AxiosAPI.put(`/pages/${page._id}/content`, {
    page,
  });
  return data;
}

export async function updateTranslatedContent(
  page: Partial<Page>,
  lang: string
): Promise<Page> {
  if (!lang) return null;
  const { data } = await AxiosAPI.put(`/pages/${page._id}/translated-content`, {
    page,
    lang,
  });
  return data;
}

export async function deletePage(pageId: string): Promise<void> {
  const { data } = await AxiosAPI.delete(`/pages/${pageId}`);

  return data;
}

export async function duplicatePage(pageId: string): Promise<Page> {
  const { data } = await AxiosAPI.post(`/pages/${pageId}/duplicate`);
  return data;
}
