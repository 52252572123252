import { debounce } from "lodash";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { TextInput } from "../../Components/Input/InputText";
import { LanguageSelector } from "../../Components/LanguageSelector/LanguageSelector";
import {
  Explanation,
  SectionTab,
} from "../../Components/SectionTab/SectionTab";
import { TextArea } from "../../Components/Textarea/TextArea.component";
import { ToggleButton } from "../../Components/ToggleButton/ToggleButton";
import { PROJECT_TYPES_MAP } from "../../hooks/useProjectType";
import { useProject } from "../../queries/project/useProject";
import { useWorkspace } from "../../queries/workspaces/useWorkspaces";
import { PAGES } from "../../Router/constants";
import { CodeEditor } from "../CodeEditor/CodeEditor";
import { LanguageList } from "../EditorPage/Translation/LanguageList";
import { ColorPicker } from "../WorkspacePage/WorkspaceSettingsModal";
import { ProjectLogo } from "./ProjectLogo";

export function PreferencesTab() {
  const {
    query: { data: project },
  } = useProject();

  const { mutate: updateProject } = useProject().mutation;
  const projType = PROJECT_TYPES_MAP[project?.type ?? "blog"];
  const { name, languages, hideCreatedWithNotice = false } = project;
  const { query: workspace } = useWorkspace();
  const navigate = useNavigate();
  const { workspaceId } = useParams();

  const {
    subtitle = {},
    description = {},
    heroBackgroundColor = {},
  } = projType.config;
  const handleNameChange = useCallback(
    debounce((e) => {
      updateProject({ name: e.target.value });
    }, 400),
    []
  );
  const languagesArray = Object.keys(languages ?? {});
  return (
    <Wrapper>
      <SectionTab title='General'>
        <H3>Name</H3>
        <TextInput
          onChange={handleNameChange}
          placeholder='Username'
          defaultValue={name ?? ""}
        />
        <H3 style={{ marginTop: 16 }}>Logo</H3>
        <p style={{ fontSize: 13, margin: 0 }}>Must be square (ex: 36x36)</p>
        <ProjectLogo />
        <H3 style={{ marginTop: 16 }}>Hide "Created with Notice"</H3>
        <ToggleButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (workspace.data.productId != null) {
              updateProject({ hideCreatedWithNotice: !hideCreatedWithNotice });
            } else {
              navigate(`/${PAGES.WORKSPACE}/${workspaceId}?subopen=true`);
            }
          }}
          checked={hideCreatedWithNotice}
        />

        <H3 style={{ marginTop: 16 }}>Accent color</H3>
        <ColorPicker
          updateColor={(color) => updateProject({ accentColor: color })}
          initialValue={project?.accentColor}
        />

        <H3 style={{ marginTop: 16 }}>
          Add Your HTML Code, To be executed on each load.
        </H3>
        <CodeEditor
          onSave={(value) => updateProject({ headCode: value })}
          initialValue={project?.headCode}
        />
      </SectionTab>

      {(subtitle.show || description.show || heroBackgroundColor.show) && (
        <SectionTab title='Hero Page'>
          {subtitle.show && (
            <>
              <H3>Subtitle</H3>
              <TextArea
                updateAction={(e) =>
                  updateProject({ subtitle: e.target.value })
                }
                placeholder={subtitle.defaultValue}
                defaultValue={project?.subtitle}
                style={{ width: "100%" }}
                minHeight='72px'
                maxLength={subtitle.maxLength}
              />
            </>
          )}
          {description.show && (
            <>
              <H3 style={{ marginTop: 16 }}>Description</H3>
              <TextArea
                updateAction={(e) =>
                  updateProject({ description: e.target.value })
                }
                placeholder={description?.defaultValue}
                defaultValue={project?.description}
                style={{ width: "100%" }}
                minHeight='72px'
                maxLength={description?.maxLength}
              />
            </>
          )}
          {heroBackgroundColor.show && (
            <>
              <H3 style={{ marginTop: 16 }}>Hero Background Color</H3>{" "}
              <ColorPicker
                updateColor={(color) =>
                  updateProject({ heroBackgroundColor: color })
                }
                initialValue={project?.heroBackgroundColor}
              />
            </>
          )}
        </SectionTab>
      )}

      <SectionTab title='Language'>
        <>
          <H3>Default Language</H3>
          <Explanation>
            Default Language is the language you write in. It's used across the
            app for multiple purposes: metadata, translation features, etc.
          </Explanation>
          <LanguageSelector />
        </>
        <>
          <H3 style={{ marginTop: 16 }}>Other languages</H3>
          <Explanation>
            You can add languages directly in editor mode.
          </Explanation>
          {languagesArray.length !== 0 && (
            <LanguagesContainer>
              <LanguageList noLangSelect />
            </LanguagesContainer>
          )}
        </>
      </SectionTab>
    </Wrapper>
  );
}

const LanguagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const H3 = styled.h3`
  margin: 0;
  padding: 0;
  border-bottom: 1px solid ${({ theme }) => theme.lightText};
`;
const Wrapper = styled.div`
  width: 600px;
`;
