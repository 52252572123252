import styled from "styled-components";
import { CrossIcon } from "../../../icons/CrossIcon";
import { NoticeToolTip } from "../../../Components/ToolTip/ToolTip";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { useProject } from "../../../queries/project/useProject";
import { useAutoAnimate } from "@formkit/auto-animate/react";

export function LanguageList({ noLangSelect }: { noLangSelect?: boolean }) {
  const [listRef] = useAutoAnimate(/* optional config */);

  const { data: project } = useProject().query;

  const { languages = {} } = project ?? {};

  return (
    <Wrapper ref={listRef}>
      {Object.entries(languages).map(([languageCode, languageData]) => (
        <Language
          key={languageCode}
          language={languageData}
          noLangSelect={noLangSelect}
        />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 8px;
  background-color: transparent;
  height: 75px;
  overflow-y: auto;
  min-height: 0;
`;

export function Language({
  language,
  noLangSelect,
}: {
  language: any;
  noLangSelect?: boolean;
}) {
  const {
    query: { data: project },

    mutation: { mutate: updateProject },
  } = useProject();

  const [getQueryParam, setQueryParam] = useQueryParam();

  const handleLangDelete = async () => {
    const newLanguages = { ...project.languages };
    delete newLanguages[language.value];
    await updateProject({
      ...project,
      languages: newLanguages,
    });
  };

  return (
    <LanguageWrapper>
      <NoticeToolTip
        anchorSelect=".translate-delete-language-tooltip"
        delayShow={400}
      >
        Remove from the supported languages
      </NoticeToolTip>

      <Lang
        onClick={() => setQueryParam("translateto", language.value)}
        $selected={language.value === getQueryParam("translateto")}
        $noLangSelect={noLangSelect}
      >
        {language.label}
      </Lang>
      <IconContainer
        className="translate-delete-language-tooltip"
        onClick={() => {
          handleLangDelete();
        }}
      >
        <CrossIcon size={8} />
      </IconContainer>
    </LanguageWrapper>
  );
}

const Lang = styled.div<{ $selected: boolean; $noLangSelect?: boolean }>`
  border-right: 0.5px solid ${({ theme }) => theme.lightText};
  padding-right: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  padding-left: 8px;
  border-radius: 8px 0px 0px 8px;
  background-color: ${({ $selected, theme, $noLangSelect }) => {
    if ($noLangSelect) return theme.white;
    return $selected ? theme.pastelBlue : theme.white;
  }};
  pointer-events: ${({ $selected }) => ($selected ? "none" : "auto")};

  &:hover {
    background-color: ${({ theme, $selected, $noLangSelect }) => {
      if ($noLangSelect) return;
      return $selected ? theme.pastelBlue : theme.lightGrey;
    }};
  }
`;

const IconContainer = styled.div`
  height: 100%;
  padding: 8px;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.lightGrey};
  }
`;

const LanguageWrapper = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0;
  height: 32px;
  border-radius: 8px;
  border: 0.5px solid ${({ theme }) => theme.lightText};
`;
