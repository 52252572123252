import { IconProps } from ".";

export const Heading1Icon = ({ size = 30 }: IconProps) => {
  return (
    <svg
      style={{ width: size, height: size }}
      width="264"
      height="220"
      viewBox="0 0 264 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18_3432)">
        <path
          d="M131.77 71.5C129.113 71.5 126.957 73.6528 126.957 76.3125V103.583H82.0408V76.3125C81.9349 69.9857 72.5216 69.9889 72.4158 76.3125V143.688C72.5216 150.014 81.9349 150.011 82.0408 143.688V113.208H126.957V143.688C127.067 150.014 136.477 150.011 136.582 143.688V76.3125C136.582 73.6528 134.426 71.5 131.77 71.5Z"
          fill="#3A85D0"
        />
      </g>
      <g clipPath="url(#clip1_18_3432)">
        <path
          d="M172.791 137.498C171.526 137.498 170.499 136.472 170.499 135.207V87.1067L158.548 102.25C157.765 103.245 156.321 103.412 155.329 102.631C154.334 101.847 154.167 100.405 154.948 99.4107C154.948 99.4107 167.193 83.903 167.257 83.8411C168.574 82.5257 170.536 82.1384 172.255 82.8465C173.974 83.5592 175.083 85.2207 175.083 87.0815V135.207C175.083 136.472 174.056 137.498 172.791 137.498Z"
          fill="#3A85D0"
        />
      </g>
      <defs>
        <clipPath id="clip0_18_3432">
          <rect
            width="77"
            height="77"
            fill="white"
            transform="translate(66 71.5)"
          />
        </clipPath>
        <clipPath id="clip1_18_3432">
          <rect
            width="55"
            height="55"
            fill="white"
            transform="translate(143 82.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
