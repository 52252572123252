import { AxiosAPI } from "../api";

export interface Version {
  _id: string;
  createdAt: string;
}

export const getVersion = async (versionId: string) => {
  try {
    const response = await AxiosAPI.get(`/versions/${versionId}`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to get version");
  }
};

export const getAllVersions = async (pageId) => {
  try {
    const response = await AxiosAPI.get(`/versions/history/${pageId}`);
    return response.data ?? [];
  } catch (error) {
    throw new Error("Failed to get all versions");
  }
};

export const changeVersion = async (pageId: string, versionId: string) => {
  try {
    await AxiosAPI.post(`/versions/load`, { versionId, pageId });
  } catch (error) {
    throw new Error("Failed to change version");
  }
};
