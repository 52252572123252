import { AxiosAPI } from "../api";
import { Page } from "../page/pages.calls";

export type ProjectTypes = "blog" | "documentation" | "job-board";

export interface Project {
  name: string;
  _id: string;
  pages: Array<Page>;
  type: ProjectTypes;
  slug?: string;
  subtitle?: string;
  template?: string;
  description?: string;
  heroBackgroundColor?: string;
  accentColor?: string;
  defaultLanguage?: string;
  categories: string[];
  hideCreatedWithNotice?: boolean;
  headCode?: string;
}

export async function getProject({ projectId }) {
  if (!projectId) return null;
  const { data } = await AxiosAPI.get(`/projects/${projectId}`);
  return data;
}

export async function updateProject(
  project: Partial<Project>
): Promise<Project> {
  const { data } = await AxiosAPI.put(`/projects/${project._id}`, {
    project,
  });
  return data;
}

export async function deleteProject(projectId: string) {
  const { data } = await AxiosAPI.delete(`/projects/${projectId}`);
  return data;
}

export async function updateProjectLogo(
  projectId: string,
  formData: FormData
): Promise<Project> {
  const { data: url } = await AxiosAPI.post(
    `/projects/${projectId}/logo`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return url;
}
