import styled from "styled-components";
import { Dialog, DialogContent } from "../../Components/Dialog/Dialog";
import { Button } from "../../Components/Button";
import { Invitation } from "../../queries/collaborators/collaborators.call";
import { capitalize } from "../Tags/helpers";
import { useInvitations } from "../../queries/collaborators/useCollaborators";
import { useNavigateToWorkspace } from "../../hooks/useQueryParam";

export function InvitationModal({
  inviteModalOpen,
  setInviteModalOpen,
  invitation,
}: {
  inviteModalOpen: boolean;
  setInviteModalOpen: (value: boolean) => void;
  invitation: Invitation;
}) {
  const navigateToWorkspace = useNavigateToWorkspace();

  const { mutateAsync: answerInvitation } = useInvitations().mutation;
  if (!invitation) return null;
  const { workspace, sentBy, role } = invitation;

  const { name: workspaceName } = workspace;
  const { name: sentByName, email: sentByEmail } = sentBy;

  const handleAnswer = async (status: "accepted" | "declined") => {
    const { status: answer } = await answerInvitation({
      status,
      invitationId: invitation._id,
    });
    if (answer === "accepted") {
      navigateToWorkspace(workspace._id);
    }
    setInviteModalOpen(false);
  };

  return (
    <Dialog open={inviteModalOpen} onOpenChange={setInviteModalOpen}>
      <DialogContent style={{ width: "100%", alignContent: "center" }}>
        <Centered>
          <Section>
            <p
              style={{ maxWidth: 300, textAlign: "center", lineHeight: "30px" }}
            >
              {sentByName ?? sentByEmail} has invited you to join
              <Bold> {workspaceName}</Bold> workspace as{" "}
              <Bold>{capitalize(role)}</Bold>
            </p>
          </Section>
          <Buttons>
            <Button
              onClick={() => {
                handleAnswer("accepted");
              }}
            >
              Accept
            </Button>
            <Button
              onClick={() => {
                handleAnswer("declined");
              }}
            >
              Refuse
            </Button>
          </Buttons>
        </Centered>
      </DialogContent>
    </Dialog>
  );
}

const Bold = styled.span`
  font-weight: bold;
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Buttons = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 16px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;
