import styled from "styled-components";
import { useProject } from "../../queries/project/useProject";
import { TemplateSelector } from "../TemplatesList/TemplatesSelector";

export function TemplateTab() {
  const {
    query: { data: project },

    mutation: { mutate: updateProject },
  } = useProject();
  if (!project?.type) return null;

  const updateTemplate = (template: string) => {
    updateProject({ template });
  };

  return (
    <Wrapper>
      <TemplateSelector
        type={project?.type}
        setTemplate={(template) => {
          updateTemplate(template);
        }}
        selectedTemplate={project?.template}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
