// same as text
const borderColor = "#36454F";
const borderRadius = "3px";

export const legacyColors = {
  // Base
  // dark: "#343746",
  // white: "#ffffff",

  // // Shades of grey
  // greyLight: "#b6c3d1",
  // grey: "#99a8b5",
  // greyDark: "#6b7985",
  // lightGrey: "#F2F0EC",
  twilightGrey: "#777CA6",
  // twilightDarkGrey: "#575B7E",
  // backgroundGrey: "rgba(250, 239, 240, 0.78)",
  // backgroundLightGrey: "#F5F5F5",
  // backgroundLighterGrey: "#F8FBFD",
  // backgroundHoverGrey: "#EBEBEB",

  // Text
  // textDark: "#444756",
  // textLight: "#ffffff",
  // textGrey: "#6b7985",
  // textLightGrey: "#9ba9b5",
  // textLighterGrey: "#B5BCBF",
  // textLight50: "rgb(255, 255, 255, 0.5)",
  textRed: "#b44437",
  // textGreen: "#2FC193",

  // Primary
  // primary: "#3a83cf",
  primaryExtraLight: "#f0f3fb",
  primaryLighter: "hsl(210, 75%, 90%)",
  primaryLight: "#74b1ff",
  primaryDark: "#00569d",

  // Secondary
  secondary: "#484d61",

  // Borders
  // border: "#d2deeb",
  // borderLight: "#e3e8ed",
  // borderDark: "#2a2c38",

  // States
  // hover: "rgba(58, 131, 207, 0.1)", // primary at 10%
  // active: "rgba(58, 131, 207, 0.25)", // primary at 25%
  // hoverDark: "rgba(0, 0, 0, 0.1)",
  // activeDark: "rgba(0, 0, 0, 0.2)",

  // Toasts & Hints
  info: "#3498db",
  success: "#07bc0c",
  warning: "#f1c40f",
  error: "#e74c3c",

  // Blocks (Temporary)
  yellorange: "#ffb849",
  mariner: "#3a83cf",
  spray: "#81f3dc",
  sweetpink: "#fd9faf",
  sweetpurple: "#b49aff",

  // Buttons
  pink: "#FA557D",
};

export const legacyColorsEditor = {
  // Base
  dark: "#343746",
  white: "#ffffff",

  // Shades of grey
  greyLight: "#b6c3d1",
  grey: "#99a8b5",
  greyDark: "#6b7985",
  lightGrey: "#F2F0EC",
  twilightGrey: "#777CA6",
  twilightDarkGrey: "#575B7E",
  backgroundGrey: "rgba(250, 239, 240, 0.78)",
  backgroundLightGrey: "#F5F5F5",
  backgroundLighterGrey: "#F8FBFD",
  backgroundHoverGrey: "#EBEBEB",

  // Text
  textDark: "#444756",
  textGrey: "#6b7985",
  textLightGrey: "#9ba9b5",
  textLighterGrey: "#B5BCBF",
  textLight50: "rgb(255, 255, 255, 0.5)",
  textRed: "#b44437",
  textGreen: "#2FC193",

  // Toastify
  toastifyWarning: "#f1c40f",

  // Primary
  // primary: "#3a83cf",
  primaryExtraLight: "#f0f3fb",
  primaryLighter: "hsl(210, 75%, 90%)",
  primaryLight: "#74b1ff",
  primaryDark: "#00569d",

  // // Secondary
  // secondary: "#484d61",

  // // Borders
  // border: "#d2deeb",
  // borderLight: "#e3e8ed",
  // borderDark: "#2a2c38",

  // States
  // hover: "rgba(58, 131, 207, 0.1)", // primary at 10%
  // active: "rgba(58, 131, 207, 0.25)", // primary at 25%
  // hoverDark: "rgba(0, 0, 0, 0.1)",
  // activeDark: "rgba(0, 0, 0, 0.2)",

  // Toasts & Hints
  info: "#3498db",
  success: "#07bc0c",
  warning: "#f1c40f",
  error: "#e74c3c",

  // Blocks (Temporary)
  yellorange: "#ffb849",
  mariner: "#3a83cf",
  spray: "#81f3dc",
  sweetpink: "#fd9faf",
  sweetpurple: "#b49aff",

  // Buttons
  pink: "#FA557D",
};

export const theme = {
  ...legacyColors,
  ...legacyColorsEditor,

  primary: "black",
  secondary: "#3A85D0",
  disabled: "#000000",

  // pastel colors
  pastelBlue: "#AED9E0",
  pastelPink: "#F4B3B3",
  pastelGreen: "#B3F4C6",
  pastelYellow: "#F4EAB3",
  pastelOrange: "#F4D1B3",
  pastelPurple: "#D1B3F4",

  // a dark blue color for text
  text: "#36454F",

  // a lighter grey color for text
  lightText: "#7F8C8D",
  lightBg: "#edf2fa",

  borderRadius,
  borderColor,
  // no shit
  white: "white",

  shadow:
    "rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px, rgba(0, 0, 0, 0.04) 0px 8px 14px, rgba(0, 0, 0, 0.02) 0px 12px 16px",

  //LegacyColors:
  lightGrey: "#F2F0EC",
};
