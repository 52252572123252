import styled from "styled-components";
import {
  Dialog,
  DialogContent,
  DialogHeading,
} from "../../Components/Dialog/Dialog";
import { useUser } from "../../queries/user/useUser";
import { DropZoneArea } from "../DropZoneArea/DropZoneArea";
import { useUploadPP } from "../../queries/file/useFiles";
import { NoticeToolTip } from "../../Components/ToolTip/ToolTip";
import { ProfilePlaceholderIcon } from "../../icons/profile-placeholder.icon";
import { TextInput } from "../../Components/Input/InputText";
import { useCallback } from "react";
import { debounce } from "lodash";

interface Props {
  profileOpen: boolean;
  setProfileOpen: (value: boolean) => void;
}
export function ProfileModal({ profileOpen, setProfileOpen }: Props) {
  const {
    mutation: { mutate: updateUser },
    query: { data },
  } = useUser();
  const updatePP = useUploadPP().mutation;
  const { name = "", email = "", picture = "" } = data || {};

  const handleNameChange = useCallback(
    debounce((e) => {
      updateUser({ name: e.target.value });
    }, 400),
    []
  );

  return (
    <Dialog open={profileOpen} onOpenChange={setProfileOpen}>
      <DialogContent style={{ textAlign: "center" }}>
        <DialogHeading>Profile</DialogHeading>

        <Section>
          <h3>Name</h3>
          <TextInput
            placeholder="Username"
            defaultValue={name}
            onChange={handleNameChange}
          />
        </Section>

        <Section>
          <h3>Email</h3>
          <span>{email}</span>
        </Section>
        <h3>Picture</h3>
        <NoticeToolTip anchorSelect=".my-anchor-element">
          Upload a square image for best results.
        </NoticeToolTip>
        <div className="my-anchor-element">
          <ProfilePic>
            <DropZoneArea
              type={"image"}
              acceptedTypes={["image/png", "image/jpeg"]}
              mutation={updatePP}
            >
              {picture ? (
                <img
                  src={picture}
                  alt="profile"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <ProfilePlaceholder />
              )}
            </DropZoneArea>
          </ProfilePic>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const ProfilePic = styled.div`
  img {
    border-radius: 50%;
    width: 100px;
    margin-top: 8px;
    transition: border 0.3s;
    height: 100px;
    object-fit: cover;
  }

  display: flex;
  justify-content: center;
  &:hover {
    opacity: 0.8;
  }
  transition: opacity 0.3s ease-in-out;
`;

export const ProfilePlaceholder = ({ size = 50 }) => {
  return (
    <WrapperProfilePlaceholder size={size}>
      <ProfilePlaceholderIcon size={size} />
    </WrapperProfilePlaceholder>
  );
};

const WrapperProfilePlaceholder = styled.div<{ size: number }>`
  width: ${({ size }) => size + 50}px;
  height: ${({ size }) => size + 50}px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;
