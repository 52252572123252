import styled, { css } from "styled-components";
import { theme } from "../../style/theme";
import { Portal } from "../../Components/Portal";
import { FlipBackward } from "../../icons/FlipBackward.icon";
import { FlipForward } from "../../icons/FlipForward.icon";
import { PenTool } from "../../icons/PenTool.icon";
import { TypeIconTitle } from "../../icons/FileCheck.icon copy";
import { NoticeToolTip } from "../../Components/ToolTip/ToolTip";
import { Editor } from "@tiptap/react";
import { AIIcon } from "../../icons/AIIcon";
import {
  useAIContent,
  useNoStreamGenerateContent,
} from "../../hooks/ai/useAiGeneratePage";
import { extendCurrentSelection, findTitle } from "../../hooks/ai/chatMessages";
import { MagicWandIcon } from "../../icons/MagicWand.icon";
import { AIPrompt } from "./AIPrompt/AIPrompt";
import { useEffect, useRef, useState } from "react";
import { useQueryParam } from "../../hooks/useQueryParam";
import { CrossIcon } from "../../icons/CrossIcon";
import { Kdb } from "../../Components/Kbd/Kbd.component";
import {
  FloatingArrow,
  arrow,
  useFloating,
  useTransitionStyles,
  offset,
} from "@floating-ui/react";
import { StopCircle } from "../../icons/StopCircle.icon";
import { ImageIcon } from "../../icons/Image.icon";
import { ImageAIPrompt } from "./ImageAIPrompt/ImageAIPrompt";

export function TopScreenToolBar({ editor }: { editor: Editor }) {
  const [getQueryParam] = useQueryParam();
  const currentTab = getQueryParam("tab");
  if (currentTab === "settings") return <CloseTab />;
  if (currentTab === "translate") return <></>;
  return <AIToolBar editor={editor} />;
}

function CloseTab() {
  const [show, setShow] = useState(false);
  const [, setQueryParam] = useQueryParam();

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Portal>
      <Wrapper $show={show}>
        <CloseTabStyled
          onClick={() => {
            setQueryParam("tab", "");
          }}
        >
          <CrossIcon size={10} />
          Close tab
        </CloseTabStyled>
      </Wrapper>
    </Portal>
  );
}

const CloseTabStyled = styled.div`
  font-size: 14px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.lightText};
  cursor: pointer;
  transition: color 0.3s ease;
  transition: stroke 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.text};
    line {
      stroke: ${({ theme }) => theme.text};
    }
  }
`;

function removeQuotes(str) {
  return str.replace(/['"]/g, "");
}

export function getModifierKey() {
  if (!navigator?.userAgent) return "Ctrl";

  const userAgent = navigator.userAgent.toLowerCase();
  const isMac = userAgent.includes("mac");
  return isMac ? "Cmd" : "Ctrl";
}

export function AIToolBar({ editor }: { editor: Editor }) {
  const [show, setShow] = useState(false);
  const [generateContent] = useAIContent();
  const abortRef = useRef(null);
  const [generateNoStream] = useNoStreamGenerateContent();
  const [isExtending, setIsExtending] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);
  const childCount = editor.state.doc.childCount;

  useEffect(() => {
    if (
      editor.storage.characterCount.characters() < 30 &&
      editor.state.doc.childCount < 3
    ) {
      setTimeout(() => setShowTooltip(true), 2000);
    } else {
      setShowTooltip(false);
    }
  }, [childCount]);
  // const showHelpToolTip =
  //   editor.storage.characterCount.characters() < 30 &&
  //   editor.state.doc.childCount < 3;
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: showTooltip,
    strategy: "absolute",
    middleware: [
      offset({
        // crossAxis: -40,
        mainAxis: 18,
      }),
      arrow({
        element: arrowRef,
      }),
    ],
    placement: "bottom",
  });

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: "scale(0.8)",
    },
  });

  useEffect(() => {
    setTimeout(() => setShow(true), 700);
  }, []);

  if (!editor) return null;
  const ACTIONS = [
    {
      id: "prompt",
      title: (
        <TitleAction>
          <Title>
            <AIIcon size={18} color={theme.pastelPurple} />
            Prompt
          </Title>
          <Subtitle>Ask the AI to generate any content.</Subtitle>
        </TitleAction>
      ),

      icon: (
        <AIPrompt
          insertMarkdown={(html) => {
            editor.view.focus();
            editor.commands.splitBlock();

            editor.commands.insertContent(html, {
              parseOptions: {
                preserveWhitespace: false,
              },
            });
          }}
        >
          <div
            style={{ position: "relative", display: "flex" }}
            ref={refs.setReference}
          >
            <MagicWandIcon size={18} color={theme.text} />
            {isMounted && (
              <div
                ref={refs.setFloating}
                style={{ ...floatingStyles, pointerEvents: "none" }}
              >
                <div style={{ ...styles, pointerEvents: "none" }}>
                  <StyledArrow ref={arrowRef} context={context} />

                  <HelpToolTip />
                </div>
              </div>
            )}
          </div>
        </AIPrompt>
      ),
      onClick: async () => {},
    },
    {
      id: "imagePrompt",
      title: (
        <TitleAction>
          <Title>
            <AIIcon size={18} color={theme.pastelPurple} />
            Image Generation
          </Title>
          <Subtitle>Ask the AI to generate an image.</Subtitle>
        </TitleAction>
      ),

      icon: (
        <ImageAIPrompt
          insertImage={(url) => {
            editor?.chain().focus().setImage({ src: url }).run();
          }}
          editor={editor}
        >
          <ImageIcon size={18} color={theme.text} />
        </ImageAIPrompt>
      ),
      onClick: async () => {},
    },
    {
      id: "generateTitle",
      title: (
        <TitleAction>
          <Title>
            <AIIcon size={18} color={theme.pastelPurple} />
            Find title
          </Title>
          <Subtitle>Create a title based on the selected text.</Subtitle>
        </TitleAction>
      ),
      icon: <TypeIconTitle size={16} color={theme.text} />,
      onClick: async () => {
        if (editor.state.selection.empty) return;
        const { state } = editor;
        const { doc, selection } = state;
        const { from, to } = selection;
        let text = "";
        text = doc.textBetween(from, to, "\n");

        await generateNoStream({
          chatMessages: findTitle(text),
          setResponse: (val) => {
            editor.commands.insertContentAt(
              from,
              `<h2>${removeQuotes(val)}</h2>`
            );
          },
          onStreamComplete: () => {},
        });
      },
      disabled: editor.state.selection.empty,
    },
    {
      id: "extendContent",
      title: isExtending ? (
        "Stop generation"
      ) : (
        <TitleAction>
          <Title>
            <AIIcon size={18} color={theme.pastelPurple} />
            Extend content
          </Title>
          <Subtitle>Continue the text after your cursor.</Subtitle>
        </TitleAction>
      ),
      icon: isExtending ? (
        <StopCircle size={18} color={theme.textRed} />
      ) : (
        <PenTool size={18} color={theme.text} />
      ),
      onClick: async () => {
        if (isExtending) {
          abortRef.current.abort();
          setIsExtending(false);
          return;
        }
        setIsExtending(true);
        const { state } = editor;
        const { doc, selection } = state;
        const { from } = selection;

        const start = from - 500 > 0 ? from - 1300 : 0;

        const textBeforeCaret = doc.textBetween(start, from, "\n");

        // get the first heading (the title)
        const $h1 = editor.$node("heading", { level: 1 });

        editor.view.focus();

        const abortController = await generateContent({
          chatMessages: extendCurrentSelection(textBeforeCaret, {
            title: $h1.textContent,
          }),
          setResponse: (val) => {
            const paragraphs = val.split("\n").filter((p) => p.length > 0);

            paragraphs.forEach((paragraph, index) => {
              editor.commands.insertContent(paragraph, {
                parseOptions: {
                  preserveWhitespace: false,
                },
              });
              if (index !== paragraphs.length - 1) editor.commands.splitBlock();
            });
          },
          onStreamComplete: () => setIsExtending(false),
        });
        abortRef.current = abortController;
      },
      disabled: !editor.view.hasFocus(),
    },

    {
      id: "undo",
      title: (
        <TitleAction>
          <Title>
            Undo change <Kdb>{getModifierKey()} + z</Kdb>
          </Title>
        </TitleAction>
      ),
      icon: <FlipBackward size={18} color={theme.text} />,
      onClick: () => {
        editor.commands.undo();
      },
    },

    {
      id: "redo",
      title: (
        <TitleAction>
          <Title>
            Redo change <Kdb>{getModifierKey()} + y</Kdb>
          </Title>
        </TitleAction>
      ),
      icon: <FlipForward size={18} color={theme.text} />,
      onClick: () => {
        editor.commands.redo();
      },
    },
  ];

  return (
    <Portal>
      <Wrapper $show={show}>
        {ACTIONS.map((action) => {
          return <Action action={action} key={action.id} />;
        })}
      </Wrapper>
    </Portal>
  );
}

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Subtitle = styled.div`
  font-size: 10px;
  border-top: 1px solid #eee;
  padding-top: 4px;
  font-style: italic;
`;

const TitleAction = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const Wrapper = styled.div<{ $isTop?: boolean; $show?: boolean }>`
  position: fixed;
  top: 0px;
  box-shadow: ${({ theme }) => theme.shadow};
  background-color: white;

  transition: background-color 0.3s ease;
  border-radius: 0px 0px 8px 8px;
  /* padding: 8px; */
  z-index: 9;
  display: flex;
  height: 32.5px;
  align-items: center;
  transition: opacity 1s ease;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  z-index: 0;
`;

function Action({ action }: { action: any }) {
  const tooltipName = `${action.id}-tooltip`;

  const { onClick, disabled } = action;
  return (
    <StyledAction
      className={tooltipName}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      $disabled={disabled}
    >
      <NoticeToolTip anchorSelect={`.${tooltipName}`} delayShow={200}>
        {action.title}
      </NoticeToolTip>
      <IconContainer $disabled={disabled}>{action.icon}</IconContainer>
    </StyledAction>
  );
}

const StyledAction = styled.div<{
  $disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  width: 33px;
  height: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme, $disabled }) =>
      $disabled ? "none" : theme.lightBg};
  }

  // apply border-radius to the first child
  &:first-child {
    /* padding-left: 8px; */
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    /* padding-right: 8px; */

    border-bottom-right-radius: 4px;
  }

  border: none;
`;

const IconContainer = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  svg {
    path {
      ${({ theme, $disabled }) =>
        $disabled &&
        css`
          stroke: ${theme.lightText};
        `}
    }
  }
`;

const HelpToolTip = () => {
  return (
    <HelpWrapper>
      <i>Need inspiration? Get started with our AI.</i>
    </HelpWrapper>
  );
};

const HelpWrapper = styled.div`
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadow};
  padding: 8px;
  border-radius: 8px;
  width: 200px;
  color: ${({ theme }) => theme.text};
  pointer-events: none;
  text-align: center;
  font-size: 15px;
`;

const StyledArrow = styled(FloatingArrow)`
  fill: white;
  stroke-width: 0 !important;
`;
