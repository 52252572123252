import { IconProps } from ".";

export const ImageBlockIcon = ({ size = 30 }: IconProps) => {
  return (
    <svg
      style={{ width: size, height: size }}
      width="264"
      height="220"
      viewBox="0 0 264 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M115.471 85.2288C115.471 80.665 119.155 76.9809 123.719 76.9809C128.283 76.9809 131.967 80.665 131.967 85.2288C131.967 89.7927 128.283 93.4768 123.719 93.4768C119.155 93.4768 115.471 89.7927 115.471 85.2288ZM197.951 82.4795V115.471C197.951 130.648 185.634 142.965 170.458 142.965H120.97C105.794 142.965 93.4768 130.648 93.4768 115.471V82.4795C93.4768 67.3033 105.794 54.9863 120.97 54.9863H170.458C185.634 54.9863 197.951 67.3033 197.951 82.4795ZM104.474 115.471C104.474 119.705 106.069 123.554 108.708 126.524L137.521 97.7108C142.91 92.3221 152.312 92.3221 157.701 97.7108L163.42 103.429C164.684 104.694 166.829 104.694 168.093 103.429L186.954 84.569V82.4795C186.954 73.4068 179.531 65.9836 170.458 65.9836H120.97C111.897 65.9836 104.474 73.4068 104.474 82.4795V115.471ZM186.954 115.471V100.13L175.846 111.237C170.458 116.626 161.055 116.626 155.666 111.237L149.948 105.519C148.683 104.254 146.594 104.254 145.274 105.519L118.936 131.857C119.595 131.967 120.255 131.967 120.97 131.967H170.458C179.531 131.967 186.954 124.544 186.954 115.471ZM159.46 153.962H93.4768C84.4041 153.962 76.9809 146.539 76.9809 137.466V104.474C76.9809 100.405 78.4656 96.501 81.2149 93.4768C83.2494 91.2224 83.0294 87.7582 80.775 85.7237C78.5205 83.6892 75.0564 83.9092 73.0219 86.1636C68.513 91.2224 65.9836 97.7108 65.9836 104.529V137.521C65.9836 152.697 78.3006 165.014 93.4768 165.014H159.46C162.485 165.014 164.959 162.54 164.959 159.515C164.959 156.491 162.485 154.017 159.46 154.017V153.962Z"
        fill="#3A85D0"
      />
    </svg>
  );
};
