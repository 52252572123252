import { IconProps } from ".";

export const NumberedListIcon = ({ size = 30 }: IconProps) => {
  return (
    <svg
      style={{ width: size, height: size }}
      width="264"
      height="220"
      viewBox="0 0 111 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.4906 24.442C30.4906 23.0124 31.7455 21.8545 33.0781 21.8545H36.5281C38.059 21.8545 39.1156 23.0124 39.1156 24.442V37.3752H40.8406C42.3715 37.3752 43.4281 38.5396 43.4281 39.9627C43.4281 41.3966 42.3715 42.5502 40.8406 42.5502H32.2156C30.883 42.5502 29.6281 41.3966 29.6281 39.9627C29.6281 38.5396 30.883 37.3752 32.2156 37.3752H33.9406V27.0295H33.0781C31.7455 27.0295 30.4906 25.8705 30.4906 24.442ZM37.3474 55.1858C36.6359 54.388 35.3745 54.4419 34.7545 55.3152L33.5557 56.9863C32.7255 58.1507 31.1094 58.4202 29.9461 57.59C28.7828 56.7599 28.5144 55.1427 29.3445 53.9891L30.5434 52.3072C33.1029 48.7171 38.3393 48.4583 41.2395 51.7789C43.5359 54.3125 43.482 58.3447 41.1209 60.9107L37.3582 64.9752H40.9376C42.3715 64.9752 43.5251 66.1288 43.5251 67.5627C43.5251 68.9966 42.3715 70.1502 40.9376 70.1502H31.4501C30.4226 70.1502 29.4922 69.5464 29.0804 68.5977C28.6675 67.6597 28.8518 66.56 29.5483 65.8053L37.3151 57.396C37.8865 56.7707 37.8973 55.8219 37.3474 55.1858ZM80.6126 26.1627C82.0465 26.1627 83.2001 27.3217 83.2001 28.7502C83.2001 30.1841 82.0465 31.3377 80.6126 31.3377H51.2876C49.8537 31.3377 48.7001 30.1841 48.7001 28.7502C48.7001 27.3217 49.8537 26.1627 51.2876 26.1627H80.6126ZM80.6126 43.4127C82.0465 43.4127 83.2001 44.5663 83.2001 46.0002C83.2001 47.4341 82.0465 48.5877 80.6126 48.5877H51.2876C49.8537 48.5877 48.7001 47.4341 48.7001 46.0002C48.7001 44.5663 49.8537 43.4127 51.2876 43.4127H80.6126ZM80.6126 60.6627C82.0465 60.6627 83.2001 61.8163 83.2001 63.2502C83.2001 64.6841 82.0465 65.8377 80.6126 65.8377H51.2876C49.8537 65.8377 48.7001 64.6841 48.7001 63.2502C48.7001 61.8163 49.8537 60.6627 51.2876 60.6627H80.6126Z"
        fill="#3A85D0"
      />
    </svg>
  );
};
