import styled from "styled-components";
import { PopoverContent } from "../../Components/Popover/Popover";
import { CopyIcon } from "../../icons/CopyIcon";
import { TrashIcon } from "../../icons/TrashIcon";
import { theme } from "../../style/theme";
import {
  useDeletePage,
  useDuplicatePage,
  usePage,
} from "../../queries/page/usePages";
import { EyeIcon } from "../../icons/Eye.icon";
import { NoticeToolTip } from "../../Components/ToolTip/ToolTip";
import { useProjectType } from "../../hooks/useProjectType";
import { EyeOffIcon } from "../../icons/EyeOffIcon";

export function PageOptionsPopOver({ open, setOpen, page }) {
  const { mutation: deletePage } = useDeletePage();
  const { mutation: duplicatePage } = useDuplicatePage();
  const { mutation: updatePage } = usePage();
  const { hidden } = page;
  const projectType = useProjectType();

  const Actions = [
    {
      icon: CopyIcon,
      text: "Duplicate",
      onClick: (e) => {
        e.stopPropagation();
        duplicatePage.mutate(page._id);
        setOpen(false);
      },
    },
    {
      icon: TrashIcon,
      text: "Delete",
      onClick: (e) => {
        e.stopPropagation();
        deletePage.mutate(page._id);
        setOpen(false);
      },
    },
    {
      icon: hidden ? EyeIcon : EyeOffIcon,
      text: hidden ? "Show" : "Hide",
      onClick: (e) => {
        e.stopPropagation();

        const newVal = hidden === true ? false : true;

        updatePage.mutate({ _id: page._id, hidden: newVal });
      },
      className: "page-hidden-tooltip",
    },
  ];
  return (
    <PopoverContent
      className="Popover"
      style={{
        padding: 0,
      }}
    >
      <NoticeToolTip anchorSelect=".page-hidden-tooltip" delayShow={300}>
        Hidden {projectType.pageName}s will not be visible on the published
        website
      </NoticeToolTip>
      {/* <ShareAndExportDialog>
        <ActionContainer>
          <Text>Share</Text>
          <ShareIcon size={18} color={theme.text} />
        </ActionContainer>
      </ShareAndExportDialog> */}

      {Actions.map((action, index) => (
        <ActionContainer
          key={index}
          onClick={action.onClick}
          className={action?.className ?? ""}
        >
          <Text>{action.text}</Text>
          <action.icon size={18} color={theme.text} />
        </ActionContainer>
      ))}
    </PopoverContent>
  );
}

const Text = styled.span``;

const ActionContainer = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130px;
  height: 35px;
  cursor: pointer;
  font-weight: 500;
  color: ${({ theme }) => theme.text};

  /* apply border radius to first child */
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  /* apply border radius to last child */
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;
