import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../Components/Dialog/Dialog";
import { TextInput } from "../../Components/Input/InputText";
import { useProjects } from "../../queries/projects/useProjects";
import { ProjectTypes } from "../../queries/project/project.calls";
import { PROJECT_TYPES_MAP } from "../../hooks/useProjectType";
import { Stepper } from "../ProjectPage/CustomDomainFlow/Stepper";
import { ProjectTypeSelector } from "./ProjectType";
import { TemplateSelector } from "./TemplatesSelector";
import { useAutoAnimate } from "@formkit/auto-animate/react";

export function CreateANewProject() {
  const [step, setStep] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [projectType, setProjectType] = useState("");
  const [template, setTemplate] = useState("");
  const [listRef] = useAutoAnimate(/* optional config */);

  const { workspaceId } = useParams();

  const { mutate } = useProjects(workspaceId).mutation;

  const createProject = async () => {
    if (!name || !projectType) return;
    mutate({
      name,
      type: projectType as ProjectTypes,
      template,
      _id: crypto.randomUUID(),
    });
    setName("");
    setProjectType("");
    setStep(1);
    setModalOpen(false);
  };

  useEffect(() => {
    if (modalOpen === false) {
      setName("");
      setProjectType("");
      setStep(1);
    }
  }, [modalOpen]);

  const getTitle = () => {
    switch (step) {
      case 1:
        return "What kind of project do you want to create?";
      case 2:
        return "Let's pick a template";
      case 3:
        return "Name your project";
      default:
        return "";
    }
  };

  return (
    <Dialog open={modalOpen} onOpenChange={setModalOpen}>
      <DialogContent>
        <Stepper
          steps={[
            { label: "Project Type", step: 1 },
            { label: "Template", step: 2 },
            { label: "Name", step: 3 },
          ]}
          activeStep={step}
          setStep={(s) => setStep(s)}
        />

        <Wrapper ref={listRef}>
          <StepTitle>{getTitle()}</StepTitle>
          {step === 1 && (
            <ProjectTypeSelector
              setProjectType={(projectTypeName) => {
                setProjectType(projectTypeName);
                setStep(2);
              }}
            />
          )}
          {step === 2 && (
            <TemplateSelector
              type={projectType}
              setTemplate={(templateName) => {
                setTemplate(templateName);
                setStep(3);
              }}
            />
          )}
          {step === 3 && (
            <ProjectNameWrapper
              name={name}
              setName={setName}
              projectType={projectType}
              createProject={createProject}
            />
          )}
          {name && <Kbd onClick={() => createProject()}>Create</Kbd>}
        </Wrapper>
      </DialogContent>

      <DialogTrigger asChild={true} onClick={() => setModalOpen(true)}>
        <CreateWrapper>
          <ProjectTitleContainer>
            <ProjectTitle style={{ margin: 0 }}>New project</ProjectTitle>
          </ProjectTitleContainer>
        </CreateWrapper>
      </DialogTrigger>
    </Dialog>
  );
}

const StepTitle = styled.h2`
  text-align: center;
  font-size: 28px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const Wrapper = styled.div`
  width: 600px;
  min-height: 400px;
  margin-top: 64px;
`;

const Kbd = styled.kbd`
  padding: 8px 16px;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${(props) => props.theme.lightText};
  cursor: pointer;
  max-width: 284px;
  margin: auto;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 16px;
  color: ${({ theme }) => theme.text};
  margin-top: 32px;
  &:hover {
    background-color: ${(props) => props.theme.pastelYellow};
  }
  background-color: transparent;
`;

const CreateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 170px;
  height: 200px;
  cursor: pointer;
  position: relative;
  font-size: 15px;

  border-radius: 8px;
  transition: top 0.2s ease-in-out;
  top: 0px;

  backdrop-filter: blur(16px);
  background-color: rgb(0 0 0 / 2.5%);

  border: 1.5px dashed ${(props) => props.theme.pastelBlue} !important;
  &:hover {
    background-color: ${(props) => props.theme.pastelYellow};
  }
  transition: background-color 0.2s ease-in-out;
`;

const ProjectTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ProjectTitle = styled.h2`
  margin: 0;
  padding: 24px;
  text-align: center;
  max-width: 100%;
  z-index: 20;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-top: 0px;
  font-size: 16px;
  font-weight: 400 !important;
`;

const ProjectNameWrapper = ({ name, setName, projectType, createProject }) => {
  const InputRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      InputRef.current.focus();
    }, 300);
  }, []);

  return (
    <NameWrapper>
      <TextInput
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder={`My ${PROJECT_TYPES_MAP[projectType]?.text}`}
        ref={InputRef}
        spellCheck={false}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            createProject();
          }
        }}
        style={{
          width: "300px",
          marginTop: 32,
          textAlign: "center",
          fontSize: 21,
        }}
      />
    </NameWrapper>
  );
};

const NameWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
