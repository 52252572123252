import styled from "styled-components";
import { PageCard } from "./PageCard";
import { usePages } from "../../queries/page/usePages";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTrigger,
} from "../../Components/Dialog/Dialog";
import { TextInput } from "../../Components/Input/InputText";
import { useState } from "react";
import { useProject } from "../../queries/project/useProject";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { PROJECT_TYPES_MAP, useProjectType } from "../../hooks/useProjectType";

export function PagesList() {
  const { data, isLoading } = usePages().query;
  const [listRef] = useAutoAnimate(/* optional config */);
  const { type } = useProjectType();

  if (isLoading) return <></>;
  const pages = data;

  if (!pages) return null;
  const hideCreate = pages.length > 0 && type === "terms-of-use";

  return (
    <Container>
      <Wrapper ref={listRef}>
        {!hideCreate && <CreatePage />}
        {pages.map((page) => (
          <PageCard key={page._id} page={page} />
        ))}
      </Wrapper>
    </Container>
  );
}

const Container = styled.div``;

const Wrapper = styled.div`
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

function CreatePage() {
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const { mutate } = usePages().mutation;
  const { type } = useProject().query.data;

  const createPage = async () => {
    mutate({ name, _id: crypto.randomUUID(), noticeType: type ?? "blog" });
    setName("");

    setModalOpen(false);
  };

  const { pageName } = PROJECT_TYPES_MAP[type] ?? PROJECT_TYPES_MAP["blog"];
  return (
    <Dialog open={modalOpen} onOpenChange={setModalOpen}>
      <DialogContent>
        <DialogDescription>
          Type the name of your new {pageName}
        </DialogDescription>
        <InputContainer
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              createPage();
            }
          }}
          spellCheck={false}
        >
          <TextInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={`My ${pageName}`}
          />
          <Kbd onClick={() => createPage()}>Enter</Kbd>
        </InputContainer>
      </DialogContent>

      <DialogTrigger
        asChild={true}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <CreateWrapper>
          <NameContainer>
            <Name>New {pageName}</Name>
          </NameContainer>
        </CreateWrapper>
      </DialogTrigger>
    </Dialog>
  );
}

const Kbd = styled.kbd`
  padding: 8px 16px;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${(props) => props.theme.lightText};
  cursor: pointer;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 16px;
  color: ${({ theme }) => theme.text};
  &:hover {
    background-color: ${(props) => props.theme.pastelYellow};
  }
  background-color: transparent;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 40px;
`;

const CreateWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;

  border-radius: 14px;

  top: 0px;
  position: relative;
  width: 170px;
  height: 200px;

  backdrop-filter: blur(16px);
  background-color: rgb(0 0 0 / 2.5%);

  transition: background-color 0.2s ease-in-out,
    backdrop-filter 0.2s ease-in-out, border 0.2s ease-in-out;
  border: 1px dashed ${(props) => props.theme.pastelBlue} !important;

  border: 1.5px dashed ${(props) => props.theme.pastelBlue};
  background-color: transparent;

  &:hover {
    top: 0px !important;
    background-color: ${(props) => props.theme.pastelYellow};
  }
`;

const Name = styled.p`
  text-align: center;
  padding: 16px;
`;

const NameContainer = styled.div`
  height: 39%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
