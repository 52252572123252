import styled from "styled-components";
import { usePage } from "../../queries/page/usePages";
import { Category } from "../Category";
import { Tags } from "../Tags";
import {
  DatePublished,
  PageAuthor,
  PageDescription,
  PageSlug,
} from "./MetadataComponents";

export function PageSettings() {
  const {
    query: { data: page, isLoading },
  } = usePage();

  if (isLoading) return <></>;
  return (
    <Wrapper>
      <Section>
        <h3>Publication date</h3>

        <DatePublished />
      </Section>
      <Section>
        <h3>URL</h3>
        <PageSlug />
      </Section>
      <Section>
        <Category />
      </Section>
      <Section>
        <h3>Tags</h3>
        <Tags />
      </Section>

      <Section style={{ width: "100%" }}>
        <h3>Summary</h3>
        <PageDescription />
      </Section>
      <Section style={{ width: "100%" }}>
        <h3>Author</h3>
        <PageAuthor />
      </Section>
    </Wrapper>
  );
}

const Section = styled.div``;

const Wrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* width: 600px; */
  justify-content: space-between;
  min-height: 300px;

  * {
    font-size: 13px;
  }
`;
