import { PostHogProvider } from "posthog-js/react";
const REACT_APP_PUBLIC_POSTHOG_KEY =
  "phc_7LYLq6AQlWUaIs8wn8iyYv1TNfAeoaWNACuccmf4DZQ";
const REACT_APP_PUBLIC_POSTHOG_HOST = "https://api-v2.notice.studio/puddle";
// const REACT_APP_PUBLIC_POSTHOG_HOST = "http://localhost:3014/puddle";

const options = {
  api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
};

export function PostHogNotice({ children }) {
  // if (process.env.NODE_ENV !== "production") return children;
  return (
    <PostHogProvider apiKey={REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
      {children}
    </PostHogProvider>
  );
}
