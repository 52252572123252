import posthog from "posthog-js";
import { AxiosAPI } from "../api";

export interface User {
  _id: string;
  name?: string;
  email: string;
  picture?: string;
}

export async function getUser(): Promise<User> {
  const { data } = await AxiosAPI.get(`/user`);

  if (data && process.env.NODE_ENV === "production") {
    posthog.identify(data.email, {
      email: data.email,
      name: data.name,
      id: data._id,
    });
  }

  return data;
}

export async function updateUser(user: Partial<User>): Promise<User> {
  const { data } = await AxiosAPI.put(`/user`, {
    ...user,
  });
  return data;
}
