import styled from "styled-components";
import { CrossIcon } from "../../icons/CrossIcon";
import { StarIcon } from "../../icons/star.icon";
import { assignColor } from "./helpers";

interface TagProps {
  name: string;
  removeTag: (tag: string) => void;
  updateCategories: (tag: string) => void;
  category?: string;
}

export function Tag({ name, removeTag, category, updateCategories }: TagProps) {
  const color = assignColor(name);
  const isActive = category === name;

  return (
    <StyledTag style={{ backgroundColor: color }}>
      <StartIconWrapper onClick={() => updateCategories(name)}>
        <StarIcon
          color={isActive ? "#FFD700" : "white"}
          fill={isActive ? "#FFD700	" : "white"}
          size={12}
        />
      </StartIconWrapper>
      <NameWrapper>{name}</NameWrapper>
      <CrossIconWrapper color={color} onClick={() => removeTag(name)}>
        <CrossIcon color='white' size={8} />
      </CrossIconWrapper>
    </StyledTag>
  );
}

const CrossIconWrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  padding: 4px 8px 4px 8px;
  border-left: 1px solid rgb(255, 255, 255, 0.2);
  height: 100%;
  &:hover {
    background-color: rgb(255, 255, 255, 0.2);
  }
`;

const StartIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;

  cursor: pointer;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px 4px 2px;
`;

const StyledTag = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.primary};
  color: white;
  border-radius: 4px;
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
