import { IconProps } from ".";

export const BulletedListIcon = ({ size = 30 }: IconProps) => {
  return (
    <svg
      style={{ width: size, height: size }}
      width="264"
      height="220"
      viewBox="0 0 264 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18_3435)">
        <path
          d="M104.5 77H192.5C193.959 77 195.358 76.4205 196.389 75.3891C197.42 74.3576 198 72.9587 198 71.5C198 70.0413 197.42 68.6424 196.389 67.6109C195.358 66.5795 193.959 66 192.5 66H104.5C103.041 66 101.642 66.5795 100.611 67.6109C99.5795 68.6424 99 70.0413 99 71.5C99 72.9587 99.5795 74.3576 100.611 75.3891C101.642 76.4205 103.041 77 104.5 77Z"
          fill="#3A85D0"
        />
        <path
          d="M192.5 104.5H104.5C103.041 104.5 101.642 105.079 100.611 106.111C99.5795 107.142 99 108.541 99 110C99 111.459 99.5795 112.858 100.611 113.889C101.642 114.921 103.041 115.5 104.5 115.5H192.5C193.959 115.5 195.358 114.921 196.389 113.889C197.42 112.858 198 111.459 198 110C198 108.541 197.42 107.142 196.389 106.111C195.358 105.079 193.959 104.5 192.5 104.5Z"
          fill="#3A85D0"
        />
        <path
          d="M192.5 143H104.5C103.041 143 101.642 143.579 100.611 144.611C99.5795 145.642 99 147.041 99 148.5C99 149.959 99.5795 151.358 100.611 152.389C101.642 153.421 103.041 154 104.5 154H192.5C193.959 154 195.358 153.421 196.389 152.389C197.42 151.358 198 149.959 198 148.5C198 147.041 197.42 145.642 196.389 144.611C195.358 143.579 193.959 143 192.5 143Z"
          fill="#3A85D0"
        />
        <path
          d="M77 82.5C83.0751 82.5 88 77.5751 88 71.5C88 65.4249 83.0751 60.5 77 60.5C70.9249 60.5 66 65.4249 66 71.5C66 77.5751 70.9249 82.5 77 82.5Z"
          fill="#3A85D0"
        />
        <path
          d="M77 121C83.0751 121 88 116.075 88 110C88 103.925 83.0751 99 77 99C70.9249 99 66 103.925 66 110C66 116.075 70.9249 121 77 121Z"
          fill="#3A85D0"
        />
        <path
          d="M77 159.5C83.0751 159.5 88 154.575 88 148.5C88 142.425 83.0751 137.5 77 137.5C70.9249 137.5 66 142.425 66 148.5C66 154.575 70.9249 159.5 77 159.5Z"
          fill="#3A85D0"
        />
      </g>
      <defs>
        <clipPath id="clip0_18_3435">
          <rect
            width="132"
            height="132"
            fill="white"
            transform="translate(66 44)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
