import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../Components/Dialog/Dialog";
import styled from "styled-components";
import { ShareIcon } from "../../icons/Share.icon";
import { theme } from "../../style/theme";
import { LinkedInIcon } from "../../icons/LinkedInIcon";
import { Link01 } from "../../icons/link-01.icon";
import { Download01Icon } from "../../icons/Download01.Icon";
import { CopyIcon } from "../../icons/CopyIcon";
import { LinkExternalIcon } from "../../icons/link-external.icon";
import { HTMLIcon } from "../../icons/HTML.icon";
import { usePage } from "../../queries/page/usePages";
import toast from "react-hot-toast";
import { MarkdownIcon } from "../../icons/Hash.icon";
import { JSONIcon } from "../../icons/Json.icon";
import { PageID } from "../EditorPage/MetadataComponents";
import { usePublishPage } from "../../queries/publish/usePublish";
import { useProject } from "../../queries/project/useProject";
import { ENDPOINTS } from "../../tools";
import { XIcon } from "../../icons/X.icon";
import { useProjectType } from "../../hooks/useProjectType";
import { MailIcon } from "../../icons/MailIcon";
import { FacebookIcon } from "../../icons/Facebook.icon";

interface Props {
  children?: React.ReactNode;
}

export function ShareAndExportDialog({ children }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent style={{ textAlign: "center" }}>
        <Content />
      </DialogContent>
      <DialogTrigger
        asChild={true}
        onClick={(e) => {
          console.log("clicked");
          e.stopPropagation();
          setOpen(true);
        }}
      >
        {children}
      </DialogTrigger>
    </Dialog>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;
const Title = styled.h2`
  font-size: 25px !important;
  font-weight: 500 !important;
  margin-bottom: 16px;
  text-align: center;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

const Content = () => {
  const { data: page } = usePage().query ?? { _id: "" };
  const { html, title = "untitled" } = page ?? {};
  const { data: project } = useProject().query ?? {};

  const { mutation: publishMutation, query: publishQuery } = usePublishPage(
    page,
    project
  );
  const { data: publishStatus } = publishQuery;

  const isPlublishing = publishStatus === "publishing";

  const { pageName } = useProjectType();

  const pageLink = `https://${project?.slug}.notice.site/${page?.slug}`;

  const sharings = [
    {
      label: "LinkedIn",
      icon: <LinkedInIcon size={14} color={theme.primary} />,
      onClick: () => {
        const maxLength = 1300; // LinkedIn's character limit for posts
        const separator = "...";

        // Remove HTML tags from the text
        const plainText = html.replace(/<[^>]+>/g, "");

        // Crop the text if it exceeds the character limit
        const croppedText =
          plainText.length > maxLength
            ? plainText.slice(0, maxLength - separator.length) + separator
            : plainText;

        // Construct the LinkedIn share URL
        const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          pageLink
        )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(
          croppedText
        )}`;

        // Open the LinkedIn share URL in a new window
        window.open(shareUrl, "_blank");
      },
      actionName: "Share",
    },
    {
      label: "Twitter",
      icon: <XIcon size={14} color={theme.primary} />,
      onClick: () => {
        const maxLength = 280; // Twitter's character limit for tweets
        const separator = "...";

        // Remove HTML tags from the text
        const plainText = html
          .replace(/<[^>]+>/g, "\n")
          .replaceAll("\n\n", "\n");

        // Calculate the available characters for the text
        const linkLength = ` ${pageLink}`.length;
        const availableCharacters = maxLength - linkLength;

        // Crop the text if it exceeds the available characters
        const croppedText =
          plainText.length > availableCharacters
            ? plainText.slice(0, availableCharacters - separator.length) +
              separator
            : plainText;

        // Construct the Twitter share URL
        const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          croppedText
        )}&url=${encodeURIComponent(pageLink)}`;

        // Open the Twitter share URL in a new window
        window.open(shareUrl, "_blank");
      },
      actionName: "Share",
    },
    {
      label: "Facebook",
      icon: <FacebookIcon size={14} color={theme.primary} />,
      onClick: () => {
        const maxLength = 1000; // Facebook's recommended maximum length for posts
        const separator = "...";

        // Remove HTML tags from the text
        const plainText = html.replace(/<[^>]+>/g, "");

        // Crop the text if it exceeds the maximum length
        const croppedText =
          plainText.length > maxLength
            ? plainText.slice(0, maxLength - separator.length) + separator
            : plainText;

        // Construct the Facebook share URL
        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          pageLink
        )}&quote=${encodeURIComponent(croppedText)}`;

        // Open the Facebook share URL in a new window
        window.open(shareUrl, "_blank", "width=600,height=400");
      },
      actionName: "Share",
    },

    {
      label: "Link",
      icon: <Link01 size={14} color={theme.primary} />,
      onClick: () => {
        // Copy to clipboard
        navigator.clipboard.writeText(pageLink);
        toast.success("Link copied to clipboard");
      },
      actionName: "Copy",
    },
    {
      label: "Email",
      icon: <MailIcon color={theme.primary} size={14} />,
      onClick: () => {
        window.open(
          `mailto:?subject=Check out my new ${pageName.toLowerCase()} - ${title}&body=Hey,%0A%0AI just published a new ${pageName.toLowerCase()}, you can visit it by clicking on this link ${pageLink}.`
        );
      },
      actionName: "Send",
    },
  ];

  const exports = [
    {
      label: "HTML",
      downloadAction: () => {
        const blob = new Blob([html], { type: "text/html" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${title}.html`;
        a.click();
        URL.revokeObjectURL(url);
      },
      copyAction: () => {
        // Copy to clipboard
        navigator.clipboard.writeText(html);
        toast.success("HTML copied to clipboard");
      },
      visitAction: () => {
        window.open(`${ENDPOINTS.API}/publications/pages/${page._id}`);
      },
      icon: <HTMLIcon />,
    },
    {
      label: "Markdown",
      downloadAction: async () => {
        const response = await fetch(
          `${ENDPOINTS.API}/publications/pages/${page._id}?format=markdown`
        );
        const json = await response.json();
        const markdown = json.markdown;
        const blob = new Blob([markdown], { type: "text/markdown" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${title}.md`;
        a.click();
        URL.revokeObjectURL(url);
      },
      copyAction: async () => {
        const response = await fetch(
          `${ENDPOINTS.API}/publications/pages/${page._id}?format=markdown`
        );
        const json = await response.json();
        const markdown = json.markdown;
        // Copy to clipboard
        navigator.clipboard.writeText(markdown);

        toast.success("Markdown copied to clipboard");
      },
      visitAction: () => {
        window.open(
          `${ENDPOINTS.API}/publications/pages/${page._id}?format=markdown`
        );
      },
      icon: <MarkdownIcon size={14} color={theme.primary} />,
    },
    {
      label: "JSON",
      downloadAction: () => {
        const blob = new Blob([JSON.stringify(page, null, 2)], {
          type: "application/json",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${title}.json`;
        a.click();
        URL.revokeObjectURL(url);
      },
      copyAction: () => {
        // Copy to clipboard
        navigator.clipboard.writeText(JSON.stringify(page, null, 2));
        toast.success("JSON copied to clipboard");
      },
      visitAction: () => {
        window.open(`${ENDPOINTS.API}/publications/pages/${page._id}`);
      },
      icon: <JSONIcon size={14} color={theme.secondary} />,
    },
  ];
  return (
    <Wrapper>
      <Title>
        <ShareIcon color={theme.primary} />
        Share and Export
      </Title>
      {!isPlublishing ? (
        <Paragraph
          style={{
            fontStyle: "italic",
          }}
        >
          Content is not up to date? Don't forget to
          <ActionLink
            style={{ marginLeft: 4 }}
            onClick={() => {
              publishMutation.mutate();
            }}
          >
            publish your page.
          </ActionLink>
        </Paragraph>
      ) : (
        <Paragraph
          style={{
            fontStyle: "italic",
          }}
        >
          Publishing...
        </Paragraph>
      )}

      <Section>
        <SectionTitle>Share</SectionTitle>
        <SharingMainWrapper>
          {sharings.map((sharing) => (
            <SharingComponent {...sharing} key={sharing.label} />
          ))}
        </SharingMainWrapper>
      </Section>
      <Section>
        <SectionTitle>Export</SectionTitle>
        <ExportWrapper>
          {exports.map((exportItem) => (
            <ExportComponent {...exportItem} key={exportItem.label} />
          ))}
        </ExportWrapper>
      </Section>
      <Section>
        <h3 style={{ marginBottom: 12 }}>API</h3>
        <Paragraph>
          Need more?{" "}
          <ActionLink
            onClick={() => {
              window.open(
                "https://documenter.getpostman.com/view/19890120/2sA3Bq4AnA",
                "_blank"
              );
            }}
          >
            Explore our API
          </ActionLink>{" "}
          and build your own integrations.
        </Paragraph>

        <Paragraph>Use the below ID to reference the page:</Paragraph>
        <PageID />
      </Section>
    </Wrapper>
  );
};

const SharingMainWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 8px;
`;

const ActionLink = styled.span`
  margin-left: 2px;
  color: ${({ theme }) => theme.secondary};
  cursor: pointer;
`;

const Paragraph = styled.p`
  font-size: 14px;
`;

const ExportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SectionTitle = styled.h3`
  padding-bottom: 8px;
`;

const Section = styled.div`
  border-top: 1px solid ${({ theme }) => theme.lightText};
  margin-bottom: 32px;
`;

const ExportComponent = ({
  label,
  downloadAction,
  copyAction,
  visitAction,
  icon,
}) => {
  return (
    <ExportContainer>
      <ExportLabelContainer style={{ gap: label === "HTML" ? 0 : 8 }}>
        <ExportLabel>{label}</ExportLabel>
        {icon}
      </ExportLabelContainer>
      <Actions>
        <Action onClick={downloadAction}>
          Download
          <Download01Icon color={theme.primary} size={12} />
        </Action>
        <Action onClick={copyAction}>
          Copy
          <CopyIcon size={12} color={theme.primary} />
        </Action>
        <Action onClick={visitAction}>
          API
          <LinkExternalIcon size={12} color={theme.primary} />
        </Action>
      </Actions>
    </ExportContainer>
  );
};

const ExportContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.lightText};
  padding: 12px;
  background-color: white;
`;

const Action = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

const ExportLabelContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const ExportLabel = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
`;

function SharingComponent({ label, icon, onClick, actionName }) {
  return (
    <SharingWrapper>
      <ShareContainer onClick={onClick}>{actionName}</ShareContainer>
      {label}
      <IconContainer>{icon}</IconContainer>
    </SharingWrapper>
  );
}

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ShareContainer = styled.div`
  width: 55.5px;
  box-sizing: border-box;
  padding-right: 8px;
  display: flex;
  justify-content: center;
  padding-left: 8px;
  padding-bottom: 4px;
  padding-top: 4px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.lightText};
  background-color: ${({ theme }) => theme.secondary};
  color: white;
  cursor: pointer;
  border-radius: 6px 0px 0px 6px;

  &:hover {
    opacity: 0.8;
  }
`;

const SharingWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
  gap: 8px;
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.lightText};
  background-color: white;
  width: 150px;
`;
