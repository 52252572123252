import { IconProps } from ".";

export const QuoteIcon = ({ size = 30 }: IconProps) => {
  return (
    <svg
      style={{ width: size, height: size }}
      width="264"
      height="220"
      viewBox="0 0 264 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110 63.25H88C82.1652 63.25 76.5694 65.5678 72.4436 69.6936C68.3178 73.8194 66 79.4152 66 85.25V107.25C66 110.167 67.1589 112.965 69.2218 115.028C71.2847 117.091 74.0826 118.25 77 118.25H109.505C108.2 125.931 104.222 132.903 98.2742 137.936C92.3262 142.968 84.791 145.735 77 145.75C75.5413 145.75 74.1424 146.329 73.1109 147.361C72.0795 148.392 71.5 149.791 71.5 151.25C71.5 152.709 72.0795 154.108 73.1109 155.139C74.1424 156.171 75.5413 156.75 77 156.75C88.6655 156.737 99.8495 152.097 108.098 143.848C116.347 135.599 120.987 124.415 121 112.75V74.25C121 71.3326 119.841 68.5347 117.778 66.4718C115.715 64.4089 112.917 63.25 110 63.25V63.25Z"
        fill="#3A85D0"
      />
      <path
        d="M187 63.25H165C159.165 63.25 153.569 65.5678 149.444 69.6936C145.318 73.8194 143 79.4152 143 85.25V107.25C143 110.167 144.159 112.965 146.222 115.028C148.285 117.091 151.083 118.25 154 118.25H186.505C185.2 125.931 181.222 132.903 175.274 137.936C169.326 142.968 161.791 145.735 154 145.75C152.541 145.75 151.142 146.329 150.111 147.361C149.079 148.392 148.5 149.791 148.5 151.25C148.5 152.709 149.079 154.108 150.111 155.139C151.142 156.171 152.541 156.75 154 156.75C165.665 156.737 176.849 152.097 185.098 143.848C193.347 135.599 197.987 124.415 198 112.75V74.25C198 71.3326 196.841 68.5347 194.778 66.4718C192.715 64.4089 189.917 63.25 187 63.25V63.25Z"
        fill="#3A85D0"
      />
    </svg>
  );
};
