import styled from "styled-components";

export function SectionTab({
  title,
  children,
}: {
  title?: string;
  children?: any;
}) {
  return (
    <Wrapper style={{ marginTop: title ? 48 : 0 }}>
      {title && <Title>{title}</Title>}
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: 600px;
  border: 1px solid ${({ theme }) => theme.lightText};
  padding: 8px;
  border-radius: 4px 0px 4px 4px;
  position: relative;
`;

const Title = styled.div`
  position: absolute;
  right: -1px;
  top: -29px;
  color: ${({ theme }) => theme.lightText};
  border-radius: 4px 4px 0 0;
  border: 1px solid ${({ theme }) => theme.lightText};
  border-bottom: none;
  padding: 4px;
  box-sizing: border-box;
`;

export const Explanation = styled.p`
  color: ${({ theme }) => theme.lightText};
  /* italic */
  font-style: italic;
  font-size: 14px;
  padding: 0px 8px 8px 8px;
  margin: 0;
`;
