import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import { usePage } from "../../queries/page/usePages";
import { useProject } from "../../queries/project/useProject";
import { AddTag } from "./AddTag";
import { Tag } from "./Tag";
import { capitalize } from "./helpers";

export function Tags() {
  const {
    query: { data: page },
    mutation: updatePage,
  } = usePage();
  const [tagsRef] = useAutoAnimate();

  const {
    query: { data: project },
    mutation: { mutate: updateProject },
  } = useProject();

  const queryClient = useQueryClient();

  const { category } = page ?? {};
  const { categories = [] } = project ?? {};

  const setTags = (tags: string[], currentTag?: string) => {
    const capiTags = tags.map(capitalize);
    updatePage.mutate({ tags: capiTags });

    if (currentTag) {
      const capiCurrentTag = capitalize(currentTag);
      removeCategory(capiTags, capiCurrentTag);
    }
  };

  const tags = page?.tags ?? [];

  const updateCategories = (tag: string) => {
    // update categories
    updatePage.mutate({ category: capitalize(tag) });
    const newCategories = categories.concat(capitalize(tag));
    updateProject({
      categories: newCategories,
    });
  };

  const removeCategory = (allTags: string[], tag: string) => {
    const capitalizedTag = capitalize(tag);

    if (category !== capitalizedTag) return;

    if (allTags.length === 0) {
      updatePage.mutate({ category: "" });
      updateProject({ categories: [] });
      queryClient.invalidateQueries({ queryKey: ["page", page._id] });
    } else {
      const newCategories = categories.filter(
        (category: string) => category !== capitalizedTag
      );
      updateCategories(allTags[0]);
      updateProject({ categories: newCategories });
    }
  };

  const addTag = (tag: string) => {
    // if tag already exists, don't add it
    if (tags.includes(capitalize(tag))) return;

    if (tags.length === 0) {
      updateCategories(tag);
    }

    setTags([...tags, tag]);
  };

  const removeTag = (tag: string) => {
    const capiTag = capitalize(tag);
    setTags(
      tags.filter((t) => t !== capiTag),
      capiTag
    );
  };

  return (
    <Container>
      <AddTag addTag={addTag} />

      <TagsContainer ref={tagsRef}>
        {tags.map((tag) => (
          <Tag
            key={tag}
            name={capitalize(tag)}
            removeTag={removeTag}
            category={category}
            updateCategories={updateCategories}
          />
        ))}
      </TagsContainer>
    </Container>
  );
}

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
`;

const Container = styled.div`
  max-width: 500px;
`;
