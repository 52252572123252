import { useMutation } from "@tanstack/react-query";
import { AxiosAPI } from "../../queries/api";
import { handleAPIErrors } from "../../tools/handleAPIErrors";
import { useWorkspace } from "../../queries/workspaces/useWorkspaces";

export const useAIGenerateImage = () => {
  const { data: workspace } = useWorkspace().query;
  const mutation = useMutation({
    mutationFn: async ({ prompt }: any) => {
      const { data } = await AxiosAPI.post(
        `/ai/image?workspaceId=${workspace._id}`,
        {
          prompt,
        }
      );
      return data;
    },
    onError: (_) => {
      console.log("Error");
      handleAPIErrors(_);
    },
  });

  return mutation;
};
