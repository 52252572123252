export type ChatMessages = Array<ChatMessage>;

export interface ChatMessage {
  role: string;
  content: string;
}

export const extendCurrentSelection = (
  currentSelection,
  { title }
): ChatMessages => {
  const TitleInfo = title ? `The title of the page is: ${title}` : ``;
  return [
    {
      role: "system",
      content: `Continue the text given by the user on 2 paragraphs. Your reply must only be plain text. ${TitleInfo}`,
    },
    { role: "user", content: currentSelection },
  ];
};

export const generalPrompt = (prompt): ChatMessages => {
  return [
    {
      role: "system",
      content: `The user wants to generate content. Your reply must only be html and contain only the answer. Do not include the following tags: <html>, <head>, <body>, <style>, <script>.`,
    },
    { role: "user", content: prompt },
  ];
};

export const findTitle = (currentSelection): ChatMessages => {
  return [
    {
      role: "system",
      content: `Find a title for the text given by the user. Your reply must only be plain text.`,
    },
    { role: "user", content: currentSelection },
  ];
};

export const translatePrompt = (text, targetLang): ChatMessages => {
  return [
    {
      role: "system",
      content: `Translate the text given to ${targetLang}. Your reply must only be html and contain only the translated text. Do not include the following tags: <html>, <head>, <body>, <style>, <script>.`,
    },
    { role: "user", content: text },
  ];
};

// export const generateImagePrompt = (html): ChatMessages => {
//   return [
//     {
//       role: "system",
//       content: `Describe an illustration for an AI that generates images. Your reply must only be plain text. Do not include anything other than the directions. Be specific with what you want: a photo, a drawing, a painting, etc. Do not use the term digital.`,
//     },
//     {
//       role: "user",
//       content: `The user wants an image to illustrate their text. They want you to generate the prompt for an AI that generates images. Find an idea and give the directions. No more than 3 sentences. This is the content of the page in HTML:\n
//       ${html}
//     `,
//     },
//   ];
// };

export const generateImagePrompt = (html): ChatMessages => {
  const cropped = html.slice(0, 10000);

  return [
    {
      role: "system",
      content: `
      You are a helpful assistant. You help users to find the right image for their text.
      You return a prompt that will make a good illustration for a text that will be provided to you.
      Only return what is asked for, don't make any comment. 
      ONLY OUTPUT THE PROMPT. NOTHING ELSE. NO COMMENT. NO EXPLANATION. NO EXAMPLE.`,
    },
    {
      role: "user",
      content: `Give a good image prompt to create an illustration for the following html:\n
      "${cropped}"
    `,
    },
  ];
};
