import { BubbleMenu } from "@tiptap/react";
import { useCallback } from "react";
import styled from "styled-components";
import { NSelect } from "../../Components/Select/Select.component";
import { AlignCenterIcon } from "../../icons/align-center";
import { JustifyIcon } from "../../icons/align-justify";
import { AlignLeftIcon } from "../../icons/align-left";
import { AlignRightIcon } from "../../icons/align-right";
import { BoldIcon } from "../../icons/bold-01";
import { CodeSnippetIcon } from "../../icons/code-snippet-01";
import { ItalicIcon } from "../../icons/italic-01";
import { Link01 } from "../../icons/link-01.icon";
import { StrikethroughIcon } from "../../icons/strikethrough-01";
import { theme } from "../../style/theme";
import { capitalize } from "../Tags/helpers";

export function InlineMenu({ editor }: any) {
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("Set the link", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);
  const hideCentering =
    editor.isActive("image") ||
    editor.isActive("codeBlock") ||
    editor.isActive("html") ||
    editor.isActive("hint");

  const getCurrentBlockName = () => {
    try {
      if (editor && editor.state.selection) {
        return editor.state.selection.$head.parent.type.name;
      }
      return "";
    } catch (_) {
      return "";
    }
  };
  const currentBlockName = useCallback(getCurrentBlockName, [editor]);
  const blockName = currentBlockName();

  const showBlockMenu = ["heading", "paragraph", "hint"].includes(blockName);

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{ duration: 100 }}
      className='bubble-menu'
      shouldShow={({ editor, view, state, oldState, from, to }) => {
        return editor &&
          editor.state &&
          editor.state.selection &&
          editor.state.selection.content() &&
          !editor.isActive("image") &&
          !editor.isActive("codeBlock") &&
          !editor.isActive("youtube") &&
          !editor.isActive("html")
          ? editor.state.selection.content().size > 0
          : false;
      }}
    >
      <Button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? "is-active" : ""}
      >
        <BoldIcon size={14} color={theme.text} />
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? "is-active" : ""}
      >
        <ItalicIcon size={14} color={theme.text} />
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={editor.isActive("strike") ? "is-active" : ""}
      >
        <StrikethroughIcon size={14} color={theme.text} />
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        className={editor.isActive("code") ? "is-active" : ""}
      >
        <CodeSnippetIcon size={14} color={theme.text} />
      </Button>

      <Button
        onClick={() => {
          setLink();
        }}
      >
        <Link01 size={14} color={theme.text} />
      </Button>

      {!hideCentering && (
        <>
          <Button
            onClick={() => editor.chain().focus().setTextAlign("left").run()}
            className={
              editor.isActive({ textAlign: "left" }) ? "is-active" : ""
            }
          >
            <AlignLeftIcon size={14} color={theme.text} />
          </Button>
          <Button
            onClick={() => editor.chain().focus().setTextAlign("center").run()}
            className={
              editor.isActive({ textAlign: "center" }) ? "is-active" : ""
            }
          >
            <AlignCenterIcon size={14} color={theme.text} />
          </Button>
          <Button
            onClick={() => editor.chain().focus().setTextAlign("right").run()}
            className={
              editor.isActive({ textAlign: "right" }) ? "is-active" : ""
            }
          >
            <AlignRightIcon size={14} color={theme.text} />
          </Button>
          <Button
            onClick={() => editor.chain().focus().setTextAlign("justify").run()}
            className={
              editor.isActive({ textAlign: "justify" }) ? "is-active" : ""
            }
          >
            <JustifyIcon size={14} color={theme.text} />
          </Button>
        </>
      )}
      {showBlockMenu && (
        <ChangeBlockMenu editor={editor} blockName={blockName} />
      )}
    </BubbleMenu>
  );
}

const Button = styled.button`
  height: 100%;
  font-size: inherit;
  font-family: inherit;
  color: ${(props) => props.theme.text};
  padding: 8px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-collapse: separate;

  &:hover {
    background-color: rgb(227, 227, 227);
  }
`;

const options = [
  {
    name: "Paragraph",
    command: (editor) => editor.chain().focus().setParagraph().run(),
  },
  {
    name: "Heading 1",
    command: (editor) => editor.chain().focus().setHeading({ level: 1 }).run(),
  },
  {
    name: "Heading 2",
    command: (editor) => editor.chain().focus().setHeading({ level: 2 }).run(),
  },
  {
    name: "Heading 3",
    command: (editor) => editor.chain().focus().setHeading({ level: 3 }).run(),
  },
  {
    name: "Hint",
    command: (editor) => editor.chain().focus().setHint().run(),
  },
  {
    name: "Bullet list",
    command: (editor) => editor.chain().focus().toggleBulletList().run(),
  },
  {
    name: "Ord. list",
    command: (editor) => editor.chain().focus().toggleOrderedList().run(),
  },
];

const ChangeBlockMenu = ({ editor, blockName }) => {
  const valueOption = {
    label: capitalize(blockName),
    value: blockName,
  };

  return (
    <NSelect
      options={options.map((option) => ({
        label: option.name,
        value: option.name,
      }))}
      value={valueOption}
      onChange={(val) => {
        const option = options.find((option) => option.name === val.value);
        option.command(editor);
      }}
      styles={{
        control: (provided, state) => ({
          display: "flex",
          backgroundColor: "white",
          borderRadius: "0px 4px 4px 0px",
          height: 36,
          cursor: "pointer",
          color: theme.text,
          borderLeft: `1px solid ${theme.lightGrey}`,
        }),
        valueContainer: (provided, state) => ({
          display: "flex",
          alignItems: "center",
          paddingLeft: 8,
          fontSize: 12,
        }),
        singleValue: (provided, state) => ({
          margin: 0,
          display: "flex",
          alignItems: "center",
          width: "100%",
        }),
        menuList: (provided, state) => ({
          minWidth: "fit-content",
          fontSize: 12,
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          padding: 4,
        }),
      }}
    />
  );
};
