import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../Components/Popover/Popover";
import { useState } from "react";
import { useVersions } from "../../queries/versions/useVersions";
import styled from "styled-components";
import { changeVersion } from "../../queries/versions/versions.calls";
import { useParams } from "react-router-dom";

export function PageVersions({ children }) {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen} placement="bottom-start">
      <StyledPopover>
        <Versions />
      </StyledPopover>

      <PopoverTrigger onClick={() => setOpen((v) => !v)}>
        <ClickWrapper>{children}</ClickWrapper>
      </PopoverTrigger>
    </Popover>
  );
}

const StyledPopover = styled(PopoverContent)`
  padding: 0;
  border: none;
  background-color: rgb(255, 255, 255, 0.3);
  border-radius: 8px 0px 0px 8px;
`;

const ClickWrapper = styled.div`
  /* When the user hover, add shadow */
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  border-radius: 8px;
  transition: all 0.2s;

  &:hover {
    background-color: white;
    box-shadow: ${({ theme }) => theme.shadow};
  }
`;

function Versions() {
  const { data: versions, isLoading } = useVersions().query;

  if (isLoading) return;

  // // Split the versions by month (using createdAt)
  // const groupedVersions = versions.reduce((acc, version) => {
  //   const month = new Date(version.createdAt).toLocaleString("default", {
  //     month: "long",
  //   });

  //   if (!acc[month]) {
  //     acc[month] = [];
  //   }

  //   acc[month].push(version);

  //   return acc;
  // }, {});

  return (
    <Wrapper>
      <VersionHistory>Version History</VersionHistory>
      {versions?.length ? (
        versions.map((version) => (
          <Version version={version} key={version._id} />
        ))
      ) : (
        <div style={{ padding: 16, fontSize: 12 }}>No versions found</div>
      )}
    </Wrapper>
  );
}

const VersionHistory = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 500;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  border-bottom: 1px solid ${(props) => props.theme.lightText};
  pointer-events: none;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  background-color: white;

  /* Scrollable */
  overflow: auto;
`;

function Version({ version }) {
  const { pageId } = useParams();
  const { createdAt, user, _id } = version;
  const { name, picture } = user;
  const date = new Date(createdAt);
  const formattedDate = date.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const handleChange = async () => {
    // changeVersion(pageId, _id);

    await changeVersion(pageId, _id);

    // reload the page
    window.location.reload();
  };

  return (
    <VersionWrapper onClick={handleChange}>
      <DateDisplay>{formattedDate}</DateDisplay>
      <User>
        {picture && <Avatar src={picture} alt={name} />}
        {name}
      </User>
    </VersionWrapper>
  );
}

const Avatar = styled.img`
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;

const VersionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s;
  padding: 8px;
  gap: 2px;

  /* last child */
  &:last-child {
    border-radius: 0px 0px 8px 8px;
  }

  &:hover {
    background-color: ${(props) => props.theme.lightBg};
    /* backdrop-filter: blur(10px); */
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.05);
  }
`;
const DateDisplay = styled.div`
  font-weight: 700;
  font-size: 12px;
`;

const User = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
`;
