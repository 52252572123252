import { useTheme } from "styled-components";
import { NSelect } from "../Select/Select.component";
import { NLanguages } from "./languages";
import { useProject } from "../../queries/project/useProject";

const options = Object.entries(NLanguages.LANGUAGES).map(([key, value]) => {
  return {
    label: value.name,
    value: key,
  };
});

export function LanguageSelector({ styles }: { styles?: any }) {
  const {
    query: { data: project },
    mutation: { mutate: updateProject },
  } = useProject();

  const handleProjectUpdate = (value: string) => {
    updateProject({ defaultLanguage: value });
  };
  const theme = useTheme();
  const defaultLanguageCode = project?.defaultLanguage ?? "";

  const defaultLanguageOption =
    defaultLanguageCode && NLanguages.LANGUAGES[defaultLanguageCode]
      ? {
          value: defaultLanguageCode,
          label: NLanguages.LANGUAGES[defaultLanguageCode].name,
        }
      : null;

  return (
    <>
      <NSelect
        className="translate-default-tooltip"
        value={defaultLanguageOption}
        options={options}
        onChange={(val) => handleProjectUpdate(val.value)}
        isSearchable={true}
        styles={{
          control: (provided, state) => ({
            display: "flex",
            backgroundColor: "white",
            borderRadius: "4px",
            height: 36,
            cursor: "pointer",
            color: theme.text,
            border: `1px solid ${theme.textLight}`,
          }),
          singleValue: (provided, state) => ({
            ...provided,
            margin: 0,
          }),
          ...styles,
        }}
      >
        Language
      </NSelect>
    </>
  );
}
