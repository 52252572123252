import { useEffect } from "react";
import { usePageStatus } from "../queries/page/usePages";

export const useLostSavingConfirmation = () => {
  const { data: pageStatus } = usePageStatus().query;
  const busy = pageStatus === "saving";

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (!busy) return;
      const confirmationMessage = "Changes that you made may not be saved.";
      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [busy]);
};
