import styled, { useTheme } from "styled-components";
import { Dialog, DialogContent } from "../../Components/Dialog/Dialog";
import { TextInput } from "../../Components/Input/InputText";
import {
  Invitation,
  ROLES,
  Roles,
} from "../../queries/collaborators/collaborators.call";
import { capitalize } from "../Tags/helpers";
import { useCollaborators } from "../../queries/collaborators/useCollaborators";
import { useState } from "react";
import { useUser } from "../../queries/user/useUser";
import { NSelect } from "../../Components/Select/Select.component";

export function AddCollaboratorModal({
  collabOpen,
  setCollabOpen,
}: {
  collabOpen: boolean;
  setCollabOpen: (value: boolean) => void;
}) {
  const { data: collaborators } = useCollaborators().query;

  const { data: user, isLoading } = useUser().query;
  const [invitation, setInvitation] = useState<Partial<Invitation>>({
    email: "",
    role: "editor",
  });
  const theme = useTheme();
  const { mutate: sendInvitation } = useCollaborators().mutation;

  const userRole = collaborators?.find(
    (collaborator) => collaborator._id === user?._id
  )?.role;

  // Only Roles that are equal or less than the current user's role can be assigned
  // slice the array according to the current user's role
  const options = ROLES.slice(ROLES.indexOf(userRole)).map((role) => ({
    value: role,
    label: capitalize(role),
  }));

  if (isLoading) return <></>;

  if (userRole === "viewer")
    return <div>Cannot invite collaborators as a viewer.</div>;

  const handleInvitation = (invitation) => {
    if (!invitation?.email || !invitation?.role) return;

    sendInvitation(invitation);
    setCollabOpen(false);
  };

  return (
    <Dialog open={collabOpen} onOpenChange={setCollabOpen}>
      <DialogContent style={{ width: "100%" }}>
        <Section>
          <h3>E-mail</h3>
          <TextInput
            placeholder="Enter email"
            onChange={(e) =>
              setInvitation({ ...invitation, email: e.target.value })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleInvitation(invitation);
              }
            }}
          />
        </Section>
        {/* <Section>
          <h3>
            Pick a role <small> - more options soon</small>
          </h3>
          <NSelect
            options={options}
            value={{
              value: invitation.role,
              label: capitalize(invitation.role),
            }}
            onChange={(selectedOption) => {
              setInvitation({
                ...invitation,
                role: selectedOption.value as Roles,
              });
            }}
          />
        </Section> */}
        <Kbd
          style={{ marginTop: 32 }}
          onClick={() => {
            handleInvitation(invitation);
          }}
        >
          Send invitation
        </Kbd>
      </DialogContent>
    </Dialog>
  );
}

const Kbd = styled.kbd`
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${(props) => props.theme.lightText};
  cursor: pointer;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 16px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.text};
  &:hover {
    background-color: ${(props) => props.theme.pastelYellow};
  }
  background-color: transparent;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;
