import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components";
import { HomeIcon } from "../../icons/home.icon";
import { PAGES } from "../../Router/constants";
import { useProject } from "../../queries/project/useProject";
import { usePage, usePageStatus } from "../../queries/page/usePages";
import { UploadCloudIcon } from "../../icons/upload-cloud";
import { CheckCircleIcon } from "../../icons/check-circle";
import { PageVersions } from "../PageVersions/PageVersions";
import { usePublishPage } from "../../queries/publish/usePublish";
import { useEffect, useState } from "react";
import { NoticeToolTip } from "../../Components/ToolTip/ToolTip";
import { Kdb } from "../../Components/Kbd/Kbd.component";
import { getModifierKey } from "../TipTap/AIToolBar";
import { ShareIcon } from "../../icons/Share.icon";
import { ShareAndExportDialog } from "../ShareAndExport/ShareAndExportDialog";

export function BreadcrumbNav() {
  const { projectId, workspaceId, pageId } = useParams();
  const { data: project } = useProject().query;
  const { data: page } = usePage().query;
  const [isScroll, setIsScroll] = useState(false);

  // WHen the user starts scrolling, make the breadcrumb white bg

  const handleScroll = () => {
    // Code to handle scroll event

    setIsScroll(window.scrollY > 10);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Container>
      <Breadcrumb $isScroll={isScroll}>
        <Link
          to={`/${PAGES.WORKSPACE}/${workspaceId}`}
          end
          style={{
            display: "flex",
            minWidth: 14,
            marginLeft: 8,
            marginRight: 8,
          }}
        >
          <HomeIcon size={14} />
        </Link>

        {projectId && project && (
          <LinkContainer>
            <Slash>/</Slash>
            <Link
              to={`/${PAGES.WORKSPACE}/${workspaceId}/${PAGES.PROJECT}/${projectId}`}
              end
            >
              {project.name}
            </Link>
          </LinkContainer>
        )}

        {pageId && page && (
          <LinkContainer>
            <Slash>/</Slash>
            <Link
              to={`/${PAGES.WORKSPACE}/${workspaceId}/${PAGES.PROJECT}/${projectId}/${PAGES.EDITOR}/${pageId}`}
              end
            >
              {page.title}
            </Link>
          </LinkContainer>
        )}
      </Breadcrumb>
      {pageId && <PageActions isScroll={isScroll} />}
    </Container>
  );
}

const PublishPage = styled.div<{ disabled?: boolean; $isScroll?: boolean }>`
  font-size: 13px;
  border: 1px solid
    ${({ theme, $isScroll }) => ($isScroll ? "transparent" : theme.lightText)};
  padding: 4px 8px;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: ${({ theme, disabled }) => {
      if (disabled) return "transparent";
      return theme.lightText;
    }};
    color: ${({ theme, disabled }) => {
      if (disabled) return theme.lightText;
      return "white";
    }};
  }
`;

const LinkContainer = styled.div`
  a {
    padding-left: 8px;
    padding-right: 8px;
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  max-width: 100px;
`;

const Slash = styled.span`
  font-size: 13px;
  font-weight: 700;
  cursor: default;
`;

const Container = styled.div`
  pointer-events: none;
  * {
    pointer-events: auto;
  }
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  background-color: transparent;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.lightText};
  align-self: baseline;
  font-size: 14px;
  position: sticky;
  top: 12px;
  color: ${({ theme }) => theme.lightText};
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  z-index: 3;
`;

const Breadcrumb = styled.div<{ $isScroll: boolean }>`
  background-color: ${({ $isScroll }) => ($isScroll ? "white" : "transparent")};
  border-radius: 8px;
  transition: shadow 0.2s linear;
  box-shadow: ${({ theme, $isScroll }) => ($isScroll ? theme.shadow : "none")};
  display: flex;
  align-items: center;
  padding: 8px;
  opacity: 1;
`;

const Link = styled(NavLink)`
  color: ${({ theme }) => theme.lightText};
  text-decoration: none;
  font-weight: 300;
  path {
    transition: stroke 0.2s ease;
  }
  &:hover {
    color: #333;
    path {
      stroke: #333;
    }
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
  transition: color 0.2s;
`;

export function SavingIndicator() {
  const { data } = usePageStatus().query;
  if (!data) return null;

  const getIndicator = () => {
    switch (data) {
      case "saving":
        return (
          <SavingIndicatorWrapper>
            <UploadCloudIcon size={12} />
            Saving...
          </SavingIndicatorWrapper>
        );
      case "saved":
        return (
          <SavingIndicatorWrapper>
            <CheckCircleIcon size={12} />
            Saved
          </SavingIndicatorWrapper>
        );
      case "error":
        return <SavingIndicatorWrapper>Error</SavingIndicatorWrapper>;
      default:
        return null;
    }
  };

  return (
    <>
      <NoticeToolTip anchorSelect={".saved-tooltip-indicator"} delayShow={800}>
        <p>Content is saved automatically.</p>
        <p>
          You can also trigger a save with <Kdb>{getModifierKey()} + s</Kdb>
        </p>
        <Subtitle>Click to see version history</Subtitle>
      </NoticeToolTip>

      <div className="saved-tooltip-indicator">{getIndicator()}</div>
    </>
  );
}
const SavingIndicatorWrapper = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-width: 70px;
  opacity: 1;
  height: 100%;
  width: 100%;
`;

function PageActions({ isScroll }: { isScroll?: boolean }) {
  const { data: page, isLoading } = usePage().query;
  const {
    query: { data: project = {} },
  } = useProject();
  const { data: pageStatus } = usePageStatus().query;

  const { mutation: publishMutation, query: publishQuery } = usePublishPage(
    page,
    project
  );
  const { data: publishStatus } = publishQuery;

  const isPlublishing = publishStatus === "publishing";
  if (isLoading) return <></>;
  return (
    <RightActionsWrapper $isScroll={isScroll}>
      <NoticeToolTip anchorSelect=".share-tooltip">
        Share and export
      </NoticeToolTip>
      <ShareAndExportDialog>
        <ShareIconContainer className="share-tooltip">
          <ShareIcon />
        </ShareIconContainer>
      </ShareAndExportDialog>
      <PublishPage
        onClick={() =>
          !isPlublishing &&
          !(pageStatus === "saving") &&
          publishMutation.mutate()
        }
        disabled={isPlublishing || pageStatus === "saving"}
        $isScroll={isScroll}
      >
        {isPlublishing ? "Publishing..." : "Publish"}
      </PublishPage>

      <PageVersions>
        <SavingIndicator />
      </PageVersions>
    </RightActionsWrapper>
  );
}

const ShareIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
  cursor: pointer;
  &:hover {
    svg {
      fill: ${({ theme }) => theme.lightText};
    }
  }
`;

const Subtitle = styled.div`
  font-size: 10px;
  border-top: 1px solid #eee;
  padding-top: 4px;
  font-style: italic;
`;

const RightActionsWrapper = styled.div<{ $isScroll?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 16px;
  background-color: ${({ $isScroll }) => ($isScroll ? "white" : "transparent")};
  border-radius: 8px;
  transition: shadow 0.2s linear;
  box-shadow: ${({ theme, $isScroll }) => ($isScroll ? theme.shadow : "none")};
  padding-left: 8px;
`;
