import toast from "react-hot-toast";
import styled from "styled-components";
import { theme } from "../style/theme";
import { Button } from "../Components/Button";

export function handleAPIErrors(err) {
  if (err?.response?.status === 402) {
    toast(<SubToast msg={err.response.data.error} />);
    return;
  }

  // Usually these are the errors thrown by express-validator
  try {
    toast.error(err.response.data.errors[0].msg);
    return;
  } catch (e) {}

  // Discretly thrown by the dev in the API
  try {
    toast.error(err.response.data.error);
    return;
  } catch (e) {}

  try {
    if (typeof err.response.data === "string") {
      toast.error(err.response.data);

      return;
    }
  } catch (e) {}

  // Fallback
  toast.error("Something went wrong");
}

export function SubToast({ msg }) {
  return (
    <Wrapper>
      {msg}
      <Button
        onClick={() => {
          window.location.href = `/workspace/?tab=projects&subopen=true`;
        }}
        style={{
          backgroundColor: theme.secondary,
          fontSize: 16,
        }}
      >
        See Plans
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  gap: 8px;
`;
