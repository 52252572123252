import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Project,
  deleteProject,
  getProject,
  updateProject,
  updateProjectLogo,
} from "./project.calls";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { handleAPIErrors } from "../../tools/handleAPIErrors";
import { getImageDimensions } from "../file/files.helper";

export const useProject = () => {
  const { projectId } = useParams();

  const queryClient = useQueryClient();
  // Queries
  const query = useQuery({
    queryKey: ["project", projectId],
    queryFn: () => getProject({ projectId }),
  });

  // Mutations
  const mutation = useMutation({
    mutationFn: (project: Partial<Project>) =>
      updateProject({ ...project, _id: projectId }),
    onMutate: async (project) => {
      queryClient.cancelQueries();

      const previousProject = queryClient.getQueryData(["project", projectId]);

      queryClient.setQueryData(["project", projectId], (old: any) => {
        return { ...old, ...project };
      });

      return { previousProject };
    },
    onSuccess: ({ data }: any) => {
      queryClient.setQueryData(["project", projectId], (old: any) => {
        return data;
      });
    },
    onError: (err: any, project, context) => {
      queryClient.setQueryData(["project", projectId], (old: any) => {
        return context.previousProject;
      });

      handleAPIErrors(err);
    },
  });

  return {
    query,
    mutation,
  };
};

export const useDeleteProject = () => {
  const queryClient = useQueryClient();
  const { workspaceId } = useParams();

  const mutation = useMutation({
    mutationFn: (projectId: string) => deleteProject(projectId),
    onMutate: async (projectId) => {
      queryClient.cancelQueries();
      queryClient.setQueryData(["projects", workspaceId], (old: any) => {
        return old.filter((w: any) => w._id !== projectId);
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["projects", workspaceId],
      });
    },
    onError: (err: any, projectId, context) => {
      queryClient.invalidateQueries({
        queryKey: ["projects", workspaceId],
      });

      handleAPIErrors(err);

      toast.error(err.response.data.errors[0].msg);
    },
  });
  return { mutation };
};

export const useUploadLogo = () => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({ file, type }: any) => {
      const formData = new FormData();

      formData.append("file", file);

      try {
        const { width, height } = await getImageDimensions(file);
        formData.append("aspectRatio", (width / height).toString());
      } catch (ex) {}

      const url = await updateProjectLogo(projectId, formData);
      return url;
    },
    onMutate: ({ file }) => {
      const url = URL.createObjectURL(file);
      queryClient.setQueryData(["project", projectId], (old: any) => {
        return { ...old, logoUrl: url };
      });
    },

    onSuccess: (data) => {
      queryClient.setQueryData(["project", projectId], (old: any) => {
        return { ...old, logoUrl: data };
      });

      return data;
    },
  });

  return { mutation };
};
