import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { EditorPage } from "../Containers/EditorPage/Editor.page";
import { ConnectPage } from "../Containers/Login.page.tsx/Connect.page";
import { ProjectPage } from "../Containers/ProjectPage/Project.page";
import { RootPage } from "../Containers/RootPage/RootPage";
import { SubscriptionCreatedRedirect } from "../Containers/SubscriptionCreatedRedirect/SubscriptionCreatedRedirect";
import { WorkspacePage } from "../Containers/WorkspacePage/Workspace.page";
import { PAGES } from "./constants";
import ErrorPage from "./ErrorPage";

export function NoticeRouter() {
  return <RouterProvider router={router} />;
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: PAGES.CONNECT,
        element: <ConnectPage />,
      },
      {
        path: PAGES.SUBSCRIPTION_CREATED_REDIRECT,
        element: <SubscriptionCreatedRedirect />,
      },
      {
        path: `${PAGES.WORKSPACE}/:workspaceId?`,
        element: <WorkspacePage />,
      },
      {
        path: `${PAGES.WORKSPACE}/:workspaceId/${PAGES.PROJECT}/:projectId?`,
        element: <ProjectPage />,
      },
      {
        path: `${PAGES.WORKSPACE}/:workspaceId/${PAGES.PROJECT}/:projectId/${PAGES.EDITOR}/:pageId`,
        element: <EditorPage />,
      },
    ],
  },
]);
