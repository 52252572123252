import { useProject } from "../queries/project/useProject";
import { legacyColors } from "../style/theme";
import templatesJson from "../templates.json";

export const templateColors = {
  documentation: legacyColors.primaryDark,
  faq: "#FFB745",
  helpCenter: legacyColors.textRed,
  blog: "#65C985",
  ["terms-of-use"]: "#F1A66D",
  privacy: "#C4D78E",
  "job-board": legacyColors.twilightGrey,
  getStarted: "#BF7AE4",
  eCommerceFaq: "#A1ACBD",
  changelog: "#659DFF",
  mvv: legacyColors.textRed,
  investorsReport: "#6DC9B7",
  portfolio: legacyColors.yellorange,
};

export const PROJECT_TYPES_MAP = templatesJson;

interface ProjectType {
  text: string;
  type: string;
  color: string;
  templates: string[];
  pageName: string;
  config: {
    subtitle: {
      show: boolean;
      defaultValue?: string;
      maxLength?: number;
    };
    description: {
      show: boolean;
      defaultValue?: string;
    };
    logo: {
      show: boolean;
    };
    heroBackgroundColor?: {
      show: boolean;
    };
  };
}

export const useProjectType = (): ProjectType => {
  const { query = {} } = useProject() ?? {
    query: {
      data: {},
    },
  };
  const { data: project } = query ?? {};

  return PROJECT_TYPES_MAP[project?.type ?? "blog"] ?? {};
};
