import { Tooltip, PlacesType } from "react-tooltip";
import styled from "styled-components";

export function NoticeToolTip({
  anchorSelect,
  children,
  delayShow = 500,
  place = "top",
}: {
  anchorSelect: string;
  children: React.ReactNode;
  delayShow?: number;
  place?: PlacesType;
}) {
  return (
    <StyledToolTip
      anchorSelect={anchorSelect}
      delayShow={delayShow}
      place={place}
    >
      {children}
    </StyledToolTip>
  );
}

const StyledToolTip = styled(Tooltip)`
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 0.9;
  --rt-transition-show-delay: 0.15s;
  --rt-transition-closing-delay: 0.15s;

  max-width: 400px;
  z-index: 999;
`;
