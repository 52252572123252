import { mergeAttributes, Node } from "@tiptap/core";
import {
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react";
import styled from "styled-components";

export interface HintOptions {
  HTMLAttributes: Record<string, any>;
}

const HINT_SUBTYPES = {
  info: {
    icon: "📘",
    color: "primaryDark",
  },
  warning: {
    icon: "❗️",
    color: "warning",
  },
  danger: {
    icon: "🚫",
    color: "error",
  },
  tip: {
    icon: "🌱",
    color: "success",
  },
};

const Hint = ({
  node,
  editor,
  extension,
  updateAttributes,
  getPos,
  decorations,
}) => {
  const { category = "info" } = node.attrs;
  const hintSubtype = HINT_SUBTYPES[category] ?? HINT_SUBTYPES.info;

  const changeType = (newType) => {
    updateAttributes({ category: newType });
  };
  return (
    <NodeViewWrapper className='react-component'>
      <Wrapper>
        <IconWrapper
          contentEditable={false}
          onClick={() => {
            const types = Object.keys(HINT_SUBTYPES);
            const currentIndex = types.indexOf(category);
            const nextIndex = (currentIndex + 1) % types.length;
            changeType(types[nextIndex]);
          }}
        >
          {hintSubtype.icon}
        </IconWrapper>

        <NodeViewContent className='content' />
      </Wrapper>
    </NodeViewWrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  background-color: rgb(240, 243, 251);
  position: relative;
  padding: 16px 16px 16px 16px;
  border-radius: 8px;
  gap: 8px;
`;

const IconWrapper = styled.span`
  cursor: pointer;
`;

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    hint: {
      /**
       * Toggle a hint
       */
      setHint: () => ReturnType;
    };
  }
}

export const HintExtension = Node.create<HintOptions>({
  name: "hint",

  //   @ts-ignore
  addNodeView() {
    return ReactNodeViewRenderer(Hint);
  },

  addOptions() {
    return {
      HTMLAttributes: {
        class: "hint-container",
      },
    };
  },

  defining: true,
  group: "block",
  content: "inline*",
  inline: false,

  addAttributes() {
    return {
      category: {
        default: "info",
        keepOnSplit: true,
        parseHTML: (element) => element.getAttribute("category"),
        renderHTML: (attributes) => ({
          category: attributes.category,
        }),
      },
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "div",
      ["p", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0],
    ];
  },

  addCommands() {
    return {
      setHint:
        () =>
        ({ commands }) => {
          return commands.setNode(this.name);
        },
    };
  },
});
