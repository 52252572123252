import { IconProps } from '.'

export const Heading3Icon = ({ size = 30 }: IconProps) => {
	return (
		<svg
			style={{ width: size, height: size }}
			width="264"
			height="220"
			viewBox="0 0 264 220"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_18_3434)">
				<path
					d="M131.77 71.5C129.113 71.5 126.957 73.6528 126.957 76.3125V103.583H82.0408V76.3125C81.9349 69.9857 72.5216 69.9889 72.4158 76.3125V143.688C72.5216 150.014 81.9349 150.011 82.0408 143.688V113.208H126.957V143.688C127.067 150.014 136.477 150.011 136.582 143.688V76.3125C136.582 73.6528 134.426 71.5 131.77 71.5Z"
					fill="#3A85D0"
				/>
			</g>
			<g clipPath="url(#clip1_18_3434)">
				<path
					d="M179.421 105.004C182.356 102.694 184.249 99.1192 184.249 95.1042C184.249 88.1535 178.593 82.5 171.645 82.5H154.457C153.192 82.5 152.166 83.5267 152.166 84.7917C152.166 86.0567 153.192 87.0833 154.457 87.0833H171.645C176.068 87.0833 179.666 90.6813 179.666 95.1042C179.666 99.5271 176.068 103.125 171.645 103.125H159.041C157.776 103.125 156.749 104.152 156.749 105.417C156.749 106.682 157.776 107.708 159.041 107.708H171.645C178.593 107.708 184.249 113.362 184.249 120.313C184.249 127.263 178.593 132.917 171.645 132.917H154.457C153.192 132.917 152.166 133.943 152.166 135.208C152.166 136.473 153.192 137.5 154.457 137.5H171.645C181.123 137.5 188.832 129.791 188.832 120.313C188.832 113.635 184.996 107.848 179.421 105.004Z"
					fill="#3A85D0"
				/>
			</g>
			<defs>
				<clipPath id="clip0_18_3434">
					<rect width="77" height="77" fill="white" transform="translate(66 71.5)" />
				</clipPath>
				<clipPath id="clip1_18_3434">
					<rect width="55" height="55" fill="white" transform="translate(143 82.5)" />
				</clipPath>
			</defs>
		</svg>
	)
}
