import styled from "styled-components";
import { SmallLoader } from "./SmallLoader";

interface Props {
  children: React.ReactNode;
  onClick?: (e?: any) => void;
  style?: React.CSSProperties;
  bgColor?: string;
  width?: string;
  disabled?: boolean;
  loading?: boolean;
  loaderPosition?: "relative" | "absolute";
  color?: string;
  className?: string;
}

export function Button({
  children,
  onClick,
  style,
  width,
  disabled,
  loading = false,
  loaderPosition,
  color,
  className,
}: Props) {
  return (
    <ButtonWrapper onClick={onClick} width={width}>
      <StyledButton style={style} disabled={disabled} className={className}>
        {children}

        {loading && (
          <Container
            style={{
              position: loaderPosition || "absolute",
              right: 0,
              marginRight: loaderPosition === "relative" ? "0px" : "8px",
            }}
          >
            <SmallLoader color={color} size={"18px"} />
          </Container>
        )}
      </StyledButton>
    </ButtonWrapper>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: 16px;
`;

const StyledButton = styled.button<{ disabled: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primary};

  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  color: white;

  padding: 8px 16px;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1.5px solid ${({ theme }) => theme.primary};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: 0.2s ease;
  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.3 : 0.8)};
  }
`;

const ButtonWrapper = styled.div<{ onClick: any; width }>`
  width: ${({ width }) => width || "fit-content"};
`;
