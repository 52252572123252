import styled from "styled-components";
import { PopoverContent } from "../../Components/Popover/Popover";
import { TrashIcon } from "../../icons/TrashIcon";
import { theme } from "../../style/theme";
import { DeleteConfirmationModal } from "./DeleteConfirmation";
import { useState } from "react";

export function ProjectOptionsPopOver({ open, setOpen, project }) {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const Actions = [
    {
      icon: TrashIcon,
      text: "Delete",
      onClick: (e) => {
        e.stopPropagation();
        setOpenConfirmation(true);
      },
    },
  ];
  return (
    <PopoverContent
      className="Popover"
      style={{
        padding: 0,
      }}
    >
      <DeleteConfirmationModal
        modalOpen={openConfirmation}
        setModalOpen={setOpenConfirmation}
        project={project}
      />
      {Actions.map((action, index) => (
        <ActionContainer key={index} onClick={action.onClick}>
          <Text>{action.text}</Text>
          <action.icon size={18} color={theme.text} />
        </ActionContainer>
      ))}
    </PopoverContent>
  );
}

const Text = styled.span``;

const ActionContainer = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130px;
  height: 35px;
  cursor: pointer;
  font-weight: 500;
  color: ${({ theme }) => theme.text};

  /* apply border radius to first child */
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  /* apply border radius to last child */
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;
