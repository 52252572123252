import { AxiosAPI } from "../api";
import { User } from "../user/user.calls";
import { Workspace } from "../workspaces/workspaces.calls";

export type Roles = "owner" | "editor" | "viewer";
// export const ROLES = ["owner", "editor", "viewer"];
export const ROLES = ["editor"];

export interface Collaborator {
  _id: string;
  email: string;
  role: Roles | "";
}

export type Collaborators = Collaborator[];

export interface Invitation {
  workspace: Partial<Workspace>;
  sentBy: Partial<User>;
  email: string;
  role: Roles;
  _id: string;
}

export async function getCollaborators(workspaceId: string) {
  const collaborators = await AxiosAPI.get(
    `/workspaces/${workspaceId}/collaborators`
  );
  return collaborators.data;
}

export async function sendInvitation(
  invitation: {
    email: string;
    role: string;
  },
  workspaceId: string
) {
  const collaborators = await AxiosAPI.post(
    `/workspaces/${workspaceId}/collaborators`,
    invitation
  );
  return collaborators.data;
}

export async function getInvitations() {
  const invitations = await AxiosAPI.get(`/invitations`);
  return invitations.data;
}

export async function answerInvitation(
  status: "accepted" | "declined",
  invitationId: string
) {
  const invitations = await AxiosAPI.put(`/invitations/${invitationId}`, {
    status,
    invitationId,
  });
  return invitations.data;
}
