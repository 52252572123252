import { useMutation } from "@tanstack/react-query";
import { AxiosAPI } from "../../../../queries/api";

export const useDomainCheckDNS = () => {
  const mutation = useMutation({
    mutationFn: async (body: any) => {
      const { data } = await AxiosAPI.post("/domain/check/dns", body);
      return data;
    },
  });

  return { mutation };
};
