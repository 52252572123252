import { ProfileIcon } from "../../icons/profile.icon";
import { LogOutIcon } from "../../icons/log-out.icon";
import { ProfileModal } from "../Profile/ProfileModal";
import { useState } from "react";
import styled from "styled-components";
import { NoticeToolTip } from "../../Components/ToolTip/ToolTip";
import { AxiosAPI } from "../../queries/api";
import { SubscriptionModal } from "../Subscription/SubscriptionModal";
import { CardIcon } from "../../icons/Card.icon";
import { useQueryParam } from "../../hooks/useQueryParam";

export const disconnect = async () => {
  await AxiosAPI.post("/auth/disconnect");
  window.location.href = "/connect";
};

export function WorkspaceActions() {
  const [profileOpen, setProfileOpen] = useState(false);
  const [getQueryParam, setQueryParam] = useQueryParam();
  const subOpen = getQueryParam("subopen") ?? "";
  const isSubOpen = subOpen === "true";

  return (
    <Wrapper>
      {/* Profile */}
      <NoticeToolTip anchorSelect=".profile-tooltip" delayShow={300}>
        Profile
      </NoticeToolTip>

      <IconContainer
        className="profile-tooltip"
        onClick={() => setProfileOpen(true)}
      >
        <ProfileIcon size={18} />
      </IconContainer>
      <ProfileModal profileOpen={profileOpen} setProfileOpen={setProfileOpen} />

      {/* Billing */}
      <NoticeToolTip anchorSelect=".billing-tooltip" delayShow={300}>
        Billing
      </NoticeToolTip>
      <IconContainer
        className="billing-tooltip"
        onClick={() => setQueryParam("subopen", "true")}
      >
        <CardIcon size={18} />
      </IconContainer>
      <SubscriptionModal
        open={isSubOpen}
        setOpen={() => setQueryParam("subopen", isSubOpen ? "false" : "true")}
      />

      {/* Log out */}
      <NoticeToolTip anchorSelect=".disconnect-tooltip" delayShow={300}>
        Log out
      </NoticeToolTip>
      <IconContainer className="disconnect-tooltip" onClick={disconnect}>
        <LogOutIcon size={18} />
      </IconContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }
`;
