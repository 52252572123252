import styled from "styled-components";
import { Project } from "../../queries/project/project.calls";
import { capitalize } from "../Tags/helpers";
import { Page } from "../../queries/page/pages.calls";
import { PROJECT_TYPES_MAP } from "../../hooks/useProjectType";
import { theme } from "../../style/theme";

export function PublishToast({
  project,
  projectTypeText,
}: {
  project: Project;
  projectTypeText: string;
}) {
  return (
    <Wrapper>
      <span style={{ marginRight: 8 }}>Published -</span>
      <A
        href={`https://${project?.slug}.notice.site`}
        target="_blank"
        color="red"
        style={{ color: theme.secondary }}
      >
        Visit your {capitalize(projectTypeText ?? "blog")}
      </A>
    </Wrapper>
  );
}

const A = styled.a`
  text-decoration: none;
`;

const Wrapper = styled.div`
  display: flex;
  border-radius: 0px;
`;

export function PublishPageToast({
  page,
  project,
}: {
  page: Page;
  project: Project;
}) {
  const { pageName = "page" } = PROJECT_TYPES_MAP[project?.type ?? "blog"];

  return (
    <Wrapper>
      <span style={{ marginRight: 8 }}>Published -</span>
      <A
        href={`https://${project?.slug}.notice.site/${page?.slug}`}
        target="_blank"
        style={{ color: theme.secondary }}
      >
        Visit your {pageName}
      </A>
    </Wrapper>
  );
}
