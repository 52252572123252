import { useQuery } from "@tanstack/react-query";
import { getAllVersions, getVersion } from "./versions.calls";
import { useParams } from "react-router-dom";

export const useVersion = ({ versionId }) => {
  const query = useQuery({
    queryKey: ["version", versionId],
    queryFn: () => getVersion(versionId),
  });

  return {
    query,
  };
};

export const useVersions = () => {
  const { pageId } = useParams();
  const query = useQuery({
    queryKey: ["versions", pageId],
    queryFn: () => getAllVersions(pageId),
  });

  return {
    query,
  };
};
