import Select from "react-select";
import { useTheme } from "styled-components";

interface Props {
  options: { value: string; label: string }[];
  onChange: (selected: { value: string; label: string }) => void;
  value?: { value: string; label: string } | null;
  isSearchable?: boolean;
  [key: string]: any; // Allow additional props
}

export function NSelect({
  options,
  onChange,
  value,
  styles,
  isSearchable = false,
  ...rest
}: Props) {
  const theme = useTheme();
  return (
    <Select
      options={options}
      styles={{
        menu: (provided, state) => ({
          ...provided,
          border: "1px solid #E5E5E5",
          borderRadius: "4px",
          color: theme.text,
          boxShadow: theme.shadow,
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#E5E5E5" : "white",
          color: theme.text,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#E5E5E5",
          },
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
        }),
        control: (provided, state) => ({
          display: "flex",
          backgroundColor: "white",
          borderRadius: "4px",
          height: 36,
          cursor: "pointer",
          color: theme.text,
        }),
        indicatorSeparator: (provided, state) => ({
          display: "none",
        }),
        valueContainer: (provided, state) => ({
          ...provided,
        }),
        singleValue: (provided, state) => ({
          ...provided,
        }),
        ...styles,
      }}
      isSearchable={isSearchable}
      onChange={onChange}
      value={value}
      {...rest}
    ></Select>
  );
}
