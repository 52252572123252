import styled from "styled-components";
import { useProject, useUploadLogo } from "../../queries/project/useProject";
import { DropZoneArea } from "../DropZoneArea/DropZoneArea";

export const ProjectLogo = () => {
  const {
    query: { data: project },
  } = useProject();

  const updateLogo = useUploadLogo().mutation;

  return (
    <DropZoneArea
      type={"image"}
      acceptedTypes={["image/png", "image/jpeg"]}
      mutation={updateLogo}
    >
      <ProjectLogoContainer>
        {project?.logoUrl && <img src={project?.logoUrl} alt="project logo" />}
        {!project?.logoUrl && <DropPlaceHolder>Upload file</DropPlaceHolder>}
      </ProjectLogoContainer>
    </DropZoneArea>
  );
};

const DropPlaceHolder = styled.div`
  border: 1px dashed ${({ theme }) => theme.lightText};
  padding: 8px;
  border-radius: 8px;
`;

const ProjectLogoContainer = styled.div`
  img {
    width: 50px;
    transition: border 0.3s;
    height: 50px;
  }
  cursor: pointer;

  display: flex;
  justify-content: center;
  &:hover {
    opacity: 0.8;
  }
  transition: opacity 0.3s ease-in-out;
`;
