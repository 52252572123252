import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  Page,
  createPage,
  deletePage,
  duplicatePage,
  getPage,
  getPages,
  updatePage,
  updatePageContent,
} from "./pages.calls";
import { toast } from "react-hot-toast";
import { handleAPIErrors } from "../../tools/handleAPIErrors";

export const usePageStatus = () => {
  const { pageId } = useParams();

  const queryClient = useQueryClient();

  // Queries
  const query = useQuery({
    queryKey: ["pageStatus", pageId],
    queryFn: () => queryClient.getQueryData(["pageStatus", pageId]),
    initialData: "saved",
  });

  return { query };
};

export const usePage = (overridePageId?: string) => {
  const { pageId: paramPageId, projectId } = useParams();
  const pageId = overridePageId ?? paramPageId;

  const queryClient = useQueryClient();

  // Queries
  const query = useQuery({
    queryKey: ["page", pageId],
    queryFn: () => getPage({ pageId }),
  });

  const mutation = useMutation({
    mutationFn: async (page: Partial<Page>) =>
      updatePage({ ...page, _id: page._id ?? pageId }),
    onMutate: async (page) => {
      queryClient.setQueryData(
        ["pageStatus", page._id ?? pageId],
        (old: any) => {
          return "saving";
        }
      );

      queryClient.setQueryData(["page", page._id ?? pageId], (old: any) => {
        return { ...old, ...page };
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["page", data?._id ?? pageId], (old: any) => {
        return data;
      });

      queryClient.invalidateQueries({ queryKey: ["pages", projectId] });

      queryClient.setQueryData(
        ["pageStatus", data?._id ?? pageId],
        (old: any) => {
          return "saved";
        }
      );
    },
    onError: (err: any) => {
      console.error(err);

      queryClient.setQueryData(["page", pageId], (old: any) => {
        return old;
      });
      queryClient.setQueryData(["pageStatus", pageId], (old: any) => {
        return "error";
      });
      handleAPIErrors(err);
    },
  });

  return {
    query,
    mutation,
  };
};

export const useSavePageContent = () => {
  const { pageId, projectId } = useParams();

  const queryClient = useQueryClient();

  // Queries
  const query = useQuery({
    queryKey: ["page", pageId],
    queryFn: () => getPage({ pageId }),
  });

  const mutation = useMutation({
    mutationFn: async (page: Partial<Page>) =>
      updatePageContent({ ...page, _id: pageId }),
    onMutate: async (page) => {
      queryClient.invalidateQueries({ queryKey: ["pages", projectId] });

      queryClient.setQueryData(["pageStatus", pageId], (old: any) => {
        return "saving";
      });
      queryClient.setQueryData(["page", pageId], (old: any) => {
        return { ...old, ...page };
      });
    },
    onSuccess: (data: any) => {
      queryClient.setQueryData(["page", pageId], (old: any) => {
        return data;
      });
      queryClient.setQueryData(["pageStatus", pageId], (old: any) => {
        return "saved";
      });
    },
    onError: (err: any) => {
      console.error(err);
      queryClient.setQueryData(["page", pageId], (old: any) => {
        return old;
      });
      queryClient.setQueryData(["pageStatus", pageId], (old: any) => {
        return "error";
      });
      toast.error("Error saving content");
    },
  });

  return {
    query,
    mutation,
  };
};

export const usePages = () => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();

  // Queries
  const query = useQuery({
    queryKey: ["pages", projectId],
    queryFn: () => getPages({ projectId }),
  });

  // Mutations
  const mutation = useMutation({
    mutationFn: ({
      name,
      _id,
      noticeType,
    }: {
      name: string;
      _id: string;
      noticeType: string;
    }) => createPage({ name, _id, projectId, noticeType }),
    onMutate: async ({ name, _id }) => {
      queryClient.cancelQueries();
      queryClient.setQueryData(["pages", projectId], (old: any) => {
        return [...old, { name, _id }];
      });
    },
    onSuccess: ({ data }: any) => {
      queryClient.setQueryData(["pages", projectId], (old: any) => {
        return old.map((w: any) => {
          if (w._id === data._id) {
            return data;
          }
          return w;
        });
      });
    },
    onError: (err: any, { _id }) => {
      queryClient.setQueryData(["pages", projectId], (old: any) => {
        const rollBack = old.filter((w: any) => w._id !== _id);
        return rollBack;
      });

      handleAPIErrors(err);
    },
  });

  return {
    query,
    mutation,
  };
};

export const useDeletePage = () => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (pageId: string) => deletePage(pageId),
    onMutate: async (pageId) => {
      queryClient.cancelQueries();
      queryClient.setQueryData(["pages", projectId], (old: any) => {
        return old.filter((w: any) => w._id !== pageId);
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["pages", projectId],
      });
    },
    onError: (err: any) => {
      queryClient.invalidateQueries({
        queryKey: ["pages", projectId],
      });

      handleAPIErrors(err);
    },
  });

  return {
    mutation,
  };
};

export const useDuplicatePage = () => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (pageId: string) => duplicatePage(pageId),
    onMutate: async (pageId) => {
      queryClient.cancelQueries();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["pages", projectId],
      });
    },
    onError: (err: any) => {
      handleAPIErrors(err);
    },
  });

  return {
    mutation,
  };
};
