import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { WrapperProjectType } from "../../Components/Pill/Pill.component";
import { Popover, PopoverTrigger } from "../../Components/Popover/Popover";
import { Collaborator } from "../../Components/User/User.component";
import { PAGES } from "../../Router/constants";
import { PROJECT_TYPES_MAP } from "../../hooks/useProjectType";
import { useNavigateToProject, useQueryParam } from "../../hooks/useQueryParam";
import { ChevronIcon } from "../../icons/ChevronIcon";
import { DotsHorizontalIcon } from "../../icons/dots-horizontal.icon";
import {
  useCollaborators,
  useInvitations,
} from "../../queries/collaborators/useCollaborators";
import { useProjects } from "../../queries/projects/useProjects";
import {
  useWorkspace,
  useWorkspaces,
} from "../../queries/workspaces/useWorkspaces";
import { AddCollaboratorModal } from "./AddCollaboratorModal";
import { InvitationModal } from "./InvitationModal";
import { ProjectOptionsPopOver } from "./ProjectOptions";
import { WorkspaceActions } from "./WorkspaceActions";
import { WorkspaceSettings } from "./WorkspaceSettingsModal";

import { useUser } from "../../queries/user/useUser";
import { CurrentWP } from "../HomePage/Home.page";
import { CreateANewProject } from "../TemplatesList/CreateProject";

export function WorkspacePage() {
  const user = useUser().query.data;
  const [collabOpen, setCollabOpen] = useState(false);
  const { isLoading, data: workspaces } = useWorkspaces().query;
  const { data: invitations } = useInvitations().query;
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [getQueryParam, setQueryParam] = useQueryParam();

  const currentTab = getQueryParam("tab");

  const { query } = useWorkspace();
  const { data: workspace } = query;
  const { data: collaborators } = useCollaborators().query;

  const { workspaceId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentTab || !["projects", "settings", "team"].includes(currentTab)) {
      setQueryParam("tab", "projects");
    }
  }, [workspaceId]);

  useEffect(() => {
    if (invitations?.length) {
      setInviteModalOpen(true);
    }
  }, [invitations]);

  const [listRef] = useAutoAnimate(/* optional config */);

  const openCollaboratorsModal = () => {
    if (workspace.productId != null) {
      setCollabOpen(true);
    } else {
      navigate(`/${PAGES.WORKSPACE}/${workspaceId}?tab=team&subopen=true`);
    }
  };

  useEffect(() => {
    if (isLoading) return;

    if (workspaceId && workspaceId !== "undefined") return;

    // Check if there is a workspaceId in local storage
    const localWorkspaceId = localStorage.getItem("workspaceId");

    if (localWorkspaceId === "undefined")
      localStorage.removeItem("workspaceId");

    const targetWP =
      localWorkspaceId && localWorkspaceId !== "undefined"
        ? localWorkspaceId
        : workspaces[0]._id;
    const found = workspaces.find((wp) => wp._id === targetWP);
    if (!found && !workspaces[0]) return;
    const targetId = found ? found._id : workspaces[0]._id;

    navigate(`/${PAGES.WORKSPACE}/${targetId}`);
  }, [isLoading, workspaces, navigate, workspaceId]);

  if (isLoading || !workspace) return <></>;

  return (
    <Wrapper ref={listRef}>
      <CurrentWP />
      <HeadingSection>
        <h1 style={{ marginBottom: 16 }}>Welcome {user?.name} 🏡</h1>
        <WorkspaceActions />
      </HeadingSection>
      <SectionTitle
        onClick={() =>
          setQueryParam("tab", currentTab === "projects" ? "" : "projects")
        }
      >
        <ChevronIcon
          style={{
            transform:
              currentTab === "projects" ? "rotate(90deg)" : "rotate(0deg)",
            transition: "transform 0.2s ease-in-out",
          }}
        />
        <H2>Projects</H2>
      </SectionTitle>
      {currentTab === "projects" && (
        <Section>
          <ProjectList />
        </Section>
      )}
      <SectionTitle
        onClick={() =>
          setQueryParam("tab", currentTab === "settings" ? "" : "settings")
        }
      >
        <ChevronIcon
          style={{
            transform:
              currentTab === "settings" ? "rotate(90deg)" : "rotate(0deg)",
            transition: "transform 0.2s ease-in-out",
          }}
        />
        <H2>Settings</H2>
      </SectionTitle>
      {currentTab === "settings" && (
        <Section>
          <WorkspaceSettings />
        </Section>
      )}
      <SectionTitle
        onClick={() =>
          setQueryParam("tab", currentTab === "team" ? "" : "team")
        }
      >
        <ChevronIcon
          style={{
            transform: currentTab === "team" ? "rotate(90deg)" : "rotate(0deg)",
            transition: "transform 0.2s ease-in-out",
          }}
        />
        <H2>Team</H2>
      </SectionTitle>

      {currentTab === "team" && (
        <CollaboratorWrapper>
          <AddCollab onClick={openCollaboratorsModal}>
            Add collaborator
          </AddCollab>
          {collaborators?.length ? (
            collaborators.map((collaborator) => {
              return (
                <Collaborator
                  key={collaborator?._id}
                  collaborator={collaborator}
                />
              );
            })
          ) : (
            <></>
          )}
        </CollaboratorWrapper>
      )}
      <AddCollaboratorModal
        collabOpen={collabOpen}
        setCollabOpen={setCollabOpen}
      />
      {invitations?.length ? (
        <InvitationModal
          inviteModalOpen={inviteModalOpen}
          setInviteModalOpen={setInviteModalOpen}
          invitation={invitations?.[0]}
        />
      ) : (
        <></>
      )}
    </Wrapper>
  );
}

const SectionTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }

  /* User can't select the text */
  user-select: none;
`;

const AddCollab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  width: 250px;
  padding: 16px;

  border-radius: 8px;
  transition: top 0.2s ease-in-out;
  top: 0px;

  backdrop-filter: blur(16px);

  cursor: pointer;
  position: relative;

  background-color: rgb(0 0 0 / 2.5%);

  border: 1.5px dashed ${(props) => props.theme.pastelBlue} !important;
  &:hover {
    background-color: ${(props) => props.theme.pastelYellow};
  }
  transition: background-color 0.2s ease-in-out;
`;

const CollaboratorWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
const HeadingSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const H2 = styled.h2`
  margin-bottom: 0px !important;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
`;

const Wrapper = styled.div`
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  width: 100%;
  display: flex;
  flex-direction: column;
  width: auto;
`;

function ProjectList() {
  const { workspaceId } = useParams();

  const { isLoading, data: projects } = useProjects(workspaceId).query;
  const [listRef] = useAutoAnimate(/* optional config */);

  if (isLoading) return <></>;

  if (!projects) return null;

  return (
    <ListWrapper ref={listRef}>
      <CreateANewProject />

      {projects.map((project) => {
        return <ProjectCard key={project?._id} project={project} />;
      })}
    </ListWrapper>
  );
}

const DotsContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0px 3px 0px 3px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1 !important;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 200px;
  cursor: pointer;
  position: relative;
  font-size: 15px;
  padding: 16px;

  box-shadow: ${(props) => props.theme.shadow};
  border-radius: 8px;
  transition: top 0.2s ease-in-out;
  top: 0px;

  backdrop-filter: blur(16px);
  background-color: rgba(255, 255, 255, 0.8);

  &:hover {
    top: -4px;
    #dots-container {
      opacity: 0.8;
    }
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 16px;
  opacity: 1;
  transition: opacity 1s ease-in-out;
`;

const ProjectCard = ({ project }) => {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const navigateToProject = useNavigateToProject();
  const { _id, name, type } = project;
  const { text, color } =
    type && PROJECT_TYPES_MAP[type]
      ? PROJECT_TYPES_MAP[type]
      : PROJECT_TYPES_MAP["blog"];

  return (
    <Card key={_id} onClick={() => navigateToProject(_id)}>
      <h2
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "100%",
          borderColor: color,
        }}
      >
        {name}
      </h2>
      <Popover
        open={optionsOpen}
        onOpenChange={setOptionsOpen}
        placement='bottom-start'
      >
        <PopoverTrigger
          onClick={(e) => {
            e.stopPropagation();
            setOptionsOpen(true);
          }}
          asChild={true}
        >
          <DotsContainer id='dots-container'>
            <DotsHorizontalIcon size={18} />
          </DotsContainer>
        </PopoverTrigger>
        <ProjectOptionsPopOver
          open={optionsOpen}
          setOpen={setOptionsOpen}
          project={project}
        />
      </Popover>

      <WrapperProjectType type={type} text={text} />
    </Card>
  );
};
