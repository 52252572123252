import Document from "@tiptap/extension-document";
import { EditorContent, useEditor } from "@tiptap/react";
import { useRef } from "react";

import { useQueryClient } from "@tanstack/react-query";
import UniqueID from "@tiptap-pro/extension-unique-id";
import { CharacterCount } from "@tiptap/extension-character-count";
import Link from "@tiptap/extension-link";
import { Placeholder } from "@tiptap/extension-placeholder";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Youtube from "@tiptap/extension-youtube";
import StarterKit from "@tiptap/starter-kit";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useQueryParam } from "../../hooks/useQueryParam";
import { useUploadFile } from "../../queries/file/useFiles";
import { useSavePageContent } from "../../queries/page/usePages";
import { TopScreenToolBar } from "./AIToolBar";
import { BlockMenu } from "./BlockMenu";
import { NoticeFileNode } from "./CustomExtensions/File.extension";
import { HintExtension } from "./CustomExtensions/Hint.extension";
import { HTMLCodeExtension } from "./CustomExtensions/HTML.extension";
import { ImageResize } from "./CustomExtensions/ImageResize.extension";
import { History } from "./CustomExtensions/NoticeHistory.extension";
import { InlineMenu } from "./InlineMenu";

const CustomDocument = Document.extend({
  content: "heading block*",
  type: "blog",
});

export const Tiptap = () => {
  const [getQueryParam] = useQueryParam();
  const currentTab = getQueryParam("tab");
  const { query, mutation } = useSavePageContent();
  const { data: page } = query;
  const [start, setStart] = useState(false);
  const queryClient = useQueryClient();
  const { mutateAsync: uploadFile } = useUploadFile().mutation;
  const handleEditorChangeRef = useRef(null);
  const previousTab = useRef(currentTab);

  const saveEditor = (ed) => {
    if (ed && ed?.isEmpty !== true) {
      const tiptapJson = ed.getJSON();
      const html = ed.getHTML();

      mutation.mutate({ content: tiptapJson.content, html });
    }
  };

  useEffect(() => {
    // If currentTab has changed, save the editor
    if (previousTab.current !== currentTab) {
      saveEditor(editor);
      previousTab.current = currentTab;
    }
  }, [currentTab]);
  const editor = useEditor({
    extensions: [
      History.configure({
        depth: 100,
      }),
      CustomDocument,
      Youtube.configure({
        width: 630,
        height: 356,
      }),
      UniqueID.configure({
        generateID: () => crypto.randomUUID(),
      }),
      ImageResize,
      // Custom Extensions
      // NoticeFileExtension,
      HintExtension,
      HTMLCodeExtension,
      NoticeFileNode,
      // Imported Extensions
      Link.configure({
        openOnClick: false,
      }),
      StarterKit.configure({
        document: false,
        history: false,
      }),
      TextStyle,
      CharacterCount.configure({}),
      Placeholder.configure({
        showOnlyCurrent: true,
        placeholder: ({ node }) => {
          if (node.type.name === "hint") {
            return "Enter text here";
          }

          if (node.type.name === "html") {
            return "";
          }

          if (node.type.name === "heading") {
            return "Untitled";
          }

          return "Type '/' for the list of blocks";
        },
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
    ],
    onUpdate: () => {
      queryClient.setQueryData(["pageStatus", page._id], "saving");

      // const tiptapJson = editor.getJSON();

      handleEditorChange();
      // setJsonContent(tiptapJson.content);
    },
    onCreate: ({ editor }) => {
      // To avoid the focus moving the page to the bottom when the user arrives
      // We only focus when the editor has 2 lines (initial values)
      if (editor && editor.getJSON().content.length === 2) {
        editor.commands.focus("end");
      }
    },
    onBeforeCreate({ editor }) {
      // Before the view is created.
    },
    onSelectionUpdate({ editor }) {
      // The selection has changed.
    },
    onTransaction({ editor, transaction }) {
      // The editor state has changed.
    },
    onFocus({ editor, event }) {
      // The editor is focused.
    },
    onBlur({ editor, event }) {
      // The editor isn’t focused anymore.
    },
    onDestroy() {
      saveEditor(editor);
    },
    content: page,
  });

  // Debounce the editor change event
  const handleEditorChange = useCallback(
    debounce(() => {
      saveEditor(editor);
    }, 1700),
    [editor?.isEmpty]
  );

  // This ref is used by lodash.debounce to flush() - aka call the debounced function immediately
  handleEditorChangeRef.current = handleEditorChange;

  const handleKeyDown = (e) => {
    if (e.key === "s" && e.metaKey) {
      e.stopPropagation();
      e.preventDefault();
      handleEditorChangeRef.current.flush();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setStart(true);
    }, 200);
  }, []);

  if (!editor || editor?.isDestroyed) {
    return null;
  }

  const addImage = async (data: DataTransfer) => {
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of Array.from(files)) {
        const [mime] = file.type.split("/");

        if (mime === "image") {
          const url = URL.createObjectURL(file);
          editor
            ?.chain()
            .focus()
            .setImage({
              src: url,
            })
            .run();

          const fileResData = await uploadFile({
            file: files[0],
            type: mime,
          });
          editor?.chain().focus().setImage({ src: fileResData.url }).run();
        } else {
          console.log("Not an image");
        }
      }
    }
  };
  const isTranslating = currentTab === "translate";

  return (
    <ContainerCool $isTranslating={isTranslating}>
      <TopScreenToolBar editor={editor} />
      <TiptapWrapper start={start ? 1 : 0} $isTranslating={isTranslating}>
        <BlockMenu editor={editor} />
        <EditorContent
          editor={editor}
          onPaste={(e) => {
            e.preventDefault();

            addImage(e.clipboardData);
          }}
          onDrop={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addImage(e.dataTransfer);
          }}
        />
        <InlineMenu editor={editor} />
      </TiptapWrapper>
    </ContainerCool>
  );
};

const ContainerCool = styled.div<{ $isTranslating?: boolean }>`
  backdrop-filter: blur(16px);
  /* background-color: rgb(255 255 255 / 19%); */
  background-color: rgb(0 0 0 / 2.5%);

  /* Sligthly blue bg */
  width: fit-content;
  padding: 8px;
  border-radius: ${({ $isTranslating }) =>
    $isTranslating ? "12px 0px 0px 12px" : "12px 0px 12px 12px"};
  transition: background-color 0.5s ease-out;
`;

export const TiptapWrapper = styled.div<{
  start: number;
  $isTranslating?: boolean;
}>`
  margin: auto;
  background-color: ${({ $isTranslating }) =>
    $isTranslating ? "transparent" : "rgb(255, 255, 255, 0.9)"};
  min-height: 100vh;
  border-radius: 8px;
  width: ${({ $isTranslating }) => ($isTranslating ? "550px" : "700px")};
  opacity: ${(props) => (props.start ? 1 : 0)};
  transition: opacity 1s ease-in-out, width 0.5s ease-out,
    background-color 0.5s ease-out;
  line-height: 26px;

  /* pointer-events: ${({ $isTranslating }) =>
    $isTranslating ? "none" : "auto"}; */
  iframe {
    border: none;
    width: 100%;
  }

  .tiptap *.is-empty::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  .ProseMirror:focus {
    outline: none;
  }

  .ProseMirror-selectednode {
    outline: none;
  }

  .content {
    flex: 1;
  }

  .bubble-menu {
    display: flex;
    border-radius: 4px;
    box-shadow: ${(props) => props.theme.shadow};

    background-color: white;

    width: fit-content;

    /* first child has border-radius */
    > *:first-child {
      border-radius: 4px 0px 0px 4px;
    }

    /* last child has border-radius */
    > *:last-child {
      border-radius: 0px 4px 4px 0px;
    }
  }

  .floating-menu {
    border-radius: 8px;
    position: relative;
    display: flex;

    left: -40px;
    flex-direction: column;
    height: 300px;
    overflow: auto;

    background-color: white;
    box-shadow: ${(props) => props.theme.shadow};
  }

  /* Basic editor styles */
  .tiptap {
    position: relative;
    padding: 32px;

    > * + * {
      margin-top: 0.75em;
    }
  }
  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  .hint-container {
    background-color: ${({ theme }) => theme.pastelBlue};
    border-radius: 8px;
    padding: 16px;
  }
  .hint {
    background-color: red !important;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  div > img {
    display: flex;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid #e0e0e0;
  }

  hr {
    border: none;
    border-top: 0.5px solid ${(props) => props.theme.borderColor};
    margin: 2rem 0;
  }

  a {
    color: ${(props) => props.theme.secondary};
    text-decoration: underline;
  }

  /* details */

  /* .is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  } */
`;
