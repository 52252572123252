import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getImageDimensions, getVideoDimensions } from "./files.helper";
import { AxiosAPI } from "../api";
import { useParams } from "react-router-dom";

export const useUploadPP = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ file, type }: any) => {
      const formData = new FormData();

      formData.append("file", file);

      try {
        const { width, height } = await getImageDimensions(file);
        formData.append("aspectRatio", (width / height).toString());
      } catch (ex) {}

      const { data: url } = await AxiosAPI.post("/user/picture", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return url;
    },
    onMutate: ({ file }) => {
      const url = URL.createObjectURL(file);
      queryClient.setQueryData(["user"], (old: any) => {
        return { ...old, picture: url };
      });
    },

    onSuccess: (data) => {
      queryClient.setQueryData(["user"], (old: any) => {
        return { ...old, picture: data };
      });

      return data;
    },
  });

  return { mutation };
};

export const useUploadFile = () => {
  const { workspaceId } = useParams();
  const mutation = useMutation({
    mutationFn: async ({ file, type }: any) => {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("workspaceId", workspaceId);

      if (type === "image") {
        try {
          const { width, height } = await getImageDimensions(file);
          formData.append("aspectRatio", (width / height).toString());
        } catch (ex) {}
      }

      if (type === "video") {
        try {
          const { width, height } = await getVideoDimensions(file);
          formData.append("aspectRatio", (width / height).toString());
        } catch (ex) {}
      }

      const { data } = await AxiosAPI.post("/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return data;
    },
    onSuccess: (data, vars) => {
      return data;
    },
  });

  return { mutation };
};
