import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { publishProject } from "./published_pages.calls";
import { toast } from "react-hot-toast";
import { handleAPIErrors } from "../../tools/handleAPIErrors";
import {
  PublishPageToast,
  PublishToast,
} from "../../Containers/PublishToast/PublishToast";
import { Project } from "../project/project.calls";
import { Page } from "../page/pages.calls";
import { useProjectType } from "../../hooks/useProjectType";

export const usePublish = (project: Project) => {
  const { text } = useProjectType();
  const { projectId } = useParams();

  const queryClient = useQueryClient();

  // Queries
  const query = useQuery({ queryKey: ["publishStatus", projectId] });

  // Mutations
  const mutation = useMutation({
    mutationFn: () => publishProject(projectId),
    onMutate: async () => {
      queryClient.cancelQueries();
      queryClient.setQueryData(["publishStatus", projectId], (old: any) => {
        return "publishing";
      });
    },
    onSuccess: ({ data }: any) => {
      setTimeout(() => {
        queryClient.setQueryData(["publishStatus", projectId], (old: any) => {
          return "published";
        });
        toast.success(
          <PublishToast project={project} projectTypeText={text} />,
          {
            duration: 5000,
            position: "top-right",
          }
        );
      }, 1000);
    },
    onError: (err: any) => {
      queryClient.setQueryData(["publishStatus", projectId], (old: any) => {
        return "error";
      });
      handleAPIErrors(err);
    },
  });

  return {
    mutation,
    query,
  };
};

export const usePublishPage = (page: Page, project: Project) => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();

  // Queries
  const query = useQuery({ queryKey: ["publishStatus", projectId] });

  // Mutations
  const mutation = useMutation({
    mutationFn: () => publishProject(projectId),
    onMutate: async () => {
      queryClient.cancelQueries();
      queryClient.setQueryData(["publishStatus", projectId], (old: any) => {
        return "publishing";
      });
    },
    onSuccess: ({ data }: any) => {
      setTimeout(() => {
        queryClient.setQueryData(["publishStatus", projectId], (old: any) => {
          return "published";
        });

        queryClient.invalidateQueries({ queryKey: ["page", page._id] });

        toast.success(<PublishPageToast page={page} project={project} />, {
          duration: 5000,
          position: "top-right",
        });
      }, 1000);
    },
    onError: (err: any) => {
      queryClient.setQueryData(["publishStatus", projectId], (old: any) => {
        return "error";
      });
      handleAPIErrors(err);
    },
  });

  return {
    mutation,
    query,
  };
};
