import { IconProps } from '.'

export const Heading2Icon = ({ size = 30 }: IconProps) => {
	return (
		<svg
			style={{ width: size, height: size }}
			width="264"
			height="220"
			viewBox="0 0 264 220"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_18_3433)">
				<path
					d="M131.77 71.5001C129.113 71.5001 126.957 73.6529 126.957 76.3126V103.583H82.0408V76.3126C81.9349 69.9857 72.5216 69.9889 72.4158 76.3126V143.688C72.5216 150.014 81.9349 150.011 82.0408 143.688V113.208H126.957V143.688C127.067 150.014 136.477 150.011 136.582 143.688V76.3126C136.582 73.6529 134.426 71.5001 131.77 71.5001Z"
					fill="#3A85D0"
				/>
			</g>
			<g clipPath="url(#clip1_18_3433)">
				<path
					d="M188.834 137.5H157.899C155.793 137.5 153.861 136.35 152.862 134.496C151.854 132.63 151.955 130.364 153.121 128.586C155.944 124.284 161.376 121.573 167.123 118.704C176.485 114.029 185.328 109.617 184.132 98.8099C183.405 92.2328 177.415 87.0811 170.496 87.0811C162.913 87.0811 156.746 93.248 156.746 100.831C156.746 102.096 155.72 103.123 154.455 103.123C153.19 103.123 152.163 102.096 152.163 100.831C152.168 90.7249 160.392 82.5001 170.501 82.5001C179.874 82.5001 187.693 89.2971 188.692 98.308C190.239 112.292 178.559 118.122 169.174 122.806C163.993 125.391 159.1 127.834 156.955 131.102C156.6 131.64 156.797 132.133 156.898 132.319C157.019 132.541 157.308 132.914 157.901 132.914H188.837C190.102 132.914 191.128 133.941 191.128 135.206C191.128 136.471 190.102 137.498 188.837 137.498L188.834 137.5Z"
					fill="#3A85D0"
				/>
			</g>
			<defs>
				<clipPath id="clip0_18_3433">
					<rect width="77" height="77" fill="white" transform="translate(66 71.5001)" />
				</clipPath>
				<clipPath id="clip1_18_3433">
					<rect width="55" height="55" fill="white" transform="translate(143 82.5001)" />
				</clipPath>
			</defs>
		</svg>
	)
}
