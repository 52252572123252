import styled from "styled-components";
import { CoverImage } from "./CoverImage";
import { Page } from "../../queries/page/pages.calls";
import { useNavigateToPage } from "../../hooks/useQueryParam";
import { DotsHorizontalIcon } from "../../icons/dots-horizontal.icon";
import { useState } from "react";
import { PageOptionsPopOver } from "./PageOptions";
import { Popover, PopoverTrigger } from "../../Components/Popover/Popover";
import { EyeOffIcon } from "../../icons/EyeOffIcon";
import { CalendarIcon } from "../../icons/CalendarIcon";
import { NoticeToolTip } from "../../Components/ToolTip/ToolTip";

interface Props {
  page: Page;
}

export function PageCard({ page }: Props) {
  const { coverImage = "", _id, title = "" } = page;
  const [optionsOpen, setOptionsOpen] = useState(false);

  const { hidden, datePublished } = page;
  const navigateToPage = useNavigateToPage();
  const isFuture = datePublished > new Date().toISOString().split("T")[0];

  return (
    <Wrapper
      onClick={() => {
        navigateToPage(_id);
      }}
    >
      <Popover
        open={optionsOpen}
        onOpenChange={setOptionsOpen}
        placement="bottom-start"
      >
        <PopoverTrigger
          onClick={(e) => {
            e.stopPropagation();
            setOptionsOpen(true);
          }}
          asChild={true}
        >
          <DotsContainer id="dots-container">
            <DotsHorizontalIcon size={18} />
          </DotsContainer>
        </PopoverTrigger>
        <PageOptionsPopOver
          open={optionsOpen}
          setOpen={setOptionsOpen}
          page={page}
        />
      </Popover>
      {isFuture && (
        <NoticeToolTip
          anchorSelect=".page-future-publish-tooltip"
          delayShow={300}
          place="top"
        >
          This article will be published on {datePublished}
        </NoticeToolTip>
      )}

      {(hidden || isFuture) && (
        <HiddenIndicator className="page-future-publish-tooltip">
          {hidden && <EyeOffIcon size={20} color="white" />}
          {isFuture && <CalendarIcon color="white" size={20}></CalendarIcon>}
        </HiddenIndicator>
      )}
      <CoverImage image={coverImage} _id={_id} />
      <NameContainer>
        <Name>{title}</Name>
      </NameContainer>
    </Wrapper>
  );
}

const HiddenIndicator = styled.div`
  color: white;
  font-weight: 500;
  align-self: center;
  display: flex;
  justify-content: center;
  padding: 4px;
  border-radius: 8px;
  position: absolute;
  left: 8px;
  top: 8px;
  gap: 8px;
`;

const DotsContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0px 3px 0px 3px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1 !important;
  }
`;

const Wrapper = styled.div`
  cursor: pointer;

  border-radius: 14px;
  top: 0px;
  position: relative;

  width: 170px;
  height: 200px;

  opacity: 1;
  backdrop-filter: blur(16px);
  background-color: rgb(0 0 0 / 2.5%);
  border: 6px solid rgba(255, 255, 255, 0.3);

  transition: filter 1s ease-in-out, top 0.2s ease-in-out,
    opacity 1s ease-in-out, background-color 0.2s ease-in-out;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1); */

  /* From https://css.glass */
  /* background: rgba(255, 255, 255, 0.42);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.1px);
  -webkit-backdrop-filter: blur(3.1px);
  border: 1px solid rgba(255, 255, 255, 0.1); */

  &:hover {
    top: -4px;
    // display the dots container
    #dots-container {
      opacity: 0.8;
    }
  }
`;

const Name = styled.p`
  text-align: center;
  padding: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

const NameContainer = styled.div`
  height: 39%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
