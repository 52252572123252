import styled from "styled-components";
import { Button } from "../../Components/Button";
import {
  Dialog,
  DialogContent,
  DialogHeading,
} from "../../Components/Dialog/Dialog";
import { useDeleteProject } from "../../queries/project/useProject";

export function DeleteConfirmationModal({ modalOpen, setModalOpen, project }) {
  const { mutation: deleteProject } = useDeleteProject();

  return (
    <Dialog open={modalOpen} onOpenChange={setModalOpen}>
      <DialogContent
        style={{ textAlign: "center" }}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogHeading>
          Are you sure you want to delete{" "}
          <b>
            <i>{project?.name}</i>
          </b>
          ?
        </DialogHeading>
        <p>
          All of the project data and its associated pages will be deleted
          forever.
        </p>

        <YesNoContainer>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              deleteProject.mutate(project._id);
              setModalOpen(false);
            }}
            style={{ width: 70, fontWeight: 700 }}
          >
            Yes
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();

              setModalOpen(false);
            }}
            style={{ width: 70, fontWeight: 700 }}
          >
            No
          </Button>
        </YesNoContainer>
      </DialogContent>
    </Dialog>
  );
}

const YesNoContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 36px;
`;
