import styled from "styled-components";
import { capitalize } from "../../Containers/Tags/helpers";
import { ProfilePlaceholder } from "../../Containers/Profile/ProfileModal";
import {
  Collaborator as CollaboratorType,
  ROLES,
  Roles,
} from "../../queries/collaborators/collaborators.call";
import { NSelect } from "../Select/Select.component";
import { useUser } from "../../queries/user/useUser";
import { useCollaborators } from "../../queries/collaborators/useCollaborators";
import { DropdownIndicator } from "react-select/dist/declarations/src/components/indicators";

interface CollaboratorProps {
  name: string;
  email: string;
  _id: string;
  role: Roles;
  picture?: string;
}

export function Collaborator({
  collaborator,
}: {
  collaborator: CollaboratorProps;
}) {
  const { name, email, picture } = collaborator;

  return (
    <CollaboratorWrapper>
      {picture ? <PP src={picture} /> : <ProfilePlaceholder size={30} />}
      <RolesSection>
        <Email>{name ?? email}</Email> <Role collaborator={collaborator} />
      </RolesSection>
    </CollaboratorWrapper>
  );
}

const Email = styled.span`
  color: ${(props) => props.theme.lighText};
`;

const RolesSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const PP = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 8px;
`;

const CollaboratorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-size: 15px;
  width: 250px;
  padding: 16px;

  box-shadow: ${(props) => props.theme.shadow};
  border-radius: 8px;
  transition: top 0.2s ease-in-out;
  top: 0px;

  backdrop-filter: blur(16px);
  background-color: rgba(255, 255, 255, 0.8);
`;

const Role = ({ collaborator }: { collaborator: CollaboratorType }) => {
  const { data: user } = useUser().query;
  const { data: collaborators } = useCollaborators().query;

  const { role } = collaborator;

  // Only Roles that are equal or less than the current user's role can be assigned
  // slice the array according to the current user's role
  // const options = ROLES.slice(ROLES.indexOf(userRole)).map((role) => ({
  //   value: role,
  //   label: capitalize(role),
  // }));

  // const userRole = collaborators?.find(
  //   (collab) => collab._id === user?._id
  // )?.role;

  // User can select all the roles except the current one
  // const options = ROLES.map((role) => ({
  //   value: role,
  //   label: capitalize(role),
  // })).filter((option) => option.value !== role);

  // const readOnly = userRole === "viewer" || collaborator._id === user?._id;
  return (
    <StyledRole>
      {capitalize(role)}

      {/* {readOnly ? (
        capitalize(role)
      ) : (
        <NSelect
          options={options}
          value={{ value: role, label: capitalize(role) }}
          onChange={(selectedOption) => {
            console.log("setting role");
          }}
          styles={{
            valueContainer: (provided, state) => ({
              ...provided,
              padding: 0,
            }),
            singleValue: (provided, state) => ({
              ...provided,
              margin: 0,
            }),
          }}
          // unstyled
        />
      )} */}
    </StyledRole>
  );
};

const StyledRole = styled.span`
  font-weight: 500;
  margin-top: 4px;
`;
