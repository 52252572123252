import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  answerInvitation,
  getCollaborators,
  getInvitations,
  sendInvitation,
} from "./collaborators.call";
import toast from "react-hot-toast";
import { handleAPIErrors } from "../../tools/handleAPIErrors";

export const useCollaborators = () => {
  const { workspaceId } = useParams();

  // Queries
  const query = useQuery({
    queryKey: ["collaborators", workspaceId],
    queryFn: () => getCollaborators(workspaceId),
    initialData: [],
  });

  const mutation = useMutation({
    mutationFn: (invitation: { email: string; role: string }) =>
      sendInvitation(invitation, workspaceId),
    onMutate: async (project) => {
      // queryClient.cancelQueries();
      // const previousProject = queryClient.getQueryData(["project", projectId]);
      // queryClient.setQueryData(["project", projectId], (old: any) => {
      //   return { ...old, ...project };
      // });
      // return { previousProject };
    },
    onSuccess: ({ data }: any) => {
      // queryClient.setQueryData(["project", projectId], (old: any) => {
      //   return data;
      // });
      toast.success("📬 Invitation sent");
    },
    onError: (err: any, project, context) => {
      // queryClient.setQueryData(["project", projectId], (old: any) => {
      //   return context.previousProject;
      // });

      console.log(err);
      handleAPIErrors(err);
    },
  });

  return { query, mutation };
};

export const useInvitations = () => {
  const query = useQuery({
    queryKey: ["invitations"],
    queryFn: () => getInvitations(),
    initialData: [],
  });

  const mutation = useMutation({
    mutationFn: ({
      status,
      invitationId,
    }: {
      status: "accepted" | "declined";
      invitationId: string;
    }) => answerInvitation(status, invitationId),
    onMutate: async (project) => {},
    onSuccess: (data: any) => {},
    onError: (err: any, project, context) => {
      handleAPIErrors(err);
    },
  });

  return { query, mutation };
};
