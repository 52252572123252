import { IconProps } from ".";

export const HTMLIcon = ({ size = 30 }: IconProps) => {
  return (
    <svg
      style={{ width: 30, height: size }}
      viewBox="0 0 264 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.3851 162.845L73.7969 44H190.203L179.615 162.781L131.904 176"
        fill="#E44D26"
      />
      <path
        d="M131.999 165.894V53.7549H179.582L170.502 155.145"
        fill="#F16529"
      />
      <path
        d="M95.4224 68.2891H132V82.8559H111.401L112.749 97.7757H132V112.31H99.401L95.4224 68.2891ZM100.043 119.626H114.674L115.7 131.273L132 135.637V150.845L102.096 142.503"
        fill="#EBEBEB"
      />
      <path
        d="M168.448 68.2891H131.935V82.8559H167.1L168.448 68.2891ZM165.785 97.7757H131.935V112.343H149.902L148.202 131.273L131.935 135.637V150.781L161.774 142.503"
        fill="white"
      />
    </svg>
  );
};
