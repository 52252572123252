import styled from "styled-components";
import { LinkExternalIcon } from "../../icons/link-external.icon";
import { NoticeToolTip } from "../../Components/ToolTip/ToolTip";
import { useEffect, useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "../../Components/Popover/Popover";
import { TextInput } from "../../Components/Input/InputText";
import { Link } from "react-router-dom";
import { useProject } from "../../queries/project/useProject";
import { LinkToNoticeComp } from "./DomainConfiguration";
import { useProjectType } from "../../hooks/useProjectType";

export function PageConfiguration() {
  const { text } = useProjectType();
  const {
    query: { data: project = {} },
  } = useProject();

  const { domain = "" } = project;

  return (
    <Wrapper>
      <Section>
        Your {text} is accessible at <LinkToProjectComponent />
        {domain && (
          <>
            and{" "}
            <LinkToNoticeComp
              onClick={() => {
                window.open(`https://${domain}`, "_blank");
              }}
            >
              {domain}
            </LinkToNoticeComp>
          </>
        )}
      </Section>
    </Wrapper>
  );
}

const Section = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HardCodedUrl = styled.span`
  display: flex;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  height: 40px;
  gap: 3px;
`;

const WebsiteName = styled.div`
  &:hover {
    background-color: ${(props) => props.theme.pastelYellow};
  }
  padding: 0px 4px 0px 4px;
  border-radius: 4px 0px 0px 4px;

  display: flex;
  align-items: center;
  cursor: text;
  height: 100%;
  transition: background-color 0.2s ease-in-out;
`;

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LinkToProject = styled.span`
  background-color: rgb(231, 231, 231);
  background-image: url(https://assets-notice.b-cdn.net/v2-editor/textured-noise.png);

  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.text};
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.lightText};

  padding: 0px;
  border-radius: 4px;
`;

const IconContainer = styled.div`
  height: 100%;
  padding: 3px 3px 3px 3px;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.pastelYellow};
  }
  border-left: 1px solid ${(props) => props.theme.lightText};
`;

export const LinkToProjectComponent = () => {
  const {
    query: { data: project },
    mutation: updateProject,
  } = useProject();

  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(project?.slug ?? "");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setURL but only allow letters, numbers, and hyphens
    setUrl(e.target.value.replace(/[^a-zA-Z0-9-]/g, "").toLowerCase());
  };

  useEffect(() => {
    if (open === false && url !== project.slug) {
      updateProject.mutate({ slug: url });
    }
  }, [open]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverContent className="Popover">
        <InputContainer
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setOpen(false);
            }
          }}
          spellCheck={false}
        >
          <TextInput value={url} onChange={onChange} placeholder="my-site" />
          <HardCodedUrl>.notice.site</HardCodedUrl>
        </InputContainer>
      </PopoverContent>

      <LinkToProject>
        <NoticeToolTip anchorSelect=".change-url-tooltip">
          Change URL
        </NoticeToolTip>

        <PopoverTrigger onClick={() => setOpen((v) => !v)}>
          <WebsiteName className="change-url-tooltip">
            {project?.slug}.notice.site
          </WebsiteName>
        </PopoverTrigger>
        <NoticeToolTip anchorSelect=".visit-website-tooltip">
          Visit your website
        </NoticeToolTip>

        <Link
          to={`https://${project?.slug}.notice.site`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ padding: 0, margin: 0, height: "100%" }}
        >
          <IconContainer className="visit-website-tooltip">
            <LinkExternalIcon size={14} />
          </IconContainer>
        </Link>
      </LinkToProject>
    </Popover>
  );
};
