import styled from "styled-components";
import { Button } from "../../Components/Button";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../Components/Dialog/Dialog";
import { useProject } from "../../queries/project/useProject";
import { DomainConfiguration as CustomDomainConfig } from "./CustomDomainFlow/CustomDomainFlow";
import { LinkToProjectComponent } from "./ProjectConfiguration";

export function DomainConfiguration() {
  const { data: project, isLoading } = useProject().query;

  if (isLoading) return null;

  const { domain = "" } = project;

  return (
    <div>
      <NoDomainContainer>
        <Dialog>
          <DialogContent style={{ width: "100%" }}>
            <CustomDomainConfig />
          </DialogContent>
          <Container>
            <Section>
              <h2>Notice site</h2>
              <LinkToProjectComponent />
            </Section>
            <Divider />
            <Section>
              <h2>Your own domain</h2>
              {domain && (
                <LinkToNoticeComp
                  onClick={() => {
                    window.open(`https://${domain}`, "_blank");
                  }}
                >
                  {domain}
                </LinkToNoticeComp>
              )}
              {!domain && (
                <>
                  <p>There's no domain configured yet.</p>
                  <DialogTrigger asChild={true}>
                    <Button>Configure your own domain</Button>
                  </DialogTrigger>
                </>
              )}
              {domain && (
                <DialogTrigger asChild={true}>
                  <ChangeDomain>Change domain</ChangeDomain>
                </DialogTrigger>
              )}
            </Section>
          </Container>
        </Dialog>
      </NoDomainContainer>
    </div>
  );
}

const ChangeDomain = styled.span`
  font-size: 11px;
  margin-top: 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.lightText};
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

export const LinkToNoticeComp = styled.span`
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.text};
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.lightText};
  padding: 0px 4px 0px 4px;
  border-radius: 4px;
  background-color: rgb(231, 231, 231);
  background-image: url(https://assets-notice.b-cdn.net/v2-editor/textured-noise.png);

  transition: background-color 0.2s;
  &:hover {
    background-color: ${(props) => props.theme.pastelYellow};
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.lightText};
  margin: 16px 0;
  width: 100%;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const NoDomainContainer = styled.div`
  display: flex;
  justify-content: center;
`;
