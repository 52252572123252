import toast from "react-hot-toast";
import { ENDPOINTS } from "../../tools";
import { useWorkspace } from "../../queries/workspaces/useWorkspaces";
import { ChatMessages } from "./chatMessages";

interface GenerateContentProps {
  chatMessages: ChatMessages;
  setResponse: any;
  onStreamComplete: any;
  temperature?: number;
  model?: string;
}

export const useAIContent = () => {
  const {
    query: { data: workspace },
  } = useWorkspace();

  const generateContent = async ({
    chatMessages,
    setResponse,
    onStreamComplete,
    temperature = 0.1,
  }: GenerateContentProps) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      try {
        const response = await fetch(`${ENDPOINTS.API}/ai/content`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
          body: JSON.stringify({
            workspaceId: workspace._id,
            chatMessages,
            temperature,
          }),
          signal,
        });

        if (response.status === 402) {
          toast.error(
            "You have reached the maximum number of AI requests for this month. Please upgrade your plan to continue using this feature."
          );
        }

        const reader = response.body
          ?.pipeThrough(new TextDecoderStream())
          .getReader();

        while (reader) {
          const stream = await reader.read();
          if (stream.value?.includes("_end_of_stream_")) {
            const split = stream.value.split("_end_of_stream_");
            setResponse(split[0]);
            break;
          }
          setResponse(stream.value);
          if (stream.done) break;
        }
        onStreamComplete();
      } catch (error) {
        onStreamComplete();

        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Fetch error:", error);
        }
      }
    };

    fetchData();

    return abortController;
  };

  return [generateContent];
};

// export const useAIContent = () => {
//   const {
//     query: { data: workspace },
//   } = useWorkspace();

//   const generateContent = async ({
//     chatMessages,
//     setResponse,
//   }: GenerateContentProps) => {
//     const abortController = new AbortController();
//     const signal = abortController.signal;

//     try {
//       const response = await fetch(`${ENDPOINTS.API}/ai/content`, {
//         method: "POST",
//         credentials: "include",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         mode: "cors",
//         body: JSON.stringify({
//           workspaceId: workspace._id,
//           chatMessages,
//         }),
//         signal,
//       });

//       if (response.status === 402) {
//         toast.error(
//           "You have reached the maximum number of AI requests for this month. Please upgrade your plan to continue using this feature."
//         );
//       }

//       const reader = response.body
//         ?.pipeThrough(new TextDecoderStream())
//         .getReader();

//       while (reader) {
//         const stream = await reader.read();
//         if (stream.value?.includes("_end_of_stream_")) {
//           const split = stream.value.split("_end_of_stream_");
//           setResponse(split[0]);
//           break;
//         }
//         setResponse(stream.value);
//         if (stream.done) break;
//       }

//       return { abortController, result: "done" };
//     } catch (error) {
//       if (error.name === "AbortError") {
//         console.log("Fetch aborted");
//       } else {
//         console.error("Fetch error:", error);
//       }
//       return { abortController, result: null };
//     }
//   };

//   return [generateContent];
// };

export const useNoStreamGenerateContent = () => {
  const {
    query: { data: workspace },
  } = useWorkspace();

  const generateContent = async ({
    chatMessages,
    setResponse,
    temperature = 0.1,
    model,
  }: GenerateContentProps) =>
    await fetch(`${ENDPOINTS.API}/ai/content`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        workspaceId: workspace._id,
        chatMessages,
        isStream: false,
        temperature,
        model,
      }),
    })
      .then(async (body) => {
        // Get the string from the response
        const res = await body?.text();

        setResponse(res);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  return [generateContent];
};
