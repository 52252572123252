import styled from "styled-components";
import { useWorkspaces } from "../../queries/workspaces/useWorkspaces";
import { useNavigateToWorkspace } from "../../hooks/useQueryParam";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeading,
  DialogTrigger,
} from "../../Components/Dialog/Dialog";
import { TextInput } from "../../Components/Input/InputText";
import { useParams } from "react-router-dom";
import { PlusIcon } from "../../icons/Plus.icon";

export function CurrentWP() {
  const { workspaceId } = useParams();
  const { data: workspaces } = useWorkspaces().query;
  const workspace = workspaces?.find((wp) => wp._id === workspaceId);
  const navigateToWorkspace = useNavigateToWorkspace();
  const [showWP, setShowWP] = useState(false);

  // Close on click outisde
  const handleClick = (e: any) => {
    if (!e.target.closest(".select-container")) {
      setShowWP(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <SelectContainer className="select-container">
      <WP
        onClick={() => {
          setShowWP(!showWP);
        }}
      >
        {workspace?.name}
      </WP>
      {showWP && (
        <Wrapper>
          {workspaces?.map((wp) => {
            return (
              <SelectWP
                key={wp._id}
                onClick={() => {
                  if (!(workspaceId === wp._id)) navigateToWorkspace(wp._id);
                  setShowWP(false);
                }}
              >
                {wp.name}
              </SelectWP>
            );
          })}
          <CreateWorkspace />
        </Wrapper>
      )}
    </SelectContainer>
  );
}

const SelectContainer = styled.div`
  position: relative;
`;
const WP = styled.div`
  color: ${({ theme }) => theme.lightText} !important;
  text-decoration: none;
  font-weight: 300;
  cursor: pointer;
  &:hover {
    color: #333 !important;
  }
  transition: color 0.2s;
  overflow: hidden;
  margin-right: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SelectWP = styled.div`
  color: ${({ theme }) => theme.text};
  font-weight: 300;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.2s ease-in-out;
  display: flex;
  gap: 4px;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const Wrapper = styled.div`
  top: 25px;
  position: absolute;
  flex-direction: column;
  background-color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  color: ${({ theme }) => theme.text};
  box-shadow: ${(props) => props.theme.shadow};
  z-index: 999;
`;

export function WorkspaceList() {
  const { data: workspaces, isLoading } = useWorkspaces().query;

  if (isLoading) return <></>;

  return (
    <Container>
      <CreateWorkspace />
      {workspaces &&
        workspaces.map(({ name, _id }) => (
          <Workspace key={_id} id={_id} name={name} />
        ))}
    </Container>
  );
}

const Container = styled.div``;

interface WorkspaceI {
  name?: string;
  id: string;
}

export function Workspace({ name, id }: WorkspaceI) {
  const navigateToWorkspace = useNavigateToWorkspace();

  return (
    <WPContainer onClick={() => navigateToWorkspace(id)}>
      <span
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "100%",
        }}
      >
        {name}
      </span>
    </WPContainer>
  );
}

const WPContainer = styled.div`
  position: relative;
  width: 300px;
  height: 55px;
  backdrop-filter: blur(16px);
  border: 6px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.8);

  border-radius: 8px;
  transition: top 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: baseline;
  padding-left: 16px;
  cursor: pointer;
  top: 0px;
  &:hover {
    top: -4px;
  }
`;

export function CreateWorkspace() {
  const mutation = useWorkspaces().mutation;
  const [val, setVal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const createWorkspace = async () => {
    mutation.mutate({ name: val, workspaceId: crypto.randomUUID() });
    setModalOpen(false);
    setVal("");
  };

  return (
    <Dialog open={modalOpen} onOpenChange={setModalOpen}>
      <DialogContent>
        <DialogHeading>Create Workspace</DialogHeading>
        <DialogDescription>
          Type the name of your new workspace
        </DialogDescription>
        <InputContainer
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              createWorkspace();
            }
          }}
          spellCheck={false}
        >
          <TextInput
            value={val}
            onChange={(e) => setVal(e.target.value)}
            placeholder="My Workspace"
          />
          <Kbd onClick={() => createWorkspace()}>Enter</Kbd>
        </InputContainer>
      </DialogContent>

      <DialogTrigger asChild={true} onClick={() => setModalOpen(true)}>
        <SelectWP
          style={{ borderTop: "1px solid lightgrey", alignItems: "center" }}
        >
          <PlusIcon />
          Create Workspace
        </SelectWP>
      </DialogTrigger>
    </Dialog>
  );
}

const InputContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 40px;
`;

const Kbd = styled.kbd`
  padding: 8px 16px;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${(props) => props.theme.lightText};
  cursor: pointer;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 16px;
  color: ${({ theme }) => theme.text};
  &:hover {
    background-color: ${(props) => props.theme.pastelYellow};
  }
  background-color: transparent;
`;
