import { IconProps } from ".";

export const TextIcon = ({ size = 30 }: IconProps) => {
  return (
    <svg
      style={{ width: size, height: size }}
      width="264"
      height="220"
      viewBox="0 0 264 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M71.5 66H192.5C193.959 66 195.358 65.4206 196.389 64.3891C197.421 63.3577 198 61.9587 198 60.5C198 59.0413 197.421 57.6424 196.389 56.6109C195.358 55.5795 193.959 55 192.5 55H71.5C70.0413 55 68.6424 55.5795 67.6109 56.6109C66.5795 57.6424 66 59.0413 66 60.5C66 61.9587 66.5795 63.3577 67.6109 64.3891C68.6424 65.4206 70.0413 66 71.5 66V66Z"
        fill="#3A85D0"
      />
      <path
        d="M148.5 88H71.5C70.0413 88 68.6424 88.5795 67.6109 89.611C66.5795 90.6424 66 92.0413 66 93.5C66 94.9587 66.5795 96.3577 67.6109 97.3891C68.6424 98.4206 70.0413 99 71.5 99H148.5C149.959 99 151.358 98.4206 152.389 97.3891C153.421 96.3577 154 94.9587 154 93.5C154 92.0413 153.421 90.6424 152.389 89.611C151.358 88.5795 149.959 88 148.5 88Z"
        fill="#3A85D0"
      />
      <path
        d="M192.5 121H71.5C70.0413 121 68.6424 121.58 67.6109 122.611C66.5795 123.642 66 125.041 66 126.5C66 127.959 66.5795 129.358 67.6109 130.389C68.6424 131.421 70.0413 132 71.5 132H192.5C193.959 132 195.358 131.421 196.389 130.389C197.421 129.358 198 127.959 198 126.5C198 125.041 197.421 123.642 196.389 122.611C195.358 121.58 193.959 121 192.5 121Z"
        fill="#3A85D0"
      />
      <path
        d="M148.5 154H71.5C70.0413 154 68.6424 154.58 67.6109 155.611C66.5795 156.642 66 158.041 66 159.5C66 160.959 66.5795 162.358 67.6109 163.389C68.6424 164.421 70.0413 165 71.5 165H148.5C149.959 165 151.358 164.421 152.389 163.389C153.421 162.358 154 160.959 154 159.5C154 158.041 153.421 156.642 152.389 155.611C151.358 154.58 149.959 154 148.5 154Z"
        fill="#3A85D0"
      />
    </svg>
  );
};
