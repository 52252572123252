import { AxiosAPI } from "../api";

export async function sendHelpMessage({ message, senderEmail }) {
  if (!message) {
    throw new Error("Message is required");
  }
  const { data } = await AxiosAPI.post(`/email/notice-help`, {
    message,
    senderEmail,
  });
  return data;
}
