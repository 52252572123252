import { AxiosAPI } from "../api";

export interface Workspace {
  name: string;
  logoUrl: string;
  members: Array<any>;
  _id: string;
  accentColor?: string;
  contactEmail?: string;
  priceId?: string;
  productId?: string;
  subscriptionId?: string;
  subscriptionStatus?: string;
}

// Calls
export function createWorkspace(name: string, _id: string): Promise<Workspace> {
  return AxiosAPI.post(`/workspaces`, { name, _id });
}

export async function getWorkspaces(): Promise<Array<Workspace>> {
  const { data } = await AxiosAPI.get(`/workspaces`);
  return data;
}

export async function getWorkspace(workspaceId: string): Promise<Workspace> {
  if (!workspaceId) return null;
  const { data } = await AxiosAPI.get(`/workspaces/${workspaceId}`);

  return data;
}

export async function updateWorkspace(
  workspace: Partial<Workspace>
): Promise<Workspace> {
  const { data } = await AxiosAPI.put(`/workspaces/${workspace._id}`, {
    workspace,
  });
  return data;
}

export async function updateWorkspaceLogo(
  workspaceId: string,
  formData: FormData
): Promise<Workspace> {
  const { data: url } = await AxiosAPI.post(
    `/workspaces/${workspaceId}/logo`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return url;
}
