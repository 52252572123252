import styled from "styled-components";
import { usePage } from "../../queries/page/usePages";

export function Category() {
  const {
    query: { data: page },
  } = usePage();

  const { category } = page ?? {};

  if (!category || category === "") return null;

  return (
    <>
      <h3>Category</h3>
      <Container>
        <StyledBox>{category}</StyledBox>
      </Container>
    </>
  );
}

const Container = styled.div`
  max-width: 500px;
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;

  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.secondary};
  border: 1px solid #7e8c8d;
  border-radius: 6px;
  background-color: #f5f5f5;
`;
