import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { getImageDimensions } from "../file/files.helper";
import {
  Workspace,
  createWorkspace,
  getWorkspace,
  getWorkspaces,
  updateWorkspace,
  updateWorkspaceLogo,
} from "./workspaces.calls";

export const useWorkspaces = () => {
  const queryClient = useQueryClient();

  // Queries
  const query = useQuery({ queryKey: ["workspaces"], queryFn: getWorkspaces });

  // Mutations
  const mutation = useMutation({
    mutationFn: ({
      name,
      workspaceId,
    }: {
      name: string;
      workspaceId: string;
    }) => createWorkspace(name, workspaceId),
    onMutate: async ({ name, workspaceId }) => {
      queryClient.cancelQueries();
      queryClient.setQueryData(["workspaces"], (old: any) => {
        return [...old, { name, _id: workspaceId }];
      });
    },
    onSuccess: ({ data }: any) => {
      queryClient.setQueryData(["workspaces"], (old: any) => {
        return old.map((w: any) => {
          if (w._id === data._id) {
            return data;
          }
          return w;
        });
      });
    },
    onError: (err: any, { workspaceId }) => {
      queryClient.setQueryData(["workspaces"], (old: any) => {
        const rollBack = old.filter((w: any) => w._id !== workspaceId);
        return rollBack;
      });

      toast.error(err.response.data.errors[0].msg);
    },
  });

  return {
    query,
    mutation,
  };
};

export const useCreateWorkspace = () => {
  const mutation = useMutation({
    mutationFn: ({ name, _id }: { name: string; _id: string }) =>
      createWorkspace(name, _id),
    onMutate: async (name) => {
      // queryClient.cancelQueries();
      // queryClient.setQueryData(["workspaces"], (old: any) => {
      //   return [...old, { name, _id: "temp" }];
      // });
    },
    onSuccess: ({ data }: any) => {
      // queryClient.setQueryData(["workspaces"], (old: any) => {
      //   return old.map((w: any) => {
      //     if (w._id === "temp") {
      //       return data;
      //     }
      //     return w;
      //   });
      // });
    },
    onError: (err: any) => {
      // queryClient.setQueryData(["workspaces"], (old: any) => {
      //   return old.filter((w: any) => w._id !== "temp");
      // });

      toast.error(err.response.data.errors[0].msg);
    },
  });

  return { mutation };
};

export const useWorkspace = () => {
  const { workspaceId } = useParams();

  const queryClient = useQueryClient();

  // Queries
  const query = useQuery({
    queryKey: ["workspace", workspaceId],
    queryFn: () => getWorkspace(workspaceId),
    refetchOnWindowFocus: true,
  });

  // Mutations
  const mutation = useMutation({
    mutationFn: (workspace: Partial<Workspace>) =>
      updateWorkspace({ ...workspace, _id: workspaceId }),
    onMutate: async (workspace) => {
      queryClient.cancelQueries();

      queryClient.setQueryData(["workspace", workspaceId], (old: any) => {
        return { ...old, ...workspace };
      });
    },
    onSuccess: ({ data }: any) => {
      queryClient.setQueryData(["workspace", workspaceId], (old: any) => {
        return data;
      });
    },
    onError: (err: any, workspace) => {
      queryClient.setQueryData(["workspace", workspaceId], (old: any) => {
        return old;
      });

      toast.error(err.response.data.errors[0].msg);
    },
  });

  return {
    query,
    mutation,
  };
};

export const useUploadLogo = () => {
  const { workspaceId } = useParams();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ file, type }: any) => {
      const formData = new FormData();

      formData.append("file", file);

      try {
        const { width, height } = await getImageDimensions(file);
        formData.append("aspectRatio", (width / height).toString());
      } catch (ex) {}

      const url = await updateWorkspaceLogo(workspaceId, formData);
      return url;
    },
    onMutate: ({ file }) => {
      const url = URL.createObjectURL(file);
      queryClient.setQueryData(["workspace", workspaceId], (old: any) => {
        return { ...old, logoUrl: url };
      });
    },

    onSuccess: (data) => {
      queryClient.setQueryData(["workspace", workspaceId], (old: any) => {
        return { ...old, logoUrl: data };
      });

      return data;
    },
  });

  return { mutation };
};
